<template>
  <div style="height: 100%">
  <Loading
      v-if="loadingDialogGlobal"
      :dialogProps="loadingDialogGlobal"
      :messageDialogeProps="messageGlobal"
      :loadingProps="loadingGlobalValue"
      :errorProps="errorGlobalValue"
    />
    <v-dialog v-model="dialogPaiement" width="750" >
    <v-snackbar
      color="error"
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          dense
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
      <v-card>
        <v-toolbar style="width:100%" class="d-flex flex-column" :color="$colors[0]" dark>
          <v-card-title
            class="d-flex flex-row justify-space-between text-body-2 text-lg-h6 align-items-center"
          >
          <div class="d-flex flex-row">
            
            <v-tooltip v-if="raisonSocialePaiement.length >= 15" bottom>
              <template v-slot:activator="{ on, attrs }">
                <small class="td-table" v-bind="attrs" v-on="on">
                  Rythme de paiement 
                  {{ raisonSocialePaiement.substring(0, 15) + "..." }}
                  </small
                >
              </template>
              <span> {{ raisonSocialePaiement }}</span>
            </v-tooltip>
            <small class="td-table" v-else>
              Rythme de paiement  {{ raisonSocialePaiement }}
              
            </small>
            <v-icon class="ml-2">mdi-wallet-outline</v-icon>
          </div>
          </v-card-title>
            <small class="my-1">Fournisseur : {{RythmePaiementVente?.fournisseur}}</small>
        </v-toolbar>
        <div class="pa-4">
          <div class="d-flex flex-row align-center justify-space-between mb-2 mx-2">
            <div>
              <span>Nombre de paiement :</span>
              <input class="ml-2" v-model="nombrePaiement" :min="minimumNombrePaiement"  style="border:2px black solid; border-radius:5px;width:10%" type="number"></input>
            </div>
            <div class="d-flex flex-column">
              <span class="my-1">Valeur Brut : 
                <span class="Poppins-Bold">{{Number(valeurBrutPaiement).toLocaleString('fr-FR')}} €</span>
              </span>
            </div>
          </div>
          <table id="table" class="table table-striped table-bordered">
            <thead style="background-color: #103a5e; color: white">
              <tr class="text-center">
                <th style="width:25vw" v-for="column in columnsPaiementRythme">
                  <small class="one-line" >
                    {{ column.label }}
                  </small>
                  <v-icon
                    class="ml-2"
                    dense
                    color="#fff"
                    v-if="column.label == 'Paiement'"
                    @click="ActivateInput()"
                    >mdi-circle-edit-outline</v-icon
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-if="index != 'valeurBrut'"
                v-for="(item, index) in RythmePaiementVente?.pourcentage_rythme_paiement"
                :key="index"
              >
                <td>
                  <div  v-if="item.editing != 'pourcentage'">
                    <span @click="startEditing(index, 'pourcentage', item.pourcentage)"
                      >{{ Number(item.pourcentage).toLocaleString('fr-FR') }} %</span
                    >
                  </div>
                  <div  v-else>
                    <input
                      min="0"
                      type="number"
                      v-model="item.newValue"
                      @keyup.enter="stopEditing(index, 'pourcentage')"
                      style="width: 50px;border:3px black solid;border-radius:5px"
                    />
                  </div>
                </td>
                <td>
                  <div v-if="item.editing != 'date'">
                    <span @click="startEditing(index, 'date', item.date)">{{
                    item.date
                    }}</span>
                  </div>
                  <div  v-else>
                    <input
                      type="date"
                      v-model="item.date"
                      @keyup.enter.prevent="handleEnterKey(index, 'date')"
                      @blur.stop="handleBlur(index, 'date')"
                      style="width: 150px;border:3px black solid;border-radius:5px"
                    />
                  </div>
                </td>
                <td>{{Number(Number(valeurBrutPaiement)*(Number(item.pourcentage)/100)).toLocaleString('fr-FR')}} €</td>
                <td>
                <div class="d-flex flex-row justify-center align-items-center">
                <p class="color-paye mb-0" v-if="item.statut == 'payé'">
                  <v-icon color="#1B5E20" class="mr-2"> mdi-cash-check </v-icon
                  ><b>payé</b>
                </p>

                
                <p class="color-annuler mb-0" v-if="item.statut == 'annuler'">
                  <v-icon color="red" class="mr-2"> mdi-cancel </v-icon><b>annulé</b>
                </p>
                <p class="mb-0" v-if="item.statut == 'en attente'">
                  <v-icon color="gray" class="mr-2"> mdi-timer-refresh-outline </v-icon
                  ><b>en attente</b>
                </p>
                <v-icon
                  size="22"
                  class="dropdown-toggle"
                  data-toggle="collapse"
                  :data-target="'#collapseStatut' + index"
                  aria-expanded="false"
                  :aria-controls="'collapseStatut' + index"
                  left
                  style="cursor: pointer"
                >
                  mdi-chevron-down
                </v-icon>
              </div>
              <div
                class="text-center collapse"
                :id="'collapseStatut' + index"
                style="border: 0px solid white"
              >
                <v-btn
                  @click="changeStatut(index, 'payé')"
                  v-if="item.statut != 'payé'"
                  class="mr-2"
                  icon
                  dark
                  small
                >
                  <v-icon color="#1B5E20"> mdi-cash-check </v-icon>
                </v-btn>
                <v-btn
                  @click="changeStatut(index, 'en attente')"
                  v-if="item.statut != 'en attente'"
                  class="mr-2"
                  icon
                  dark
                  small
                >
                  <v-icon color="black"> mdi-timer-refresh-outline </v-icon>
                </v-btn>
                

                <v-btn
                  @click="changeStatut(index, 'annuler')"
                  v-if="item.statut != 'annuler'"
                  class="mr-2"
                  icon
                  small
                  dark
                >
                  <v-icon color="red"> mdi-cancel </v-icon>
                </v-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
          
          
            
          <div class="d-flex flex-row justify-space-between">
            <div style="border:2px solid rgb(222, 226, 230);width:27%" class="d-flex flex-row justify-center align-center ">
              <span>Paiement : {{Number(totalPourcentage).toLocaleString('fr-FR')}} %</span>
            </div>
            <!--<v-btn style="width:25%" @click="checkTotalPourcentage()" :color="$colors[0]" class="white--text">Verification</v-btn>-->
            <v-btn style="width:26%" @click="updatePaiement()" :disabled="totalPourcentage != 100" :color="$colors[0]" class="white--text">Mettre a jour</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-card class="ma-4 pa-4">
      <div class="d-flex flex-row justify-end">
      <v-btn
          style="position:absolute;right:0;top:34px;margin-right:34px"
          class="white--text"
          :color="$colors[0]"
          @click="dialogGenerateCSV = true"
        >
          <span>excel</span>
          <v-icon dense class="ml-2">mdi-file-excel-outline</v-icon>
        </v-btn>
      </div>
      <div v-if="dialogGenerateCSV">
      <v-dialog v-model="dialogGenerateCSV" width="500">
        <v-card height="100%">
          <v-toolbar :color="$colors[0]" dark>
            <v-card-title
              class="d-flex justify-content-center text-body-2 text-lg-h6 align-items-center"
            >
              <span>Création Excel</span
              ><v-icon class="ml-2">mdi-file-excel-outline</v-icon>
            </v-card-title>
          </v-toolbar>
          <div style="height: 100%" class="pa-4 d-flex flex-column">
            <span class="mx-2 mb-4">Génération Excel par :</span>
            <div class="d-flex flex-row justify-center">
              <div
                v-for="item in FilterList"
                @click="createSheet(item)"
                class="pa-2 rounded mx-2 text-center"
                :style="{ backgroundColor: $colors[0], width: '33%', cursor: 'pointer' }"
              >
                <span class="white--text Poppins-Bold">{{ item.label }}</span>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
      </div>
      <datatable
        id="table-gestion-ventes"
        class="ma-4"
        :columns="columns"
        @search="rechercheData"
      >
        <template v-slot:body>
          <tbody class="text-center" v-if="projects.length > 0 && loadingTable == false">
            <tr v-for="(item, index) in projects" :key="item.id">
              <td>
                <v-tooltip v-if="item.Raison.length >= 15" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <small class="td-table" v-bind="attrs" v-on="on">
                      <router-link
                        :to="{
                          path: '/les-profils-societes',
                          query: { id: item.idSociete },
                        }"
                        >{{ item.Raison.substring(0, 20) + "..." }}</router-link
                      >
                    </small>
                  </template>
                  <span>{{ item.Raison }}</span>
                </v-tooltip>
                <small class="td-table" v-else>
                  <router-link
                    :to="{ path: '/les-profils-societes', query: { id: item.idSociete } }"
                    >{{ item.Raison }}</router-link
                  >
                </small>
              </td>
              <td>{{ item.numCompteur }}</td>
              <td>{{ item.fournisseur }}</td>
              <td>{{ $dayjs(item.dateDF).isValid() ? $dayjs(item.dateDF).format('DD/MM/YYYY') : item.dateDF}}</td>
              <td>{{ $dayjs(item.dateFF).isValid() ? $dayjs(item.dateFF).format('DD/MM/YYYY') : item.dateFF}}</td>
              <td>{{ Number(item.consommation).toLocaleString("fr-FR") }}</td>
              <td v-if="roleUser === 'admin' || roleUser === 'comptable'">
                {{ Number(item.valeurBrut).toLocaleString('fr-FR') }} € 
              </td>
              <td v-if="roleUser === 'Chef equipe' || roleUser == 'ADV'">
                {{ item.valeur }}
              </td>
              <td>
                <div v-if="item.status == 'valide'" class="text-center">
                  <v-icon class="ml-2" color="green accent-3">mdi-check</v-icon>
                </div>
                <div v-if="item.status == 'paye'" class="text-center">
                  <v-icon class="ml-2" color="#1B5E20">mdi-cash-check</v-icon>
                </div>
                
              </td>
              <td>
                <v-btn @click="openDialogPaiement(item, index)" text> Rythme </v-btn>
              </td>
            </tr>
          </tbody>
          <noDataTable
            class="ma-4"
            v-else-if="projects?.length == 0 && loadingTable == false"
          />
          <progressCircular class="ma-8" v-else-if="loadingTable == true" />
        </template>
      </datatable>
      <v-row>
        <v-col>
          <pagination
            v-if="projects.length > 0"
            :pagination="pagination"
            @selectNumberOfRecords="rechercheData"
            @first="getData(pagination.firstPage)"
            @last="getData(pagination.lastPage)"
            @prev="getData(pagination.prevPageUrl)"
            @current="getData(pagination.currentPage)"
            @next="getData(pagination.nextPageUrl)"
          >
          </pagination>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import Datatable from "../components/dataTable/Datatable.vue";
import Pagination from "../components/dataTable/Pagination.vue";
import noDataTable from "../components/no-data-table.vue";
import progressCircular from "../components/progress-circular.vue";
import Loading from "@/components/ui/loading-component.vue";
export default {
  name: "gestionVentes",
  components: { Datatable, Pagination, noDataTable, progressCircular,Loading },
  data() {
    return {
      oldValueNombrePaiement:0,
      messageGlobal:"",
      errorGlobalValue:false,
      loadingGlobalValue: false,
      loadingDialogGlobal: false,
      FilterList: [
        { label: "Date", value: "date" },
        { label: "Fournisseur", value: "fournisseur" },
        { label: "Vendeur", value: "Vendeur" },
      ],
      rapportVenteActuelle:null,
      isBlurHandled: false,
      activateInput:false,
      totalPourcentage:0,
      updatePourcentage:false,
      snackbar:false,
      timeout:5000,
      text:'',
      valeurBrutPaiement:0,
      listePaiement:[],
      nombrePaiement:0,
      minimumNombrePaiement:0,
      listKeysRythmePaiement: [],
      RythmePaiementVente: null,
      listeStatutPaiement: ["payé", "annulé"],
      columnsPaiementRythme: [
        { width: "25vw", label: "Paiement", name: "paiement" },
        { width: "25vw", label: "Date", name: "date" },
        { width: "25vw", label: "Valeur", name: "valeur" },
        { width: "25vw", label: "Statut", name: "statut" },
      ],
      columns: [
        { width: "11vw", label: "Raison Sociale", name: "raison", sortable: true },
        { width: "11vw", label: "PDL", name: "numCompteur", sortable: true },
        { width: "11vw", label: "Fournisseur", name: "fournisseur", sortable: true },
        { width: "11vw", label: "DDF", name: "ddf", sortable: true },
        { width: "11vw", label: "DFF", name: "dff", sortable: true },
        { width: "11vw", label: "Conso", name: "conso", sortable: true },
        { width: "11vw", label: "Valeur", name: "valeur", sortable: true },
        { width: "11vw", label: "Statut", name: "statut", sortable: true },
        { width: "11vw", label: "Paiement", name: "paiement", sortable: true },
      ],
      roleUser: "",
      projects: [],
      dateDebutModel: "",
      projectsPrix: [],
      dialogGenerateCSV: false,
      tableauPaiement: [],
      valuePaiementChange: "",
      valuePaiementStatutChange: "",
      tableModifyValue: [],
      tableFournisseur: [],
      fournisseurSelected: "",
      raisonSocialePaiement: "",
      dialogPaiement: false,
      loadingTable: false,
      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
        filterItems: null,
        filterType: "all",
      },
      pagination: {
        perPage: [10, 15, 30],
        firstPage: 0,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
      items: {
        date: [],
        nom: [],
        raison: [],
        fournisseur: [],
        type: [],
        numCompteur: [],
        Consommation: [],
        Duree: [],
        marge: [],
        valeurBrut: [],
        Pourcentage: [],
        valeurAA: [],
        valeurGEMC: [],
        status: [],
        dateDebutFMIN: null,
        dateDebutFMAX: null,
        paiement: 100,
      },
    };
  },
  async created() {
    await this.getData();
    this.roleUser = await this.whoIAm(this.getToken("token"));
  },
  
  methods: {
    async createSheet(item) {
      this.dialogGenerateCSV = false;
      this.loadingDialogGlobal = true;
      this.loadingGlobalValue = true;
      switch (item.value) {
        case "date":
          await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "createSheetRythmePaiementDate/",
            method: "POST",
            data: {
              key: item.value,
            },
          }).then((res) => {
            var url = res.data.result;
            const link = document.createElement("a");
            link.href = url;
            link.download = "test.xlsx";
            link.click();
            if (res.status === 200) {
              this.loadingDialogGlobal = false;
              this.loadingGlobalValue = false;
            } else {
              this.loadingDialogGlobal = true;
              this.loadingGlobalValue = false;
              this.errorGlobalValue = true;
              this.messageGlobal = "Erreur";
            }
          });
          break;
        case "fournisseur":
          await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "createSheetRythmePaiementField/",
            method: "POST",
            data: {
              field: item.value,
            },
          }).then((res) => {
            if (res.status === 200) {
              var url = res.data.result;
              const link = document.createElement("a");
              link.href = url;
              link.download = "test.xlsx";
              link.click();
              this.loadingDialogGlobal = false;
              this.loadingGlobalValue = false;
            } else {
              this.loadingDialogGlobal = true;
              this.loadingGlobalValue = false;
              this.errorGlobalValue = true;
              this.messageGlobal = "Erreur";
            }
          });
          break;
        case "Vendeur":
          await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "createSheetRythmePaiementField/",
            method: "POST",
            data: {
              field: item.value,
            },
          }).then((res) => {
            if (res.status === 200) {
              var url = res.data.result;
              const link = document.createElement("a");
              link.href = url;
              link.download = "test.xlsx";
              link.click();
              this.loadingDialogGlobal = false;
              this.loadingGlobalValue = false;
            } else {
              this.loadingDialogGlobal = true;
              this.loadingGlobalValue = false;
              this.errorGlobalValue = true;
              this.messageGlobal = "Erreur";
            }
          });
          break;

        default:
          break;
      }
    },
    handleEnterKey(index,type) {
      if (!this.isBlurHandled) {
        this.stopEditing(index, type)
        this.isBlurHandled = true;
      }
    },
    handleBlur(index,type) {
      if (!this.isBlurHandled) {
        this.stopEditing(index, type)
      }
     
      this.isBlurHandled = false;
    },
    ActivateInput(){
      this.activateInput = !this.activateInput
      if(this.activateInput){
        Object.entries(this.RythmePaiementVente.pourcentage_rythme_paiement).forEach((element,index) => {
          this.startEditing(element[0], 'pourcentage', element[1].pourcentage)
        });
      }else{
        Object.entries(this.RythmePaiementVente.pourcentage_rythme_paiement).forEach((element,index) => {
          this.stopEditing(element[0], 'pourcentage',element[1].pourcentage)
        });
      }
    },
    checkTotalPourcentage(index){
      if(index == Object.keys(this.RythmePaiementVente.pourcentage_rythme_paiement)[Object.keys(this.RythmePaiementVente.pourcentage_rythme_paiement).length-1]){
        this.activateInput = false;
        if(this.totalPourcentage != 100){
          this.updatePourcentage = false;
          this.snackbar = true
          this.text = "Vous devez affecter 100 % du paiement"
          setTimeout(() => {
            this.snackbar = false
          }, 5000);
        }
      }
    },
    changeStatut(index,newStatut){
    this.$set(
          this.RythmePaiementVente.pourcentage_rythme_paiement[index],
          "statut",
          newStatut
        );
    },
    async updatePaiement(){
      if(this.totalPourcentage == 100){
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "updateRythmePaiement/",
          method: "POST",
          data: {
            idRythmePaiement: this.RythmePaiementVente.id,
            pourcentageRythmePaiement:JSON.stringify(this.RythmePaiementVente.pourcentage_rythme_paiement)
          },
        }).then(res=>{
          this.rapportVenteActuelle.status = res.data.statutRapportVente
          if(res.data.data.message != true){

            alert(res.data.data.message)
          }else{
          this.dialogPaiement = false;
          }
        })
      }
    },
    startEditing(index, field, value) {
      if (
        this.RythmePaiementVente.pourcentage_rythme_paiement[index].statut !== "payé" ||
        field == "date" || field == "statut"
      ) {
        // Lorsque vous cliquez sur une cellule, activez le mode d'édition pour le champ spécifié

        this.$set(
          this.RythmePaiementVente.pourcentage_rythme_paiement[index],
          "editing",
          field
        );

        // Copiez la valeur actuelle du champ dans un champ temporaire newValue pour édition
        this.RythmePaiementVente.pourcentage_rythme_paiement[index].newValue = value;
       
      }
    },
    stopEditing(index, field) {
      let compteurPaiementPayé = 0;
      let pourcentageTotal = 0;
      let length = Object.keys(this.RythmePaiementVente.pourcentage_rythme_paiement).length;
      let oldValue = this.RythmePaiementVente.pourcentage_rythme_paiement[
          index
        ].pourcentage
      // Lorsque vous cliquez en dehors de la cellule (par exemple, sur un autre élément de la page),
      // désactivez le mode d'édition pour le champ spécifié
      this.$set(
        this.RythmePaiementVente.pourcentage_rythme_paiement[index],
        "editing",
        null
      );
      // Vérifiez si le champ édité est "pourcentage"
      if(field === 'date' && this.$dayjs(this.RythmePaiementVente.pourcentage_rythme_paiement[index].date).isValid()){
        this.RythmePaiementVente.pourcentage_rythme_paiement[index].date = this.$dayjs(this.RythmePaiementVente.pourcentage_rythme_paiement[index].date).format('DD/MM/YYYY')
      }
      if (field === "pourcentage") {
        // Affectez la nouvelle valeur au champ cible
        if(!isNaN(Number(this.RythmePaiementVente.pourcentage_rythme_paiement[
          index
        ].newValue)) && Number(this.RythmePaiementVente.pourcentage_rythme_paiement[index].newValue) >=0){
          this.RythmePaiementVente.pourcentage_rythme_paiement[
            index
          ].pourcentage = Number(this.RythmePaiementVente.pourcentage_rythme_paiement[
            index
          ].newValue);
        }else{
          this.RythmePaiementVente.pourcentage_rythme_paiement[
            index
          ].pourcentage = oldValue
        }
      }
      Object.keys(this.RythmePaiementVente.pourcentage_rythme_paiement).forEach(element=>{
        pourcentageTotal += Number(this.RythmePaiementVente.pourcentage_rythme_paiement[element].pourcentage)
      })
      this.totalPourcentage = pourcentageTotal;
      // Supprimez le champ temporaire newValue
      delete this.RythmePaiementVente.pourcentage_rythme_paiement[index].newValue;
      delete this.RythmePaiementVente.pourcentage_rythme_paiement[index].editing;
      // Vous pouvez également enregistrer la nouvelle valeur ici, par exemple en appelant une API
     
    },
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      var pageNumber = 0;
      this.tableData.search = key;
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "suiviCommerciale/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.projects.forEach((element) => {
                if (
                  !this.tableFournisseur.includes(element.fournisseur) &&
                  element.fournisseur != ""
                ) {
                  this.tableFournisseur.push(element.fournisseur);
                }
              });
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async openDialogPaiement(item, index) {
      this.rapportVenteActuelle = item
      this.nombrePaiement = 0;
      this.minimumNombrePaiement = 0;
      this.totalPourcentage = 0;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getRythmePaiementVente/",
        method: "POST",
        data: {
          idRapportVente: item.id,
        },
      }).then((res) => {
        this.RythmePaiementVente = res.data;
      });
      this.dialogPaiement = true;
      this.raisonSocialePaiement = item.Raison;
      this.listePaiement = this.RythmePaiementVente.pourcentage_rythme_paiement
      this.valeurBrutPaiement = this.RythmePaiementVente.valeurBrut
      Object.keys(this.RythmePaiementVente.pourcentage_rythme_paiement).forEach(
        (element, index) => {
          this.totalPourcentage += Number(this.RythmePaiementVente.pourcentage_rythme_paiement[element].pourcentage)
          if(element !='valeurBrut'){
            if(this.RythmePaiementVente.pourcentage_rythme_paiement[element].statut == 'payé' || this.RythmePaiementVente.pourcentage_rythme_paiement[element].pourcentage == 100){
              this.minimumNombrePaiement++;
            }
            this.nombrePaiement++;
            this.tableModifyValue.push([
              { value: false, label: "paiement" },
              { value: false, label: "date" },
              { value: false, label: "statut" },
            ]);
          }
        }
      );
    },
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "filterRapportVentePro/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async filterResult({ type, key = "", items = "None", numberOfPage = 0 }) {
      if (
        type == "search" ||
        type == "nbrRecords" ||
        type == "filter" ||
        type == "sort" ||
        type == "next"
      ) {
        this.tableData.filterType = type;
      }
      var pageNumber = 0;
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      } else if (type == "filter") {
        this.tableData.filterItems = items;
        this.tableData.filterItems.dateDebutFMIN = this.filterItem.dateDebutFMIN;
        this.tableData.filterItems.dateDebutFMAX = this.filterItem.dateDebutFMAX;
      } else if (type == "sort") {
      } else if (type == "next") {
        pageNumber = numberOfPage;
      }
      this.tableData.search = key;
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "filterRapportVentePro/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          type: this.tableData.filterType,
          itemsFilter: this.tableData.filterItems,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    async getData(pageNumber = 0) {
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getVenteProServerSideRapportDeVente/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          searchItem: "None",
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            this.items["raison"] = response.data.raison;
            this.items["numCompteur"] = response.data.numCompteur;
            this.items["Duree"] = response.data.listeDuree;
            this.items["nom"] = response.data.nom;
            this.items["fournisseur"] = response.data.fournisseur;
            this.items["type"] = response.data.type;
            this.items["Consommation"] = response.data.Consommation;
            this.items["marge"] = response.data.listeMarge;
            this.items["valeurBrut"] = response.data.listevaleurBrut;
            this.items["Pourcentage"] = response.data.listepourcentage;
            this.items["valeurAA"] = response.data.listevaleur;
            this.items["valeurGEMC"] = response.data.listevaleurGemc;
            this.items["status"] = response.data.listevaleurstatus;
            this.filterItem = { ...this.items };
            if (this.tableData.draw == data.draw) {
              this.projectsPrix = [];
              this.projects = data.data.data;
              this.fournisseurSelected = this.tableFournisseur[0];
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  
  watch:{
    nombrePaiement:function(newValue,oldValue){
      let compteurPaiementPayé = 0
      let pourcentageTotal = 0
      if(!!this.RythmePaiementVente.pourcentage_rythme_paiement){
          if(!!oldValue && Number(oldValue) > 0){
            this.oldValueNombrePaiement = Number(oldValue)
          }
        
        if(Number(this.oldValueNombrePaiement) > 0 && Number(newValue) > 0){
          if((Number(newValue) > Number(this.oldValueNombrePaiement))){
          for(let i = Number(Object.entries(this.RythmePaiementVente.pourcentage_rythme_paiement).length+1);i<=Number(newValue);i++){
            this.$set( // permet de mettre a jour dynamiquement les tableaux/objets avec Vue 2
              this.RythmePaiementVente.pourcentage_rythme_paiement,
              `paiement${i}`,
              {
              pourcentage:"0",
              date:this.$dayjs().format('DD/MM/YYYY'),
              statut:"en attente",
              valeur:0
            }
            );
          }
            }else{
            if(Object.entries(this.RythmePaiementVente.pourcentage_rythme_paiement).length == Number(this.oldValueNombrePaiement)){

            for(let i = Number(this.oldValueNombrePaiement);i>Number(newValue);i--){
                if(this.RythmePaiementVente.pourcentage_rythme_paiement[`paiement${i}`].statut != 'payé'){

                  this.$set(this.RythmePaiementVente.pourcentage_rythme_paiement,`paiement${i}`,{})
                  delete this.RythmePaiementVente.pourcentage_rythme_paiement[`paiement${i}`]
                }else{
                  this.nombrePaiement = i
                }
              }
            }
          }
            Object.keys(this.RythmePaiementVente.pourcentage_rythme_paiement).forEach(element=>{
              pourcentageTotal += Number(this.RythmePaiementVente.pourcentage_rythme_paiement[element].pourcentage)
            })
            this.totalPourcentage = pourcentageTotal;
        }
      }
       
    }
  }
};
</script>
<style >

.v-toolbar__content, .v-toolbar__extension{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
#table-gestion-ventes th,
#table-paiment th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}
</style>
