import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        messages:[]
    },
    getters:{
        getMessages:state=>{
            return state.messages
        }
    },

    mutations:{
        addMessage(state,newMessage){
          state.messages = newMessage
        },
    },
    plugins:[new VuexPersistence().plugin]
})
