<template>
    <div class="mr-2 ml-3">
        <!-- modal supprimer -->
        <v-dialog v-model="ouvrireDialogeOffre" max-width="700px">
            <v-card style="overflow: hidden;">
                <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cette vente ?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="fermerDialogeSupprimerOffre"
                    >Annuler</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteOffre"
                    >Confirmer</v-btn
                >
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card class="mt-3 pa-2" elevation="11">
            <v-data-iterator
                :items="allDonner"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                :search="search"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                hide-default-footer
              >
                <template v-slot:header>
                    <v-toolbar
                    dark
                    color="blue darken-3"
                    class="mb-1"
                    >
                    <v-text-field
                        v-model="search"
                        clearable
                        flat
                        solo-inverted
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        label="Search"
                    ></v-text-field>
                    </v-toolbar>
                </template>
            
                <template v-slot:default="props">
                    <v-row>
                    <v-col
                        v-for="item in props.items"
                        :key="item.id"
                        cols="12"
                        sm="12"
                        md="12"
                        lg="12"
                    >
                        <v-row>
                            <v-col cols='12' sm='2' md='1' lg='1' xl='1'
                                v-for="(key, index) in filteredKeys"
                                :key="index"
                                v-if='key=="Date"'
                                :class="{ 'blue--text': sortBy === key }">
                                <span class="text-body-1 grey--text  mb-2"> {{ item['date'] }}</span>
                            </v-col>
                            <v-col cols='12' sm='2' md='2' lg='2' xl='2'
                              v-else-if='key=="Infomation"'
                              :class="{ 'blue--text': sortBy === key }">
                                <p><span class="text-body-1 grey--text  mb-2">Nom: </span><span class="text-body-1 black--text  mb-2">{{ item['nom'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Prenom: </span><span class="text-body-1 black--text  mb-2">{{ item['prenom'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Nom de Jeune Fille: </span><span class="text-body-1 black--text  mb-2">{{ item['nomJeuneFille'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">N° Enfant </span><span class="text-body-1 black--text  mb-2">{{ item['nbrEnfant'] }} </span></p>
                            </v-col>
                            <v-col cols='12' sm='3' md='2' lg='2' xl='2'
                              v-else-if='key=="Contact"'
                              :class="{ 'blue--text': sortBy === key }">
                                <p><span class="text-body-1 grey--text  mb-2">E-mail: </span><span class="text-body-1 black--text  mb-2">{{ item['email'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">N° télé: </span><span class="text-body-1 black--text  mb-2">{{ item['num_Tele'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Adresse Postale: </span><span class="text-body-1 black--text  mb-2">{{ item['adresse'] }} </span></p>
                            </v-col>
                            <v-col cols='12' sm='2' md='2' lg='2' xl='2'
                              v-else-if='key=="Naissance"'
                              :class="{ 'blue--text': sortBy === key }">
                                <p><span class="text-body-1 grey--text  mb-2">Date Nais: </span><span class="text-body-1 black--text  mb-2">{{ item['dateNais'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">ville Nais: </span><span class="text-body-1 black--text  mb-2">{{ item['villeNais'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">dep Nais: </span><span class="text-body-1 black--text  mb-2">{{ item['departementNais'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Pays Nais: </span><span class="text-body-1 black--text  mb-2">{{ item['paysNais'] }} </span></p>
                            </v-col>
                            <v-col cols='12' sm='2' md='2' lg='2' xl='2'
                              v-else-if='key=="Situation"'
                              :class="{ 'blue--text': sortBy === key }">
                                <p><span class="text-body-1 grey--text  mb-2">Nationalité: </span><span class="text-body-1 black--text  mb-2">{{ item['nationalite'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Situation: </span><span class="text-body-1 black--text  mb-2">{{ item['situation'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">numéro SS: </span><span class="text-body-1 black--text  mb-2">{{ item['numSS'] }} </span></p>
                                <p><span class="text-body-1 grey--text  mb-2">Clé: </span><span class="text-body-1 black--text  mb-2">{{ item['cle'] }} </span></p>
                            </v-col>
                            <v-col cols='12' sm='2' md='1' lg='1' xl='1'
                              v-else-if='key=="Attachments"'
                              :class="{ 'blue--text': sortBy === key }">
                                <div v-if="item['cinPath'] != ''">
                                    <a style="text-decoration:none" :href="item['Cin']" target="_blank">
                                        <span class="text-body-2 blue--text mb-2">CIN</span>
                                    </a>
                                </div>
                            
                                <div v-if="item['ribPath'] != ''">
                                    <a style="text-decoration:none" :href="item['Rib']" target="_blank">
                                        <span class="text-body-2 blue--text mb-2">RIB</span>
                                    </a>
                                </div>
                            </v-col>
                            <v-col cols='12' sm='1' md='1' lg='1' xl='1'
                              v-else-if='key=="Action"'
                              :class="{ 'blue--text': sortBy === key }">
                                <v-btn @click="ouvrireDialogeSupprimerOffre(item['id'])" color="error">
                                    Supprimer
                                </v-btn>
                            </v-col>
                        </v-row>
                    <hr>
                    </v-col>
                    </v-row>
                </template>
                <template v-slot:footer>
                    <v-card>
                    <v-row
                        class="ma-0"
                        align="center"
                        justify="center"
                    >
            
                        <v-spacer></v-spacer>
            
                        <span
                        class="mr-4
                        grey--text"
                        >
                        Page {{ page }} of {{ numberOfPages }}
                        </span>
                        <v-btn
                        fab
                        dark
                        color="blue darken-3"
                        class="mr-1 mt-4 mb-0"
                        @click="prev"
                        >
                        <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn
                        @click="next"
                        fab
                        dark
                        color="blue darken-3"
                        class="ml-1 mr-1 mt-4 mb-0"
                        >
                        <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                    
                    </v-card>
                </template>
            </v-data-iterator>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'getAllEmployeur',
        components: {
        },
        data: () => ({
            status : "",
            search: '',
            idOffreDeleted: '',
            ouvrireDialogeOffre: false,
            headers: [
                {text: 'Date', align: 'start', sortable: true, value: 'date'},
                { text: 'Infomation', value: 'nom', sortable: true },
                { text: 'Contact', value: 'email', sortable: true },
                { text: 'Securite sociale', value: 'numSS', sortable: false },
                { text: 'Naissance', value: 'dateNais', sortable: false },
                { text: 'Situation', value: 'nationalite', sortable: true },
                { text: 'Attachments', value:'attachments', sortable: false},
                { text: 'Action', value:"Action", sortable: false},
            ],
            allDonner: [],
            
            itemsPerPageArray: [10, 15, 20, 30],
            search: '',
            filter: {},
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            sortBy: '',
            keys: [
                'Date',
                'Infomation',
                'Contact',
                'Securite sociale',
                'Naissance',
                'Situation',
                'Attachments',
                'Action',
            ],
        }),
        
        computed: {
            numberOfPages () {
            return Math.ceil(this.allDonner.length / this.itemsPerPage)
            },
            filteredKeys () {
            return this.keys
            },
            filteredKeysSorted () {
            return this.keys.filter(key => key !== 'Action')
            },
        },
        created() {
            //this.verifyApplicationDataSecurity()
            this.getAllDonnerEmployeur()
        },
        methods: {
            next(){
                if(this.page<this.numberOfPages){
                    this.page ++
                }
            },
            prev(){
                if(this.page>1){
                    this.page --
                }
            },
            ouvrireDialogeSupprimerOffre(id){
                this.idOffreDeleted = id
                this.ouvrireDialogeOffre = true
            },
            fermerDialogeSupprimerOffre(){
                this.idOffreDeleted = ''
                this.ouvrireDialogeOffre = false
            },
            splitedList(str){
                
                if(str !== '' && str != null){
                    return str.split(',')
                }else{
                    return null
                }
            },
            deleteOffre(id){
                var formData = new FormData()
                formData.append('id', this.idOffreDeleted)
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'deleteNewEmployeur/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        this.getAllDonnerEmployeur()
                    }else if(res.data.result == false){
                        this.getAllDonnerEmployeur()
                    }else{
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                
                this.ouvrireDialogeOffre = false
            },
            verifyApplicationDataSecurity() {
               if (this.$cookies.get('token')) {
                    var formData = new FormData()
                    formData.append('type', localStorage.getItem('typeUser'))
                    formData.append('vueAfficher', 'admin')
                    formData.append('token', this.$cookies.get('token'))
                    axios({
                        url:
                        process.env.VUE_APP_URL_API_CLIENT +
                        'verifyApplicationDataSecurity.php',
                        method: 'POST',
                        data: formData,
                        
                    })
                    .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        this.$cookies.remove('token')
                        this.$router.push('/login')
                    }
                    })
                    .catch((err) => {
                    console.log(err)
                    })
                }else{
                    this.$router.push('/login')
                }
            },
            getAllDonnerEmployeur() {
                let self = this
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAllNewEmployeur/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        self.allDonner = res.data.data
                    }else if(res.data.result == false){
                        alert('une erreur est survenue')
                    }else{
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }

                })
            },
        
        }
    }
</script>