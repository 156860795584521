<template>
  <div>
    <datatable
      id="table-mes-societes"
      class="ma-8"
      :columns="columns"
      :sortKey="sortKey"
      :sortOrders="sortOrders"
      @sort="sortBy"
      @search="rechercheData"
    >
      <template v-slot:body>
        <tbody v-if="projects.length > 0 && loadingTable == false">
          <tr class="text-center" v-for="project in projects" :key="project.id">
            <td>
              <v-tooltip v-if="project.Raison.length >= 20" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <small class="td-table" v-bind="attrs" v-on="on">
                    <router-link
                      :to="{
                        path: '/mes-profils-societes',
                        query: { id: project.idSociete },
                      }"
                      >{{ project.Raison.substring(0, 20) + "..." }}</router-link
                    >
                  </small>
                </template>
                <span>{{ project.Raison }}</span>
              </v-tooltip>
              <small class="td-table" v-else>
                <router-link
                  :to="{
                    path: '/mes-profils-societes',
                    query: { id: project.idSociete },
                  }"
                  >{{ project.Raison }}</router-link
                >
              </small>
            </td>
            <td>
              {{ project.siret }}
            </td>
            <td>
              {{ project.codeNaf }}
            </td>

            <td>
              {{ project.codeInsee ? project.codeInsee : "MANQUANT" }}
            </td>

            <td>
              <div class="responsive-phone">
                <v-tooltip v-if="project.AdressePostal.length > 20" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span style="cursor: pointer" v-bind="attrs" v-on="on">
                      {{ project.AdressePostal.substring(0, 20) + "..." }}
                    </span>
                  </template>
                  <span>{{ project.AdressePostal }}</span>
                </v-tooltip>
                <span v-else>{{ project.AdressePostal }}</span>
              </div>
            </td>
            <td>
              <v-btn small :color="$colors[0]">
                <router-link
                  class="text-white text-decoration-none"
                  :to="{
                    path: '/demande-cotation-gaz-pro',
                    query: { siret: project.siret },
                  }"
                >
                  <span class="Poppins-Bold"
                    >Cotation Gaz<v-icon dense icon class="ml-2"
                      >mdi-fire-circle</v-icon
                    ></span
                  >
                </router-link>
              </v-btn>
            </td>
            <td>
              <v-btn small :color="$colors[0]" class="text-white">
                <router-link
                  class="text-white text-decoration-none"
                  :to="{
                    path: '/demande-cotation-elec-pro',
                    query: { siret: project.siret },
                  }"
                >
                  <span class="Poppins-Bold"
                    >Cotation Elec<v-icon dense class="ml-2"
                      >mdi-lightning-bolt-circle</v-icon
                    ></span
                  >
                </router-link>
              </v-btn>
            </td>
            <td>
              <v-btn icon small class="text-white">
                <router-link
                  class="text-white text-decoration-none"
                  :to="{ path: '/ajouterVenteProMulti', query: { siret: project.siret } }"
                >
                  <v-icon :color="$colors[0]">mdi-shopping-outline</v-icon>
                </router-link>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <noDataTable
          class="ma-4"
          v-else-if="projects?.length == 0 && loadingTable == false"
        />
        <progressCircular class="ma-8" v-else-if="loadingTable == true" />
      </template>
    </datatable>
    <v-row>
      <v-col>
        <pagination
          v-if="projects.length > 0"
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import jQuery from "jquery";

import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import noDataTable from "../../components/no-data-table.vue";
import progressCircular from "../../components/progress-circular.vue";
import axios from "axios";
export default {
  name: "mesSocietes",
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "13vw", label: "Raison Sociale", name: "Raison", sortable: true },
      { width: "13vw", label: "Siret", name: "siret", sortable: true },
      { width: "13vw", label: "NAF", name: "codeNaf", sortable: true },
      { width: "13vw", label: "INSEE", name: "codeInsee", sortable: true },
      { width: "13vw", label: "Adresse", name: "adressePostal", sortable: true },
      { width: "13vw", label: "Cotation gaz", name: "cotationGaz", sortable: true },
      { width: "13vw", label: "Cotation elec", name: "cotationElec", sortable: true },
      { width: "13vw", label: "Ajout vente", name: "ajouterVente", sortable: true },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      loadingTable: false,
      projects: [],
      columns: columns,
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "15", "30"],
      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 0,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      rules: {
        required(finPhrase) {
          return (value) => !!value || `Veuillez saisir ${finPhrase}`;
        },
      },
    };
  },
  async created() {
    this.loadingTable = true;
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", whoIam);
    }
    await this.getData();
    this.loadingTable = false;
  },
  methods: {
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      var pageNumber = 0;
      this.tableData.search = key;
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getToutLesSocietesServerSide/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async getData(pageNumber = 0) {
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getToutLesSocietesServerSide/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<style>
#table-mes-societes th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}
</style>
