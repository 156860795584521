<template>
  <v-form ref="form" v-model="isFormValid" class="mb-12">
    <v-subheader class="pl-0">Recherche des propositions:</v-subheader>

    <v-divider class="mb-12"></v-divider>
    <v-row>
      <v-col sm="6" md="4" lg="3">
        <v-select
          required
          outlined
          @change="setOptionTarifaire"
          v-model="optionTarifaire"
          :items="optionsTarifaires"
          label="Option Tarifaire"
        ></v-select>
      </v-col>

      <v-col sm="6" md="4" lg="3">
        <v-select
          required
          outlined
          v-model="puissanceSouscrite"
          :items="optionsPuissance"
          label="Puissance souscrite"
          suffix="kVA"
        ></v-select>
      </v-col>

      <v-col sm="6" md="4" lg="3" v-if="optionTarifaire === 'CU'">
        <v-text-field
          required
          outlined
          :rules="[required('Consommation'), positive('consommation')]"
          v-model="consommationCU"
          type="number"
          :min="0"
          label="Consommation"
          suffix="MWh"
        />
      </v-col>

      <v-col sm="6" md="4" lg="3" v-if="optionTarifaire === 'MU'">
        <v-text-field
          required
          outlined
          :rules="[required('consommation HP'), positive('consommation HP')]"
          v-model="consommationPleinesMU"
          type="number"
          :min="0"
          label="Consommation HP"
          suffix="MWh"
        />
      </v-col>

      <v-col sm="6" md="4" lg="3" v-if="optionTarifaire === 'MU'">
        <v-text-field
          required
          outlined
          :rules="[required('consommation HC'), positive('consommation HC')]"
          v-model="consommationCreusesMU"
          type="number"
          :min="0"
          label="Consommation HC"
          suffix="MWh"
        />
      </v-col>
    </v-row>

    <!-- Actions -->
    <v-row>
      <v-col
        xs="12"
        sm="6"
        md="3"
        lg="3"
        class="d-flex justify-lg-end justify-md-end justify-sm-center justify-xs-center"
      >
        <v-btn
          @click="submitForm"
          depressed
          :disabled="!isFormValid"
          style="textTransform: none"
          elevation="1"
          large
          :color="$colors[0]"
          class="ma-auto text-white"
          width="90%"
          ><span class="Poppins-Bold">Rechercher</span></v-btn
        >
      </v-col>
      <v-col
        xs="12"
        sm="6"
        md="3"
        lg="3"
        class="d-flex justify-lg-end justify-md-end justify-sm-center justify-xs-center"
      >
        <v-btn
          @click="resetForm"
          depressed
          style="textTransform: none"
          large
          elevation="1"
          class="ma-auto"
          width="90%"
          ><span class="Poppins-Bold">Réinitialiser</span></v-btn
        >
      </v-col>

      <v-spacer></v-spacer>
    </v-row>
  </v-form>
</template>

<script>
export default {
  emits: ['load-propositions', 'set-option-tariffaire'],

  data() {
    return {
      //valeurs listes deroulantes forme propositions
      optionsTarifaires: ['CU', 'MU'],
      optionsPuissance: [3, 6, 9, 12, 15, 18, 24, 30, 36],
      //
      //valeurs forme propositions
      optionTarifaire: 'MU',
      puissanceSouscrite: 9,
      consommationCU: 7.5,
      consommationPleinesMU: 7.5,
      consommationCreusesMU: 4,
      //valeurs validation
      isFormValid: true,

      //regles de validation forme propositions
      required(fieldName) {
        return (value) =>
          !!value || `Veuillez saisir une valeur de ${fieldName}`
      },
      positive(fieldName) {
        return (value) =>
          value > 0 || `Veuillez saisir une valeur valide de ${fieldName}`
      },
    }
  },

  methods: {
    resetForm() {
      this.optionTarifaire = 'MU'
      this.puissanceSouscrite = 9
      this.consommationCU = 7.5
      this.consommationPleinesMU = 7.5
      this.consommationCreusesMU = 4
      this.submitForm()
    },

    submitForm() {
      const formData = {
        optionTarifaire: this.optionTarifaire,
        puissanceSouscrite: this.puissanceSouscrite,
        consommationCU: this.consommationCU,
        consommationPleinesMU: this.consommationPleinesMU,
        consommationCreusesMU: this.consommationCreusesMU,
      }

      this.$emit('load-propositions', formData)
    },

    setOptionTarifaire() {
      this.$emit('set-option-tariffaire', this.optionTarifaire)
    },
  },
}
</script>
