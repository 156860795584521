<template>

    <section style="background-color: #eee;">
        <div class="container-fluid pt-5" style="height: 100%;">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card mb-4">
                        <v-btn
                            style="width: 250px; position: absolute; right: 310px;"
                            @click="NavigateToCotationPage()"
                            :color="$colors[0]"
                            class="white--text"
                            >Editer l'offre </v-btn>
                    <ListePropositionsGaz  v-if="Propositions.length > 0" 
                    :Propositions="Propositions"
                     :SocieteData="null"
                        :numCompteur="null"
                        :typeCompteur="null" 
                        :panel= "[]"
                        :fournisseurActuelle="null"
                        :selected="[]"
                        :listCompteur="[]"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import $ from 'jquery'
    import jQuery from 'jquery'
    import axios from 'axios'
    import ListePropositionsGaz from '../../components/proposition-professionnels-gaz/newGazFormulaireProposition/newListePropositionGazPro.vue'
    export default{
        components: {
            ListePropositionsGaz,
        },
        name: 'newHistoriqueCotationGaz',
        data() {
            return {
                cotation: null,
                Propositions:[],
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
                localStorage.setItem('typeUser', whoIam)
            }
            await this.getCotation();
            console.log(this.Propositions)
        },
        methods: {
            async getCotation(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idCotation', this.$route.query.id)
                await axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAncienCotationGaz/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.cotation = res.data.cotation;
                        for (let pdl of Object.keys(res.data.cotation.cotation)){
                            this.Propositions.push({
                                NumCompteur: pdl,
                                resultat: res.data.cotation.cotation[pdl],
                            })
                        }
                    }else if(res.data.result==false){
                        alert("Il semble que la société n'existe pas")
                    }else if(res.data.result=="permission"){
                        alert('permission')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            },
            NavigateToCotationPage() {
                let id_historique = this.$route.query.id
                let listCompteur = [];
                let siret = "";
                let cotation = this.cotation.cotation;
                console.log('cotation',cotation)
                for (const [key, value] of Object.entries(cotation)) {
                    listCompteur.push(key);
                    if (siret.length == 0) {
                    siret = value.dataSociete.siret;
                    }
                    this.$router.push({
                    path: "/historiqueCotationGazPreparation",
                    query: { siret: siret, listCompteurSelected: listCompteur, idHistorique: id_historique},
                    });
                }
                },
        }
    }

</script>