<template>
  <v-row cols="12" >
    <!-- Chiffre d'Affaire -->
    <v-col cols="12" sm="12" md="4">
      <v-card elevation="0" class="pa-4">
        <div class="d-flex justify-space-between flex-row">
          <span class="font-weight-bold align-self-center" style="color: #103a5e">Chiffre d'Affaire</span>
          <div class="pa-1" style="border: 2px solid #103a5e; border-radius: 10px">
            <v-icon :color="$colors[0]">mdi-finance</v-icon>
          </div>
        </div>
        <span style="color: #103A5E; font-family: Poppins; font-size: 20px; padding: 0;" class="font-weight-bold">
          {{ chiffreAffaireGlobal ? parseFloat(chiffreAffaireGlobal.toFixed(2)).toLocaleString() : 0 }} € / 
          {{ totalvaleurCA ? parseFloat(totalvaleurCA.toFixed(2)).toLocaleString() : 0 }} €
        </span>
      </v-card>
    </v-col>


    <!-- Cotations Rentrées -->
    <v-col cols="12" sm="12" md="4">
      <v-card elevation="0" class="pa-4">
        <div class="d-flex justify-space-between flex-row">
          <span class="font-weight-bold align-self-center" style="color: #103a5e">Cotations Rentrées</span>
          <div class="pa-1" style="border: 2px solid #103a5e; border-radius: 10px">
            <v-icon :color="$colors[0]">mdi-cash-multiple</v-icon>
          </div>
        </div>
        <span style="color: #103A5E; font-family: Poppins; font-size: 20px; padding: 0;" class="font-weight-bold">
          {{ CotationGlobal || 0 }} / {{totalvaleurcotation}}
        </span>
      </v-card>
    </v-col>

    <!-- Ventes Rentrées -->
    <v-col cols="12" sm="12" md="4">
      <v-card elevation="0" class="pa-4">
        <div class="d-flex justify-space-between flex-row">
          <span class="font-weight-bold align-self-center" style="color: #103a5e">Ventes Rentrées</span>
          <div class="pa-1" style="border: 2px solid #103a5e; border-radius: 10px">
            <v-icon :color="$colors[0]">mdi-cash-multiple</v-icon>
          </div>
        </div>
        <span style="color: #103A5E; font-family: Poppins; font-size: 20px; padding: 0;" class="font-weight-bold">
          {{ VenteGlobal || 0 }} / {{totalvaleurvente}}
        </span>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DashHeaderCards',
  props: ['chiffreAffaireGlobal', 'CotationGlobal', 'VenteGlobal', 'totalvaleurCA', 'totalvaleurcotation', 'totalvaleurvente'],
}
</script>

<style scoped>
</style>