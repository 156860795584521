<template>
    <div style="background-color: #eee">
        <v-card style="overflow: hidden;">
    <v-toolbar
       :color="$colors[0]"
       dark>
       <span class="text-h6 text-white">Creation ACD Engie</span>
   </v-toolbar>
   <v-card-text class="my-6">
       <v-form ref="formAcd"  lazy-validation>
           <v-autocomplete 
               v-model="selectedCompteur" 
               :items="listCompteur" 
               hide-no-data 
               :rules="[rules.required]"
               label="Autre compteur" 
               placeholder="Ajouter un compteur" 
               prepend-icon="mdi-account-search-outline"
               chips
               item-text="numCompteur"
               deletable-chips
               multiple
               return-object
               >
               <template v-slot:item="data">
                   <template>
                    <v-list-item-content>
                      <v-list-item-title>{{ data.item.numCompteur }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        data.item.typeDemande
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                   </template>
               </template>
               <template v-slot:prepend-item>
                   <v-list-item ripple @click="toggle">
                       <v-list-item-content>
                           <v-list-item-title>Select All</v-list-item-title>
                       </v-list-item-content>
                   </v-list-item>
                   <v-divider class="mt-2"></v-divider>
               </template>
           </v-autocomplete>
           <div class="d-flex justify-content-end"> 
               <v-btn
                   text
                   color="error"
                   class="mt-8 mr-4"
                   @click="openModalAcdEngie=false"
                   >
                   Fermer
               </v-btn>
               <v-btn
                   :color="$colors[0]"
                   class="mt-8 mr-4 text-white"
                   @click="creationAcdEngie"
                   >
                   Valider
               </v-btn>
           </div>
       </v-form>
   </v-card-text>
</v-card>
    </div>
  </template>
  <script>

    import { degrees, PDFDocument, rgb, StandardFonts, TextAlignment, setFillingRgbColor, setFontAndSize } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
  export default {
    name: "CreationAcdEngie",
    props: {listCompteur:[], societe: "" },
    data() {
      return{
        selectedCompteur: [],

        rules: {
            required: value => (!!value && (""+value).length>0)|| 'Ce champ est obligatoire.',
            requiredString: value => (""+value).length>8|| 'Ce champ est obligatoire.',
            siretLength: v => v.length == 14 || 'Le siret doit comporter 14 chiffres',
            isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
            compteurElecLength: v => v.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
            isGazCompteur: v => (v.match(/^(GI)[0-9]{6}$/) || (v.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
            phoneLength: v => v.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
            phoneisNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
            margeMaxMin: v => (v<=25 && v>=2) || 'La marge doit être comprise entre 2 et 25',
            
            emailRules: [
            v => !!v || 'E-mail obligatoire',
            v => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
            ],
        },
        required(fieldName) {
            return (value) => ((!!value || value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
        },
        consoMaxMin(typeCompteur) {
            return (v) => {
            if (typeCompteur == "T1") {
                if (v >= 0 && v < 6) {
                return true;
                } else {
                return "consomation pour un Tarif T1 doit être comprise entre 0 et 5.99";
                }
            } else if (typeCompteur == "T2") {
                if (v >= 3 && v <= 350) {
                return true;
                } else {
                return "consomation pour un Tarif T2 doit être comprise entre 3 et 350";
                }
            } else if (typeCompteur == "T3") {
                if (v >= 100 && v < 5000) {
                return true;
                } else {
                //250 4999.99
                return "consomation pour un Tarif T3 doit être comprise entre 100 et 4999.99";
                }
            } else if (typeCompteur == "T4") {
                if (v >= 5000) {
                return true;
                } else {
                return "consomation pour un Tarif T4 doit être supérieur ou égal à 5000";
                }
            }
            };
        },
      }
    },
      computed: {
     
      },
      watch: {
      
      },
    methods: {
        closeModal() {
      this.$emit("closeAcdEngieModal");
    },
    toggle() {
      this.selectedCompteur = [...this.listCompteur];
    },
    async creationAcdEngie(){
                console.log(this.selectedCompteur)
                
                this.$refs.formAcd.validate()
                if(this.$refs.formAcd.validate()){
                    //var pdfDoc = await PDFDocument.create()

                    const doc = new jsPDF('p', 'pt', 'a4')

                    //draw image
                    /*var img = new Image()
                    img.src = "/logos/engieAcdLog.png"
                    doc.addImage(img, 'png', 10, 78, 12, 15)*/
                    var tableColumn = ['ELECTRICITE (Point De Livraison) PDL','GAZ (Point de Consommation Estimé) PCE'] 
                    var tableRows = []
                    var lesCompteurGaz = []
                    var lesCompteurElec = []


                    //drawTable
                    for (var i=0; i<this.selectedCompteur.length; i++){
                        if(this.selectedCompteur[i]['typeDemande'] == "ELEC"){
                            lesCompteurElec.push(this.selectedCompteur[i]['numCompteur'])
                        }else{
                            lesCompteurGaz.push(this.selectedCompteur[i]['numCompteur'])
                        }
                    }
                    if(lesCompteurElec.length>=lesCompteurGaz.length){
                        for(var j =0; j<lesCompteurElec.length; j++){
                            if(j<lesCompteurGaz.length){
                                tableRows.push([lesCompteurElec[j], lesCompteurGaz[j]])
                            }else{
                                tableRows.push([lesCompteurElec[j], ""])
                            }
                        }
                    }else{
                        for(var j =0; j<lesCompteurGaz.length; j++){
                            if(j<lesCompteurElec.length){
                                tableRows.push([lesCompteurElec[j], lesCompteurGaz[j]])
                            }else{
                                tableRows.push(["" ,lesCompteurGaz[j]])
                            }
                        }

                    }
                    var columnStyle = {
                        0: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 220, minCellHeight: 30 ,fontStyle: 'normal'},
                        1: {halign: 'center',valign: 'middle', fillColor: [255,255,255], textColor: 0, lineColor: 0, fontSize:9, lineWidth:1, cellWidth: 220, minCellHeight: 30 ,fontStyle: 'normal'},
                    }
                    doc.autoTable(tableColumn, tableRows, 
                    { 
                        startX: 50,
                        startY: 585,
                        margin: { left: 60},
                        columnStyles: columnStyle,
                        headStyles :{fillColor : [210, 210, 210], textColor: 0, halign: 'center',lineWidth:1,valign: 'middle'}
                    })
                    
                    let finalY = doc.lastAutoTable.finalY;
                    let blob = doc.output("arraybuffer");
                    let pdfDoc = await PDFDocument.load(blob)
                    let tablePageDoc = pdfDoc;
                    var pages = tablePageDoc.getPages()
                    var page = pages[0]
                    
                    const ImageLogo = await fetch("/logos/engieAcdLog.png").then((res) =>
                        res.arrayBuffer()
                    )

                    var pngImage = await pdfDoc.embedPng(ImageLogo)
                    //var page = pdfDoc.addPage()
                    page.drawImage(pngImage, {x: 40, y: 720, width: 260,height: 100,})
                    const form = pdfDoc.getForm()
                    var xBegin = 40
                    var yBegin = 680
                    var width = 520
                    var height = 20
                    var fieldName = "para1"
                    //societe.commune 
                    var txt = "AUTORISATION POUR LA RECUPERATION DES DONNEES AUPRES DES GESTIONNAIRES DE RESEAUX"
                    
                    await this.createTextField(10, true, form, page, xBegin, yBegin, width, height, txt, "title"+fieldName, [1, 1,1], 'Helvetica-Bold')
                    var txt = "Je soussigné(e) "+this.societe.prenom+" "+this.societe.nom+" agissant en qualité de "+this.societe.fonction+" de la société "+this.societe.Raison+
                    " immatriculée au RCS sous le numéro de SIRET"+this.societe.siret
                    yBegin = yBegin - 95
                    height = 85
                    width = 490
                    var fieldName = "para2"
                    await this.createTextField(11, true, form, page, xBegin, yBegin, width, height, txt, "title"+fieldName, [1, 1,1], 'Courier')
                    var txt = "Le signataire du présent formulaire déclare être dûment habilité par le client pour la signature du présent document."+
                            "Par la signature de ce document, le Client autorise expressément ENERGYPLUS ainsi que les fournisseurs partenaires"+
                            " dûment habilité(s) à cet effet (liste des fournisseurs sur simple demande), à demander et à recevoir communication"+
                            " auprès des gestionnaires de réseaux assurant l’accès au réseau d’électricité et de gaz du(des) site(s) du client, des"+
                            " données cochées ci-dessous, sous réserve de disponibilité, en vue de la préparation d’une offre de fourniture de gaz"+
                            " et/ou d’électricité :\n"+
                            "   • L’historique des mesures, en kWh, du site (et puissances atteintes et dépassements de puissance)\n"+
                            "   • L’historique des relevés d’index quotidiens, en kWh, et la puissance maximale quotidienne, en kVA ou kWh, du site;\n"+
                            "   • L’historique de courbe de charge, au pas restitué par Enedis, du site;\n"+
                            "   • Les données techniques et contractuelles disponibles du site.\n\n"+
                            "La présente autorisation ne peut être cédée et pourra être retirée à tout moment. Elle est consentie pour toute la"+
                            " période d’exécution du contrat ou à défaut pour la durée de 12 mois, à compter de la date de signature."+
                            "Le Client accepte expressément que ses données personnelles soient conservées par ENERGYPLUS et/ou les"+
                            " gestionnaires de réseaux à des fins de gestion et de traçabilité. Conformément à la loi n°78-17 du 6 janvier 1978"+
                            " modifiée et au règlement (UE) n°2016/679 du 27 avril 2016, les informations recueillies sont enregistrées dans un fichier"+
                            " informatisé par ENERGYPLUS en sa qualité de responsable de traitement à des fins de gestion et de traçabilité "+
                            "des demandes. Vous disposez d’un droit d'accès à vos données, de rectification, d’opposition et d’effacement pour motifs"+
                            " légitimes. Vous disposez, également, d’un droit à la limitation du traitement et à la portabilité des données à caractère"+
                            " personnel vous concernant. Vous pouvez exercer vos droits à l’adresse suivante Enedis, 34 place des Corolles, 92079"+
                            " Paris La Défense Cedex Conformément à la loi « informatique et libertés », vous disposez de la faculté d’introduire une"+
                            " réclamation auprès de la CNIL."
                    yBegin = yBegin - 320
                    height = 340
                    var fieldName = "para3"
                    await this.createTextField(11, true, form, page, xBegin, yBegin, width, height, txt, "title"+fieldName, [1, 1,1], 'Courier')
                    // les table
                    yBegin = yBegin-10
                    /*for (var i=0; i<this.selectedCompteur.length; i++){
                        txt = "* "+this.selectedCompteur[i]
                        yBegin = yBegin - 25
                        height = 20
                        var fieldName = "compteur"+i
                        await this.createTextField(11, true, form, page, xBegin, yBegin, width, height, txt, "title"+fieldName, [1, 1,1], 'Courier')
                        if(yBegin<60){
                            page = pdfDoc.addPage()
                            yBegin = 700
                        }
                    }*/
                    page = pages[pages.length-1]
                    var txt = "En vue de la préparation d’une offre de fourniture de gaz et/ou d’électricité. "
                    yBegin = 842 - finalY - 40
                    height = 20
                    var fieldName = "para4"
                    await this.createTextField(11, true, form, page, xBegin, yBegin, width, height, txt, "title"+fieldName, [1, 1,1], 'Courier')
                    if(yBegin<120){
                        page = pdfDoc.addPage()
                        yBegin = 700
                    }
                    var txt = "La présente autorisation ne peut être cédée et pourra être retirée à tout moment. Elle est consentie pour toute la"+
                    " période d’exécution du contrat ou à défaut pour la durée de 12 mois, à compter de la date de signature."+
                    "\nLes données ainsi acquises sont détruites dès la fin de validité de la présente autorisation"
                    yBegin = yBegin - 40
                    height = 60
                    var fieldName = "para5"
                    await this.createTextField(11, true, form, page, xBegin, yBegin, width, height, txt, "title"+fieldName, [1, 1,1], 'Courier')
                    var dateAjourdhui= new Date().getDate()+"/"+(new Date().getMonth()+1)+"/"+new Date().getFullYear()
                    var txt = "Fait à "+this.societe.commune+" le "+dateAjourdhui
                    yBegin = yBegin - 25
                    height = 20
                    var fieldName = "para6"
                    await this.createTextField(11, true, form, page, xBegin, yBegin, width, height, txt, "title"+fieldName, [1, 1,1], 'Courier')
                    var txt = "Signature du gérant:                                                                                    Cachet"
                    yBegin = yBegin - 25
                    height = 20
                    var fieldName = "para7"
                    await this.createTextField(11, true, form, page, xBegin, yBegin, width, height, txt, "title"+fieldName, [1, 1,1], 'Courier')
                    const pdfBytes = await pdfDoc.save()
                    this.generatedPdf = pdfBytes
                    download(pdfBytes, 'AcdEngie'+this.societe.siret+'.pdf', 'application/pdf')
                }
            },
            async createTextField(fontsize, multiLine,form, page, x, y, width, height, txt, name, bgColor, font){
                var textField = form.createTextField(name)
                textField.setText(txt)
                if(multiLine == true){
                    textField.enableMultiline()
                }
                textField.setAlignment(TextAlignment.Left)
                textField.addToPage(page, {
                    x: x,
                    y: y,
                    width: width,
                    height: height,
                    textColor: rgb(0, 0,0),
                    backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
                    borderWidth: 0,
                })
                textField.acroField.setDefaultAppearance(setFontAndSize(font, fontsize).toString()+setFillingRgbColor(0, 0,0).toString())
                var fields = form.getFields()
                fields.forEach(field => {
                    field.enableReadOnly()
                })
                form.updateFieldAppearances()
            },
    },
  };
  </script>
  <style>
  .tdCenter{
          vertical-align:middle;
      }
    #tableau-multisite-elec.table thead th, #table-multisite-gaz.table thead th{
      background-color:#103A5E;
      color:white;
      font-weight:bold;
    }
  </style>
  <style scoped>
  ::v-deep .v-label{
    margin:0px; 
  }
  
  @media only screen and (max-width:400px){
      .title-container{
        font-size:large;
        margin-left:0px !important;
      }
    }
  </style>
  <style scoped lang="scss">
      .dropzone {
      border: 2px solid #90a4ae56;
      border-radius: 8px;
      min-height: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover {
          cursor: pointer;
      }
      p {
          margin-bottom: 0;
          &:first-of-type {
          font-weight: 500;
          font-size: 1rem;
          color: #263238;
          span {
              color: gray;
          }
          }
          &:last-of-type {
          font-size: 0.8rem;
          }
      }
      }
      .requireddropzone {
      border: 2px dashed red;
      border-radius: 8px;
      min-height: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:hover {
          cursor: pointer;
      }
      p {
          margin-bottom: 0;
          &:first-of-type {
          font-weight: 500;
          font-size: 1rem;
          color: #263238;
          span {
              color: gray;
          }
          }
          &:last-of-type {
          font-size: 0.8rem;
          }
      }
      }
  