<template>
<v-card class="ma-4 d-flex flex-column">
                <v-card-title class="text-h6 text--primary">Informations de la Société</v-card-title>
                <v-card-text class="ma-6  d-flex  flex-row">
                    <v-row align="center" justify="start">
                            <span v-for='(value,name) in object' class="d-flex my-1 mx-2 text-subtitle-1"><p class="font-weight-bold black--text">{{name}}</p> : <p class="ma-0 px-2 black--text">{{value}}</p></span>                      
                    </v-row>
                </v-card-text>
            </v-card>
</template>
<script>
export default {
    name:'CardInformations',
    props:['object'],
}
</script>