<template>
    <div>
        <datatable class="ma-8" :columns="columns" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy"  @search="rechercheData">
            <template v-slot:body>
                <tbody>
                    <tr  v-for="( project, index) in projects" :key="project.id">
                        <td>
                            <div class="td-table">
                                {{project.date}}
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                {{project.typeDemande}}
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                {{project.Raison}}
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                {{project.siret}}
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                {{project.numCompteur}}
                            </div>
                        </td>
                        <td>
                            <div class="td-table">
                                {{project.compteurProfile}}
                            </div>
                        </td>
                        <td>
                        <small class="td-table">
                                <router-link :to="{path: '/ajouterNouvelleOffre', query: { id: project.id }}">Cliquez ici</router-link> 
                        </small>
                        </td>
                        <td>
                            <div class="td-table">
                                <p v-if="project.etat=='activer'" style="color:green;">Actif</p>
                                <p v-else-if="project.etat=='Désactiver'" style="color:orange;">Désactiver</p>
                                <p v-else-if="project.etat=='Refuser'" style="color:red;">Refuser</p>
                            </div>
                        </td>
                        <!--<td>
                            <v-btn
                                v-if="project.etat=='activer'"
                                @click="RefuserLink(project.id)"
                                class="dropdown-project mt-1"
                                color='red lighten-1'
                                small
                                dark>
                                <span>Refuser</span>
                            </v-btn>
                            <v-btn
                                v-if="project.etat!='activer'"
                                @click="activerLink(project.id)"
                                class="dropdown-project mt-1"
                                color='green lighten-1'
                                small
                                dark>
                                <span>Accepter</span>
                            </v-btn>
                        </td>-->
                    </tr>
                </tbody>
            </template>
        </datatable>
        <v-row >
            <v-col >
                <pagination :pagination="pagination"
                    @selectNumberOfRecords="rechercheData"
                    @first="getData(pagination.firstPage)"
                    @last="getData(pagination.lastPage)"
                    @prev="getData(pagination.prevPageUrl)"
                    @current="getData(pagination.currentPage)"
                    @next="getData(pagination.nextPageUrl)">
                </pagination>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";

    import $ from 'jquery'
    import jQuery from 'jquery'

    import Datatable from '../../components/dataTable/Datatable.vue'
    import Pagination from '../../components/dataTable/Pagination.vue'
    import axios from 'axios'
    export default{
        name: 'mesOffre',
        components: {datatable:Datatable, pagination:Pagination},
        data(){
            let sortOrders={};
            let columns = [
               {width:'10%', label:'Date', name:'date', sortable:true},
                {width:'10%', label:'Energie', name:'typeDemande', sortable:true},
                {width:'20%', label:'Raison Sociale', name:'Raison', sortable:true},
                {width:'12%', label:'Siret', name:'siret', sortable:true},
                {width:'12%', label:'PDL/PCE', name:'numCompteur', sortable:true},
                {width:'5%', label:'Type PDL/PCE', name:'compteurProfile', sortable:true},
                {width:'10%', label:'Accès', name:'link', sortable:true},
                {width:'10%', label:'Etat', name:'etat', sortable:true},
                //{width:'5%', label:'Action', name:'action', sortable:true},
            ];
            columns.forEach((column)=>{
                sortOrders[column.name] = -1;
            });
            return{
                projects: [],
                typeUser: localStorage.getItem('typeUser'),
                columns: columns,
                sortKey: 'date',
                sortOrders: sortOrders,
                perPage: ['15', '15', '30'],
                tableData: {
                    draw: 0,
                    length: 15,
                    search: '',
                    column: 0,
                    dir: 'desc',
                },
                pagination: {
                    perPage: ['10', '15', '30'],
                    firstPage: 0,
                    lastPage: '',
                    currentPage: '',
                    total: '',
                    lastPageUrl: '',
                    nextPageUrl: '',
                    prevPageUrl: '',
                    from: '',
                    to: ''
                },

                 rules: {
                    required(finPhrase) {
                    return (value) => !!value || `Veuillez saisir ${finPhrase}`
                    },
                },
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else if(whoIam == "admin"){
              this.$router.push('/toutLesOffreAdmin')
            }else if(whoIam != "fournisseur"){
              this.$router.push('/OldDashBoard')
            }
            this.getData();
        },
        methods: {
            rechercheData({type, key}) {
                console.log(type)
                if(type=='search'){
                    var pageNumber = 0
                    this.tableData.search = key
                }else if(type=="nbrRecords")
                {
                    this.tableData.length = key
                }
                var pageNumber = 0
                this.tableData.search = key
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getLienParFournisseur/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                    .then(response => {
                       if(response.data.result == true){
                            let data = response.data.data;
                            if (this.tableData.draw == data.draw) {
                                this.projects = data.data.data;
                                this.configPagination(data.data);
                            }
                        }else{
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            getData(pageNumber = 0) {
                this.tableData.draw++;
                axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getLienParFournisseur/',
                    method: 'POST',
                    data: {'dataTable': this.tableData,
                        "pageNumber": pageNumber,
                        'token': this.getToken('token')},
                })
                    .then(response => {
                        if(response.data.result == true){
                            let data = response.data.data;
                            if (this.tableData.draw == data.draw) {
                                this.projects = data.data.data;
                                this.configPagination(data.data);
                            }
                        }else{
                            alert('permission refusée')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                })
                .catch(errors => {
                    console.log(errors);
                });
            },
            configPagination(data) {
                this.pagination.lastPage = data.last_page;
                this.pagination.currentPage = data.current_page;
                this.pagination.total = data.total;
                this.pagination.lastPageUrl = data.last_page_url;
                this.pagination.nextPageUrl = data.next_page_url;
                this.pagination.prevPageUrl = data.prev_page_url;
                this.pagination.from = data.from;
                this.pagination.to = data.to;
            },
            sortBy(key) {
                this.sortKey = key;
                this.sortOrders[key] = this.sortOrders[key] * -1;
                this.tableData.column = this.getIndex(this.columns, 'name', key);
                this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
                this.getData();
            },
            getIndex(array, key, value) {
                return array.findIndex(i => i[key] == value)
            },            
            RefuserLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'RefuserLink/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result == true){
                        alert("Il semble que la demande d'offre ait été refusée !")
                        this.getData(this.pagination.currentPage)
                    }else if(res.data.result == false){
                        alert("Impossible de désactiver ce lien")
                        this.getData(this.pagination.currentPage)
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })

            },
            activerLink(id){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'activerLink/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result == true){
                        alert("Lien activé")
                        this.getData(this.pagination.currentPage)
                    }else if(res.data.result == false){
                        alert("Impossible d'activer ce lien")
                        this.getData(this.pagination.currentPage)
                    }else if(res.data.result == "permission"){
                        alert('permission refusée')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
        }
    }
</script>