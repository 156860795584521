<template>
    <div>
        <loadingComponent v-if="dialog==true" @closeDialog='dialog = $event' :dialogProps="dialog" :loadingProps="loading" :errorProps="error" />
        
        <div>
            <v-btn
            @click="generatePdf"
            icon
            depressed class="ma-auto text-capitalize">
                <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
    import loadingComponent from "../../components/ui/loading-component.vue"
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import axios from 'axios'
    export default {
        name: 'premioContrat',
        components:{loadingComponent},
        props: {
            optionTarifaire: '',
            donner: null,
            numCompteur: null,
            societe: null,
            donnerEnedis: null,
            codePremio: '',
        },
        data: () => ({
            
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "",
             generatedPdf: null,
             type: '',
             positionPage1: 1,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             
             data:{
                 dateAjourdhui: new Date().getDate()+"/"+new Date().getMonth()+"/"+new Date().getFullYear(),
                 dateDebut: "",
                 dateFin: "",
                 pdl: "",
                 siret: "", 
                 codeNaf: "", 
                 adresseSociete: "", 
                 adresseCompteur: "", 
                 nom: "",
                 prenom: "", 
                 email: "", 
                 numTele: "", 
                 consommationAnuuelle: "",
                 typeCompteur: "",
                 kva: "",
                kvaBase: '',
                kvaHp: '',
                kvaHc: '',
                kvaPte: '',
                kvaHce: '',
                kvaHpe: '',
                kvaHch: '',
                kvaHph: '',

                 prixPointe: "",
                 prixHPH: "",
                 prixHCH: "",
                 prixHPE: "",
                 prixHCE: "",
                 periodeDebut: [],
                 periodeFin: [],
                 stringLesPeriode: '',
                 aboPeriode: '',
                 ceePeriode: '',
                 capaPeriode: '',
                 idSociete: "",
                 raison: '',
             },
        }),
        created() {
           // this.verifyApplicationDataSecurity()
        },
        methods: {
            
            async getOppid(){
                this.data.idSociete = this.codePremio+""
                if(this.donnerEnedis.donneTechniqueInfo[0].result == 'valide'){
                    this.data.adresseCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie+' '+
                            this.donnerEnedis.donneTechniqueInfo[0].data.codePostal+' '+this.donnerEnedis.donneTechniqueInfo[0].data.commune

                }
                
                this.data.nbrMonth = this.donner.nbrMonth+""
                if(this.donnerEnedis.donneTechniqueInfo[0].data.typeCompteur!=''){
                    this.data.typeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.typeCompteur
                }else{
                    this.data.typeCompteur = this.optionTarifaire
                }
                this.data.codeNaf = this.societe.codeNaf               
                this.data.codePostaleCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.codePostal
                this.data.voieCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie
                this.data.communeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.commune
                this.data.codePostaleSociete = this.societe.codePostal
                this.data.voieSociete = this.societe.Voie
                this.data.communeSociete = this.societe.commune
                
                this.data.nbrYears = this.donner.nbrYears+""
                this.data.dateDebut = this.donner.dateDebut+""
                this.data.dateFin = this.donner.dateFin+""
                this.data.pdl = this.numCompteur+""
                this.data.siret = this.societe.siret+''
                this.data.adresseSociete = this.societe.AdressePostal+""
                this.data.nom = this.societe.nom+""
                this.data.prenom = this.societe.prenom+""
                this.data.email = this.societe.AdresseEmail+""
                this.data.numTele = this.societe.numTele+""
                this.data.raison = this.societe.Raison+""
                this.data.consommationAnuuelle = this.donner.consomationGlobale+""
                
                this.data.kva = this.donnerEnedis.donneTechniqueInfo[0].data.kva+""
                this.data.kvaBase= this.donner.kvaBase+""
                this.data.kvaHp= this.donner.kvaHp+""
                this.data.kvaHc= this.donner.kvaHc+""
                this.data.kvaPte= this.donner.kvaPte+""
                this.data.kvaHce= this.donner.kvaHce+""
                this.data.kvaHpe= this.donner.kvaHpe+""
                this.data.kvaHch= this.donner.kvaHch+""
                this.data.kvaHph= this.donner.kvaHph+""
                if(isNaN(this.data.kvaPte) == true){
                    this.data.kvaPte = ""
                }

                this.data.prixHPH = this.donner.unitairehph+""
                this.data.prixHCH = this.donner.unitairehch+""
                this.data.prixHPE = this.donner.unitairehpe+""
                this.data.prixHCE = this.donner.unitairehce+""
                this.data.prixHC = this.donner.unitairehc+""
                this.data.prixHP = this.donner.unitairehp+""
                this.data.prixBASE = this.donner.unitaire+""
                this.data.prixPointe = this.donner.unitairepointe+""
                if(isNaN(this.data.prixPointe) == true){
                    this.data.prixPointe = ""
                }
                
                this.data.aboPeriode = ""
                this.data.ceePeriode = this.donner.listCee+""
                this.data.capaPeriode = this.donner.listCapa+""
                if(this.data.capaPeriode.indexOf(",")!=-1){
                   this.data.capaPeriode = this.data.capaPeriode.split(',').join(' ')
                }
                if(this.data.ceePeriode.indexOf(",")!=-1){
                   this.data.ceePeriode = this.data.ceePeriode.split(',').join(' ')
                }
                if(this.donner.type.includes('fixe')){
                    this.type = "fixe"

                }else{
                    this.type = "arenh"

                }
                
            },
            async generatePdfNode(){
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialoge= ""
                var dataToSend = {
                    donner: this.donner,
                    donnerEnedis: this.donnerEnedis,
                    optionTarifaire: this.optionTarifaire,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                    codePremio: this.codePremio,
                }
                await axios({
                    url: 'https://editioncontrat.volto.fr/primeoContrat',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur+'_PrimeoEnergie_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })
                
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
            },
            async generatePdf(){
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialoge= ""
                await this.getOppid()
                //get all period possible betwen two date
                this.getPeriods(this.data.dateDebut, this.data.dateFin)
                const url0 = '/pdf/primeo/primeoPage0.pdf'
                const pageZero = await fetch(url0).then((res) =>
                    res.arrayBuffer()
                )
                
                // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                //interloculteur
                multiText = layoutMultilineText(this.data.raison, {
                    alignment: 'left', 
                    fontSize: 13,   
                    font: timesRomanFont,
                    lineHeight: 12,
                    bounds: { width: 350 - 100, height: 1000  }
                })
                
                var startingPositon=200
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 70,
                        y: startingPositon,
                        size: 13,
                        font: timesRomanFont,
                        maxWidth: 350 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                firstPage.drawText(": "+this.data.dateAjourdhui, {
                    x: 135,
                    y: 113,
                    size: 10,
                    color: rgb(0, 0.4, 0.3),
                })
                firstPage.drawText(": "+this.data.dateAjourdhui, {
                    x: 163,
                    y: 97,
                    size: 10,
                    color: rgb(0, 0.4, 0.3),
                })
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                
                var url1 = ""
                if(this.type == "fixe"){
                    url1 = '/pdf/primeo/pageOneFixe.pdf'
                }else{
                    url1 = '/pdf/primeo/pageOne.pdf'
                }
                const pageOne = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                
                // Load a PDFDocument from each of the existing PDFs
                const pageOneDoc = await PDFDocument.load(pageOne)
                getPages = pageOneDoc.getPages()
                firstPage = getPages[0]
                
                firstPage.drawText(this.data.siret, {
                    x: 60,
                    y: 600,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 180,
                    y: 600,
                    size: 12,
                    color: rgb(0, 0, 0),
                })
                //adresse
                const Helvetica = await pageOneDoc.embedFont(StandardFonts.Helvetica)
                var multiText = layoutMultilineText(this.data.adresseSociete, {
                    alignment: 'left', 
                    fontSize: 11,   
                    font: Helvetica,
                    lineHeight: 11,
                    bounds: { width: 250 - 100, height: 1000  }
                })
                
                    var startingPositon=600
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 250,
                        y: startingPositon,
                        size: 11,
                        maxWidth: 250 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                //interloculteur
                multiText = layoutMultilineText(this.data.nom+" "+this.data.prenom+" "+this.data.numTele+" "+this.data.email, {
                    alignment: 'left', 
                    fontSize: 11,   
                    font: Helvetica,
                    lineHeight: 11,
                    bounds: { width: 250 - 100, height: 1000  }
                })
                
                    var startingPositon=600
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 410,
                        y: startingPositon,
                        size: 11,
                        maxWidth: 250 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }

                ///////////////////////////////
                ////////////////////////////votre site de consomation
                firstPage.drawText(this.data.pdl, {
                    x: 80,
                    y: 500,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                //adresse de consomation
                multiText = layoutMultilineText(this.data.adresseCompteur, {
                    alignment: 'left', 
                    fontSize: 11,   
                    font: Helvetica,
                    lineHeight: 11,
                    bounds: { width: 250 - 100, height: 1000  }
                })
                
                var startingPositon=500
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 240,
                        y: startingPositon,
                        size: 11,
                        maxWidth: 250 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                //sonsomation annuelle en MWH1*
                firstPage.drawText(this.data.consommationAnuuelle, {
                    x: 470,
                    y: 500,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                /////////////////////////
                /////////////// puissances souscrites
                firstPage.drawText(this.data.pdl, {
                    x: 80,
                    y: 420,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                //type of turpe
                 firstPage.drawText(this.data.typeCompteur, {
                    x: 235,
                    y: 420,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                //Pointe
                 firstPage.drawText(this.data.kvaPte, {
                    x: 350,
                    y: 420,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                //HPH
                 firstPage.drawText(this.data.kvaHph, {
                    x: 395,
                    y: 420,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                //HCH
                 firstPage.drawText(this.data.kvaHch, {
                    x: 440,
                    y: 420,
                    size: 11,
                    color: rgb(0, 0, 0),
                }) 
                //hCE
                 firstPage.drawText(this.data.kvaHpe, {
                    x: 485,
                    y: 420,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                //hPE
                 firstPage.drawText(this.data.kvaHce, {
                    x: 525,
                    y: 420,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                /////////////////////////
                /////////////// prix de lenergie arenh
                firstPage.drawText(this.data.pdl, {
                    x: 80,
                    y: 260,
                    size: 11,
                    color: rgb(0, 0, 0),
                })
                //type of turpe
                 firstPage.drawText("du "+this.data.dateDebut+" au "+this.data.dateFin, {
                    x: 210,
                    y: 260,
                    size: 9.5,
                    color: rgb(0, 0, 0),
                })
                //Pointe
                 firstPage.drawText(this.data.prixPointe, {
                    x: 348,
                    y: 260,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                //HPH
                 firstPage.drawText(this.data.prixHPH, {
                    x: 394,
                    y: 260,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                //HCH
                 firstPage.drawText(this.data.prixHCH, {
                    x: 438,
                    y: 260,
                    size: 10,
                    color: rgb(0, 0, 0),
                }) 
                //hPE
                 firstPage.drawText(this.data.prixHPE, {
                    x: 480,
                    y: 260,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                //hPE
                 firstPage.drawText(this.data.prixHCE, {
                    x: 522,
                    y: 260,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                /////////////////////////
                /////////////// abo en mois
                /*if(this.data.aboPeriode!=""){
                    firstPage.drawText(this.data.pdl, {
                        x: 80,
                        y: 200,
                        size: 11,
                        color: rgb(0, 0, 0),
                    })
                    //periode de forniture
                    multiText = layoutMultilineText(this.data.stringLesPeriode, {
                        alignment: 'left', 
                        fontSize: 11,   
                        font: Helvetica,
                        lineHeight: 11,
                        bounds: { width: 250 - 100, height: 1000  }
                    })
                    
                    var startingPositon=200
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 240,
                            y: startingPositon,
                            size: 11,
                            maxWidth: 250 - 100,
                            color: rgb(0, 0, 0),
                        })
                        startingPositon = startingPositon - (11)
                    }
                    //periode de forniture
                    multiText = layoutMultilineText(this.data.aboPeriode, {
                        alignment: 'left', 
                        fontSize: 11,   
                        font: Helvetica,
                        lineHeight: 11,
                        bounds: { width: 130 - 100, height: 1000  }
                    })
                    
                    var startingPositon=200
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 460,
                            y: startingPositon,
                            size: 11,
                            maxWidth: 130 - 100,
                            color: rgb(0, 0, 0),
                        })
                        startingPositon = startingPositon - (11)
                    }
                }*/
                
                
                firstPage.drawText(this.data.idSociete, {
                    x: 250,
                    y: 40,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                // merge file
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                var url2 = ""
                
                if(this.type == "fixe"){
                    if(this.data.ceePeriode!=""){
                        url2 = '/pdf/primeo/pageTwoFixe.pdf'
                    }else{
                        url2 = '/pdf/primeo/pageTwoCeeFalseFixe.pdf'
                    }
                }else{
                    if(this.data.ceePeriode!=""){
                        url2 = '/pdf/primeo/pageTwo.pdf'
                    }else{
                        url2 = '/pdf/primeo/pageTwoCeeFalse.pdf'
                    }
                }
                const pageTwo = await fetch(url2).then((res) =>
                    res.arrayBuffer()
                )
                
                // Load a PDFDocument from each of the existing PDFs
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                getPages = pageTwoDoc.getPages()
                firstPage = getPages[0]
                ///////////////////////////////////////////
                //////////////////////////capa
                if(this.data.capaPeriode!=""){
                    if(this.data.ceePeriode!=""){
                        var ypos=340
                        if(this.type == "fixe"){
                            ypos=440
                        }else{
                            ypos=365
                        }
                    }else{
                        var ypos=370
                        if(this.type == "fixe"){
                            ypos=470
                        }else{
                            ypos=365
                        }
                    }
                    
                    firstPage.drawText(this.data.pdl, {
                        x: 80,
                        y: ypos-10,
                        size: 11,
                        color: rgb(0, 0, 0),
                    })

                    //periode de forniture
                    multiText = layoutMultilineText(this.data.stringLesPeriode, {
                        alignment: 'left', 
                        fontSize: 11,   
                        font: Helvetica,
                        lineHeight: 11,
                        bounds: { width: 250 - 100, height: 1000  }
                    })
                    var startingPositon = ypos
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 220,
                            y: startingPositon,
                            size: 11,
                            maxWidth: 250 - 100,
                            color: rgb(0, 0, 0),
                        })
                        startingPositon = startingPositon - (11)
                    }
                    //periode de capa
                    multiText = layoutMultilineText(this.data.capaPeriode, {
                        alignment: 'left', 
                        fontSize: 11,   
                        font: Helvetica,
                        lineHeight: 11,
                        bounds: { width: 130 - 100, height: 1000  }
                    })
                    
                    var startingPositon=ypos
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 440,
                            y: startingPositon,
                            size: 11,
                            maxWidth: 130 - 100,
                            color: rgb(0, 0, 0),
                        })
                        startingPositon = startingPositon - (11)
                    }
                }
                
                 ///////////////////////////////////////////
                //////////////////////////cee
                if(this.data.ceePeriode!=""){
                     var ypos=220
                    if(this.type == "fixe"){
                        ypos=245
                    }else{
                        ypos=220
                    }
                    firstPage.drawText(this.data.pdl, {
                        x: 80,
                        y: ypos-20,
                        size: 11,
                        color: rgb(0, 0, 0),
                    })

                    //periode de forniture cee
                    multiText = layoutMultilineText(this.data.stringLesPeriode, {
                        alignment: 'left', 
                        fontSize: 11,   
                        font: Helvetica,
                        lineHeight: 11,
                        bounds: { width: 250 - 100, height: 1000  }
                    })
                   var startingPositon=ypos-15
                    
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 210,
                            y: startingPositon,
                            size: 11,
                            maxWidth: 250 - 100,
                            color: rgb(0, 0, 0),
                        })
                        startingPositon = startingPositon - (11)
                    }
                    //valeur cee
                    multiText = layoutMultilineText(this.data.ceePeriode, {
                        alignment: 'left', 
                        fontSize: 11,   
                        font: Helvetica,
                        lineHeight: 11,
                        bounds: { width: 130 - 100, height: 1000  }
                    })
                    
                   startingPositon=ypos-15
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 440,
                            y: startingPositon,
                            size: 11,
                            maxWidth: 130 - 100,
                            color: rgb(0, 0, 0),
                        })
                        startingPositon = startingPositon - (11)
                    }
                }
                
                firstPage.drawText(this.data.idSociete, {
                    x: 250,
                    y: 40,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                //////////////////////////////////page three ///////////////////////////
                 const url3 = '/pdf/primeo/pageThree.pdf'
                const PageThree = await fetch(url3).then((res) =>
                    res.arrayBuffer()
                )
                
                const pageThreeDoc = await PDFDocument.load(PageThree)
                getPages = pageThreeDoc.getPages()
                firstPage = getPages[0]
                ///////////////////////////////////////////
                ////////////////////////// page three

                firstPage.drawText(this.data.idSociete, {
                    x: 250,
                    y: 40,
                    size: 8,
                    color: rgb(0, 0, 0),
                })



                
                //////////////////////////////////page Four ///////////////////////////
                 const url4 = '/pdf/primeo/pageFour.pdf'
                const PageFour = await fetch(url4).then((res) =>
                    res.arrayBuffer()
                )
                
                const PageFourDoc = await PDFDocument.load(PageFour)
                getPages = PageFourDoc.getPages()
                firstPage = getPages[0]

                firstPage.drawText(this.data.idSociete, {
                    x: 250,
                    y: 40,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                ///////----------------------------------------------////////////////////////
                //////////////////////////////////page Five ///////////////////////////
                 const url5 = '/pdf/primeo/pageFive.pdf'
                const pageFive = await fetch(url5).then((res) =>
                    res.arrayBuffer()
                )
                
                const pageFiveDoc = await PDFDocument.load(pageFive)
                getPages = pageFiveDoc.getPages()
                firstPage = getPages[0]

                firstPage.drawText(this.data.idSociete, {
                    x: 250,
                    y: 40,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                //////////////////////////////////page Six ///////////////////////////
                 const url6 = '/pdf/primeo/pageSix.pdf'
                const pageSix = await fetch(url6).then((res) =>
                    res.arrayBuffer()
                )
                
                const PageSixDoc = await PDFDocument.load(pageSix)
                getPages = PageSixDoc.getPages()
                firstPage = getPages[0]

                firstPage.drawText(this.data.idSociete, {
                    x: 250,
                    y: 40,
                    size: 8,
                    color: rgb(0, 0, 0),
                })
                //////////////////////////////////last page ///////////////////////////
                 const url8 = '/pdf/primeo/lastPage.pdf'
                const LastPage = await fetch(url8).then((res) =>
                    res.arrayBuffer()
                )
                
                // Load a PDFDocument from each of the existing PDFs
                const pagelastDoc = await PDFDocument.load(LastPage)
                getPages = pagelastDoc.getPages()
                firstPage = getPages[0]
                ///////////////////////////////////////////
                //////////////////////////nom prenom
                var posYLast = 525
                firstPage.drawText(this.data.prenom+" "+this.data.nom, {
                    x: 120,
                    y: posYLast,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.raison, {
                    x: 80,
                    y: posYLast-10,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.siret, {
                    x: 45,
                    y: posYLast-24,
                    size: 10,
                    color: rgb(0, 0, 0),
                })
                firstPage.drawText(this.data.adresseSociete, {
                    x: 60,
                    y: posYLast-34,
                    size: 10,
                    color: rgb(0, 0, 0),
                })

                firstPage.drawText(this.data.idSociete, {
                    x: 250,
                    y: 40,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                // merge file
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, PageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFiveDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, PageSixDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pagelastDoc)


                //fin contact
                const pdfBytes = await mergedPdf.save()

                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_PrimeoEnergie_'+this.data.raison+'_.pdf', 'application/pdf')
                
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
                this.$emit('readonlyExpand',false)
            },
            async addPagesToPdf(mergedPdf, donorPdf) {
                const copiedPages = await mergedPdf.copyPages(
                    donorPdf,
                    donorPdf.getPageIndices()
                )
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page)
                })
                return mergedPdf
            },
            getPeriods(date1, date2){
                date1 = date1.split("/").reverse().join("-")
                date2 = date2.split("/").reverse().join("-")
                var pd = []
                var pf = []
                
                var m1 = new Date(date1).getMonth()+1
                var d1 = new Date(date1).getDate()
                var y1 = new Date(date1).getFullYear()

                
                var m2 = new Date(date2).getMonth()+1
                var d2 = new Date(date2).getDate()
                var y2 = new Date(date2).getFullYear()
                var tmpDateDebut = ""
                var tmpDateFin = ""
                 
                if(y2 != y1){
                    date1 = date1.split("-").reverse().join("/")
                    pd.push(date1)
                    for (var i=y1; i<=y2; i++ ){
                        if(i != y2){
                            var s = i+"-12-31"
                            s = s.split("-").reverse().join("/")
                            pf.push(s)
                            s= (i+1)+"-01-01"
                            s = s.split("-").reverse().join("/")
                            pd.push(s)
                        }
                    }
                    
                    date2 = date2.split("-").reverse().join("/")
                    pf.push(date2)
                }else{
                    
                    date1 = date1.split("-").reverse().join("/")
                    date2 = date2.split("-").reverse().join("/")
                    pd.push(date1)
                    pf.push(date2)
                }
                
                var periode = ""
                for(var i =0; i < pd.length; i++){
                    periode=periode+(" Du "+pd[i]+" au "+pf[i])
                }
                this.data.periodeDebut = pd
                this.data.periodeFin = pf
                this.data.dateDebut = date1
                this.data.dateFin = date2
                this.data.stringLesPeriode = periode
            },
             /*verifyApplicationDataSecurity() {
                let self = this
                var formData = new FormData()
                formData.append('type', localStorage.getItem('typeUser'))
                formData.append('vueAfficher', 'pro')
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'verifyApplicationDataSecurity.php',
                    method: 'POST',
                    data: formData,
                    
                })
                .then((res) => {
                    var data = JSON.parse(JSON.stringify(res.data))
                    if (data[0].result === 'notLogin') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    } else if (data[0].result === 'accessDenied') {
                        localStorage.setItem('vueAfficher', 'particulier')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            },*/
        },
    }
</script>