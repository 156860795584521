<template>
    <v-card class="mt-6 pa-0 mr-2 ml-3" elevation="10" >
        <div class="d-flex justify-content-center">
            <v-toolbar
                :color="$colors[0]"
                dark>
                    <span class="text-h6 pl-0">Informations nouvel employé</span>
            </v-toolbar>
        </div>
        <v-form class="pa-4 pt-8" ref="form"  lazy-validation>
            <v-row>
                <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog==true">
                    <dialogeModal
                    :error="error"
                    :dialog="dialog"
                    :loading="loading"
                    @closeDialog="dialog = false"
                    titre="Enregistrement des données"
                    :messageDialogeSucces="this.messageDialogeSucces"
                    :messageDialogeError="this.messageDialogeError" />
                </v-col>
            </v-row>
            
            <v-row class="justify-center">
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required,rules.isString]"
                        outlined
                        v-model="nom"
                        label="Nom"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required,rules.isString]"
                        outlined
                        v-model="prenom"
                        label="Prénom"
                    ></v-text-field>
                </v-col>

                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        outlined
                        :rules="[rules.required,rules.isString]"
                        v-model="nomJeuneFille"
                        label="Nom de jeune fille"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required, rules.isNumber, rules.phoneLength]"
                        outlined
                        v-model="num_Tele"
                        label="Téléphone"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required,rules.emailRules]"
                        outlined
                        v-model="email"
                        label="Adresse e-mail"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required]"
                        outlined
                        v-model="adresse"
                        label="Adresse Postale"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required, rules.isNumber, rules.ssLength]"
                        outlined
                        v-model="numSS"
                        label="Numéro de sécurité sociale"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required, rules.isNumber, rules.cleLength]"
                        outlined
                        v-model="cle"
                        label="Clé"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required]"
                        outlined
                        type="date"
                        v-model="dateNais"
                        label="Date de naissance"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required]"
                        outlined
                        v-model="villeNais"
                        label="Ville de naissance"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required,rules.isString]"
                        outlined
                        v-model="departementNais"
                        label="Département de naissance"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required,rules.isString]"
                        outlined
                        v-model="paysNais"
                        label="Pays de naissance"
                    ></v-text-field>
                </v-col>
                
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required,rules.isString]"
                        outlined
                        v-model="nationalite"
                        label="Nationalité"
                    ></v-text-field>
                </v-col>
                <v-col cols="10" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        :rules="[rules.required]"
                        outlined
                        v-model="situation"
                        label="Situation familiale"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5" lg="5" xl="5">
                    <v-text-field
                        required
                        type="number"
                        :rules="[rules.required]"
                        outlined
                        v-model="nbrEnfant"
                        label="Nombre d'enfants à charge"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="5" lg="5" xl="5"></v-col>
                
                <v-col cols="10" sm="10" md="10" lg="10" xl="10">
                    <!-- input file -->
                    <v-row align="center" justify="center">
                        <v-col class="ml-lg-12" cols="10" sm="5" md="5" lg="5" xl="5">
                            <div class="flex w-full h-screen items-center justify-center text-center" id="app">
                                <div class="p-12 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                                    <h3 style="color: gray;">Votre CIN ou Carte de sejour</h3>
                                    <input type="file" style="display: none;" name="fields" id="assetsFieldHandle" 
                                    class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange('cin')" ref="file" accept=".pdf,image/*" />

                                    <label for="assetsFieldHandle" class="block cursor-pointer">
                                        <div :class="cinRequired?'requireddropzone':'dropzone'">
                                            <v-icon x-large>cloud_upload</v-icon>
                                            <p>
                                               Déposez votre fichier ici <span><br>(ou)</span>
                                            </p>
                                            <p style="background-color: #e2e2e2;">Séléctionnez le fichier à importer</p>
                                        </div>
                                    </label>
                                    <ul class="mt-4" v-if="Cin != null" v-cloak>
                                        <li class="text-sm p-1">
                                            {{ Cin[0].name }}<v-btn class="ml-2" icon type="button" @click="remove('cin')"><v-icon>mdi-delete</v-icon></v-btn>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </v-col>
                        <v-col class="ml-lg-12" cols="10" sm="5" md="5" lg="5" xl="5">
                            <div class="flex w-full h-screen items-center justify-center text-center" id="app">
                                <div class="p-12 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="dropRib">
                                <h3 style="color: gray;">Votre RIB</h3>
                                
                                    <input type="file" style="display: none;" name="fields" id="assetsFieldHandle1" 
                                    class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange('rib')" ref="fileRib" accept=".pdf,image/*" />
                                    
                                    <label for="assetsFieldHandle1" class="block cursor-pointer">
                                        <div :class="ribRequired?'requireddropzone':'dropzone'">
                                            <v-icon x-large>cloud_upload</v-icon>
                                            <p>
                                                 Déposez votre fichier ici <span><br>(ou)</span>
                                            </p>
                                            <p style="background-color: #e2e2e2;">Sélectionnez le ou les fichier(s) à importer</p>
                                        </div>
                                    </label>
                                    <ul class="mt-4" v-if="Rib != null" v-cloak>
                                        <li class="text-sm p-1">
                                            {{ Rib[0].name }}<v-btn class="ml-2" icon type="button" @click="remove('rib')"><v-icon>mdi-delete</v-icon></v-btn>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
             <!-- button submit -->
            <v-row align="center" justify="center">
                <v-col class="ml-lg-16 ml-6 mt-0" cols="10" sm="8" md="8" lg="6">
                    <v-btn
                    :color='$colors[0]'
                    @click="submitForm"
                    depressed
                    elevation="1"
                    large
                    class="ma-auto white--text font-weight-bold text-capitalize"
                    width="90%"
                    >
                    Envoyer
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>
<script>
import dialogeModal from "../../components/ui/dialogeModal"
import axios from 'axios'
export default {
  name: 'contratEmbauche',
  components:{dialogeModal:dialogeModal},
    data() {
        return {
            //data for dialog
            dialog: false,
            error: false,
            loading: false,
            messageDialogSucces: "votre demande a été envoyée",
            messageDialogeError:"",

            nom: '',
            prenom: '',
            nomJeuneFille: '',
            adresse: '',
            numSS: '',
            cle: '',
            dateNais: '',
            villeNais: '',
            departementNais: '',
            paysNais: '',
            nationalite: '',
            situation: '',
            nbrEnfant: '0',
            email: '',
            num_Tele: '',
            Cin: null,
            Rib: null,
            cinRequired: false,
            ribRequired: false,

            rules: {
                required: value => !!value || 'Ce champ est obligatoire.',
                isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
                ssLength: v => v.length == 13 || 'le numéro de sécurité sociale doit comporter 13 chiffres',
                cleLength: v => v.length == 2 || 'la cle de sécurité sociale doit comporter 2 chiffres',
                isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                phoneLength: v => v.length == 10 || 'Le numéro de téléphone est constitué de 10 chiffres',
                phoneisNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                emailRules:v => !!v && /.+@.+\.+.+/.test(v) || 'E-mail invalide',
            },
            
        }
    },
    
    created() {
    },
    methods: {
        submitForm(){
            if(this.Cin == null){
                this.cinRequired = true
            }else{
                this.cinRequired = false
            }
            if(this.Rib == null){
                this.ribRequired = true
            }else{
                this.ribRequired = false
            }
            if(this.$refs.form.validate() && this.cinRequired==false && this.ribRequired==false){
                
                this.dialog=true
                this.loading=true
                this.error = false
                var formData = new FormData()
                formData.append('nom', this.nom)
                formData.append('prenom', this.prenom)
                formData.append('nomJeuneFille', this.nomJeuneFille)
                formData.append('adresse', this.adresse)
                formData.append('numSS', this.numSS)
                formData.append('cle', this.cle)
                formData.append('dateNais', this.dateNais)
                formData.append('villeNais', this.villeNais)
                formData.append('departementNais', this.departementNais)
                formData.append('paysNais', this.paysNais)
                formData.append('nationalite', this.nationalite)
                formData.append('situation', this.situation)
                formData.append('nbrEnfant', this.nbrEnfant)
                formData.append('email', this.email)
                formData.append('num_Tele', this.num_Tele)
                formData.append('Cin', this.Cin[0])
                formData.append('Rib', this.Rib[0])
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'saveNewEmployeur/',
                    method: 'POST',
                    data: formData,
                }).then((res) => {
                    if(res.data.result == true){
                        this.dialog=true
                        this.loading=false
                        this.error = false
                        this.messageDialogeSucces = "votre demande a été envoyée"
                    }else if (res.data.result == false){
                        
                        this.dialog=true
                        this.loading=false
                        this.error = true
                        this.messageDialogeError = "Une erreur s'est produite, Veuillez réessayer plus tard"
                    }
                }).catch((err)=>{
                    
                    this.dialog=true
                    this.loading=false
                    this.error = true
                    this.messageDialogeError = "Une erreur s'est produite, Veuillez réessayer plus tard"
                })
            }
        },
        onChange(type) {
            if(type == 'cin'){
                this.Cin = this.$refs.file.files
            }else if(type=="rib"){
                this.Rib = this.$refs.fileRib.files
            }
        },
        remove(type) {
            if(type == 'cin'){
                this.Cin = null
            }else if(type=="rib"){
                this.Rib = null
            }
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('bg-green-300')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-green-300');
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },
        drop(event) {
            event.preventDefault();
            if(event.dataTransfer.files.length>1){
                this.$refs.file.files=null;
            }else{
                    this.$refs.file.files = event.dataTransfer.files;
                    this.onChange('cin'); // Trigger the onChange event manually
                    // Clean up
                    event.currentTarget.classList.add('bg-gray-100');
                    event.currentTarget.classList.remove('bg-green-300');
            }
        },
        dropRib(event) {
            event.preventDefault();
            if(event.dataTransfer.files.length>1){
                this.$refs.fileRib.files=null;
            }else{
                    this.$refs.fileRib.files = event.dataTransfer.files;
                    this.onChange('rib'); // Trigger the onChange event manually
                    // Clean up
                    event.currentTarget.classList.add('bg-gray-100');
                    event.currentTarget.classList.remove('bg-green-300');
            }
        },
    },
}

</script>

<style scoped lang="scss">
    .dropzone {
        padding:5px;
        border: 2px dashed #90a4ae;
        border-radius: 8px;
        min-height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
        p {
            margin-bottom: 0;
            &:first-of-type {
            font-weight: 500;
            font-size: 1rem;
            color: #263238;
            span {
                color: gray;
            }
            }
            &:last-of-type {
            font-size: 0.8rem;
            }
        }
    }
    .requireddropzone {
        padding:5px;
        border: 2px dashed red;
        border-radius: 8px;
        min-height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
        p {
            margin-bottom: 0;
            &:first-of-type {
            font-weight: 500;
            font-size: 1rem;
            color: #263238;
            span {
                color: gray;
            }
            }
            &:last-of-type {
            font-size: 0.8rem;
            }
        }
    }
</style>
<style scoped>
@media only screen and (max-width:350px){
  .page-title{
    font-size:1.2em;
  }
}
</style>