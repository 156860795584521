<template>
  <v-card class="mt-6 pa-0 mr-2 ml-3" elevation="10">
    <v-toolbar :color="$colors[0]" dark>
      <span class="Poppins-Bold pl-0">Création de message</span>
    </v-toolbar>
    <v-form class="pa-4" ref="form" lazy-validation>
      <v-row>
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-radio-group v-model="color" row>
            <v-radio color="green" label="vert" value="goodNews"></v-radio>
            <v-radio color="amber" label="orange" value="warningNews"></v-radio>
            <v-radio color="red" label="rouge" value="badNews"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="11" md="11" lg="11" xl="11">
          <v-textarea
            v-model="message"
            solo
            auto-grow
            label="Message"
            color="teal"
            :rules="[required('un message valide')]"
          >
          </v-textarea>
          <div>
            <emoji-picker @emoji="insert" :search="search">
              <div
                slot="emoji-invoker"
                slot-scope="{ events: { click: clickEvent } }"
                @click.stop="clickEvent"
              >
                <button type="button"><v-icon>mdi-emoticon-outline</v-icon></button>
              </div>
              <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
                <div>
                  <div>
                    <v-text-field
                      solo
                      dense
                      type="text"
                      label="Search Emoji"
                      v-model="search"
                    />
                  </div>
                  <div style="max-height: 150px; overflow-y: auto">
                    <div v-for="(emojiGroup, category) in emojis" :key="category">
                      <h5>{{ category }}</h5>
                      <div>
                        <span
                          v-for="(emoji, emojiName) in emojiGroup"
                          :key="emojiName"
                          @click="insert(emoji)"
                          :title="emojiName"
                          >{{ emoji }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </emoji-picker>
          </div>
        </v-col>
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-btn
            depressed
            :loading="loadingButtonAddMessage"
            :color="$colors[0]"
            class="mb-4 text-white"
            @click="ajouterMessage(message, color)"
            ><span class="Poppins-Bold">Ajouter un message</span></v-btn
          >
          <v-snackbar v-model="snackbar" timeout="2000" color="green accent-3">
            <template v-slot:default>
              <span class="d-flex flex-row justify-content-center align-items-center">
                <v-icon>mdi-check-circle</v-icon>
                <p class="ma-0 ml-2">Message ajouté !</p>
              </span>
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="messages.length > 0" class="mb-4 pa-4">
      <div>
        <p class="my-4 ml-2">
          <b><span class="black--text font-italic me-1">Liste des messages</span></b>
        </p>
        <b-table
          id="table-controle-alert"
          responsive
          bordered
          fixed
          :fields="fieldMessage"
          hover
          :items="messages"
        >
          <template #cell(date)="data">
            <span>{{ $dayjs(data.item.date).format("DD/MM/YYYY") }}</span>
          </template>
          <template #table-busy>
            <div
              class="d-flex flex-column flex-lg-row justify-content-center text-primary my-2"
            >
              <b-spinner
                style="width: 2rem; height: 2rem"
                class="align-middle mx-4"
              ></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <template #cell(message)="data">
            <span style="width: 60%; text-align: end">{{ data.item.message }}</span>
          </template>
          <template #cell(action)="data">
            <div class="d-flex justify-content-center align-items-center">
              <v-dialog persistent v-model="dialog" width="750" :retain-focus="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="newMessage = data.item"
                    class="mx-2"
                    color="grey"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-message-text</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-form ref="formUpd">
                    <v-toolbar :color="$colors[0]" class="text-white" dark>
                      <v-card-title
                        class="d-flex justify-content-center align-items-center Poppins-Bold"
                      >
                        Modification de message
                      </v-card-title>
                    </v-toolbar>
                    <v-col cols="12" md="12" lg="12" xl="12">
                      <span>Importance du message :</span>
                      <v-radio-group v-model="newMessage.color" row>
                        <v-radio color="green" label="vert" value="goodNews"></v-radio>
                        <v-radio
                          color="amber"
                          label="orange"
                          value="warningNews"
                        ></v-radio>
                        <v-radio color="red" label="rouge" value="badNews"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <span class="ml-4">Nouveau message :</span>
                    <v-textarea
                      class="ma-4"
                      ref="newMessage"
                      solo
                      auto-grow
                      :label="newMessage.message"
                      color="teal"
                    >
                    </v-textarea
                    ><!-- Maximum call stack size exceeded -> v-model message -->

                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="error" text @click="dialog = false">
                        <span class="Poppins-Bold">Fermer</span>
                      </v-btn>
                      <v-btn
                        :color="$colors[0]"
                        class="text-white"
                        @click="modifierMessage(data.item.id)"
                        :loading="loadingButtonModifyMessage"
                      >
                        <span class="Poppins-Bold">Modifier</span>
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
              <v-btn
                v-if="data.item.order > 1"
                :loading="loadingButton"
                icon
                :color="$colors[0]"
                id="background-hover"
                @click="test(data.item)"
                ><v-icon dense>mdi-arrow-up-bold-box</v-icon></v-btn
              >
              <v-btn
                icon
                id="background-hover"
                color="error"
                @click="deleteMessage(data.item.id)"
                ><v-icon dense>mdi-delete</v-icon></v-btn
              >
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </v-card>
</template>
<script>
import axios from "axios";
import EmojiPicker from "vue-emoji-picker";
import dialogeModal from "../../components/ui/dialogeModal";
export default {
  name: "addRapideMessage",
  components: {
    dialogeModal: dialogeModal,
    EmojiPicker: EmojiPicker,
  },
  data() {
    return {
      snackbar: false,
      loadingButtonAddMessage: false,
      loadingTable: false,
      loadingButtonModifyMessage: false,
      loadingButton: false,
      newMessage: { color: "", message: "", id: 0 },
      dialog: false,
      message: "",
      color: "goodNews",
      search: "",
      messages: [],
      lesOrder: [],
      fieldMessage: [
        { key: "date", label: "Date d'ajout" },
        { key: "message", label: "Message" },
        { key: "color", label: "Couleur" },
        { key: "action", label: "Action" },
      ],
      required(fieldName) {
        return (value) =>
          (!!value && value >= 0) ||
          (!!value && value != "") ||
          `Veuillez saisir ${fieldName}`;
      },
    };
  },
  async created() {
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", whoIam);
    }
    this.getAllMessage();
  },
  computed: {},
  methods: {
    async test(item) {
      this.loadingButton = true;
      let indexElement = this.lesOrder.findIndex((element) => element == item.order);
      if (indexElement != 0) {
        var tmp = this.lesOrder[indexElement];
        this.lesOrder[indexElement] = this.lesOrder[indexElement - 1];
        this.lesOrder[indexElement - 1] = tmp;
        var formData = new FormData();
        formData.append("token", this.getToken("token"));
        formData.append("lesOrder", this.lesOrder);
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "modifierLesOrdre/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == "permission") {
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {});
        await this.getAllMessage();
        this.loadingButton = false;
      }
    },
    async modifierMessage() {
      this.loadingButtonModifyMessage = true;
      if (this.$refs.formUpd.validate()) {
        this.$refs.newMessage.lazyValue =
          this.$refs.newMessage.lazyValue == null
            ? this.newMessage.message
            : this.$refs.newMessage.lazyValue;
        var formData = new FormData();
        formData.append("message", this.$refs.newMessage.lazyValue);
        formData.append("color", this.newMessage.color);
        formData.append("id", this.newMessage.id);
        formData.append("token", this.getToken("token"));

        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "modifierMessage/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == "permission") {
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {});
        await this.getAllMessage();
      }
      this.$refs.newMessage.reset();
      this.newMessage.id = 0;
      this.loadingButtonModifyMessage = false;
      this.dialog = false;
    },
    insert(emoji) {
      this.message += emoji;
    },
    async deleteMessage(id) {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "deleteMessage/",
        method: "POST",
        data: {
          id: id,
          token: this.getToken("token"),
        },
      })
        .then((res) => {
          if (res.data.result == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      await this.getAllMessage();
    },
    async getAllMessage() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getListmessageRapide/",
        method: "POST",
        data: {},
      })
        .then((res) => {
          this.messages = res.data.result;
          this.lesOrder = res.data.lesOrder;
          this.$store.commit("addMessage", this.messages);
        })
        .catch((err) => {});
    },
    async ajouterMessage(message, color, value) {
      if (this.$refs.form.validate() || value) {
        this.snackbar = true;
        this.loadingButtonAddMessage = true;
        var formData = new FormData();
        formData.append("message", message);
        formData.append("color", color);
        formData.append("token", this.getToken("token"));

        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "ajouterMessage/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == "permission") {
              localStorage.removeItem("vendeurName");
              localStorage.removeItem("typeUser");
              localStorage.removeItem("token");
              this.$router.push("/login");
            }
          })
          .catch((err) => {});
        await this.getAllMessage();
        this.message = "";
        this.$refs.form.resetValidation();
        this.loadingButtonAddMessage = false;
      }
    },
  },
};
</script>
<style>
#table-controle-alert th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}
#table-controle-alert td {
  text-align: center;
}
</style>
<style scoped>
::v-deep .table-bordered thead th {
  /*class header element in b-table*/
  text-align: center !important;
}
::v-deep .table-striped tbody {
  /* class tbody element in b-table*/
  text-align: center !important;
}
</style>
