<template>
  <v-card class="mt-6 pa-4 mr-2 ml-3 px-16 page" elevation="10">
    <h1 class="my-4 mb-6">Tableau de bord</h1>

    <v-subheader class="px-0"
      >Récapitulatif des contrats professionnnels :</v-subheader
    >
    <v-divider class="mb-12"></v-divider>

    <v-row class="mb-10 header">
      <v-avatar>
        <v-icon size="30"> mdi-lightning-bolt </v-icon>
      </v-avatar>

      <v-subheader class="pl-1"> Électricité :</v-subheader>
    </v-row>
    <!-- Les Indicateurs de repformance Electicite-->
    <v-row>
      <!-- Nombre ACD par vendeur -->
      <v-col class="py-0 mb-7" cols="12" xs="12" sm="6" md="3" lg="2">
        <cardComponent
          class="cool"
          :loadersProps="this.loaders.loadingVendeurInfo"
          iconProps="mdi-equalizer"
          h5Title="ACD"
          :h2Title="this.statistiquesDemandes['acd']['elec']"/>
      </v-col>
      <!-- Nombre DC par vendeur -->
      <v-col class="py-0 mb-7" cols="12" xs="12" sm="6" md="3" lg="2">
        <cardComponent
          class="cool"
          :loadersProps="this.loaders.loadingVendeurInfo"
          iconProps="mdi-equalizer"
          h5Title="DCc"
          :h2Title="this.statistiquesDemandes['dc']['elec']"/>
      </v-col>
      <!-- Nombre Ventes par vendeur -->
      <v-col class="py-0 mb-7" cols="12" xs="12" sm="6" md="3" lg="2">
        <cardComponent
          class="win"
          :loadersProps="this.loaders.loadingVendeurInfo"
          iconProps="mdi-cash"
          h5Title="Ventes"
          h2Title="?"/>
      </v-col>
      <!-- Objectifs de vendeur -->
      <v-col class="py-0 mb-7" cols="12" xs="12" sm="6" md="3" lg="2">
        <cardComponent
          class="objectif"
          :loadersProps="this.loaders.loadingVendeurInfo"
          iconProps="mdi-bullseye-arrow"
          h5Title="Objectif"
          h2Title="?"/>
      </v-col>
    </v-row>

    <v-row class="mb-10 mt-6 header">
      <v-avatar>
        <v-icon size="30"> mdi-fire </v-icon>
      </v-avatar>

      <v-subheader class="pl-1"> Gaz :</v-subheader>
    </v-row>
    <!-- Les Indicateurs de repformance Gaz-->
    <v-row>
      <!-- Nombre ACD par vendeur -->
      <v-col class="py-0 mb-7" cols="12" xs="12" sm="6" md="3" lg="2">
        <cardComponent
          class="cool"
          :loadersProps="this.loaders.loadingVendeurInfo"
          iconProps="mdi-equalizer"
          h5Title="ACD"
          :h2Title="this.statistiquesDemandes['acd']['gaz']"/>
      </v-col>
      <!-- Nombre DC par vendeur -->
      <v-col class="py-0 mb-7" cols="12" xs="12" sm="6" md="3" lg="2">
        <cardComponent
          class="cool"
          :loadersProps="this.loaders.loadingVendeurInfo"
          iconProps="mdi-equalizer"
          h5Title="DC"
          :h2Title="this.statistiquesDemandes['dc']['gaz']"/>
      </v-col>
      <!-- Nombre Ventes par vendeur -->
      <v-col class="py-0 mb-7" cols="12" xs="12" sm="6" md="3" lg="2">
      <cardComponent
          class="win"
          :loadersProps="this.loaders.loadingVendeurInfo"
          iconProps="mdi-cash"
          h5Title="Ventes"
          h2Title="?"/>
      </v-col>
      <!-- Objectifs de vendeur -->
      <v-col class="py-0 mb-7" cols="12" xs="12" sm="6" md="3" lg="2">
        <cardComponent
          class="objectif"
          :loadersProps="this.loaders.loadingVendeurInfo"
          iconProps="mdi-bullseye-arrow"
          h5Title="Objectif"
          h2Title="?"/>
      </v-col>
    </v-row>

    <div v-if="this.user.crmUserId">
      <v-subheader class="mt-8 px-0">Recapitulatif CRM :</v-subheader>
      <v-divider class="mb-12"></v-divider>

      <v-row>
        <!-- Table: Dernières ventes professionnelles de vendeur -->
        <v-col cols="12" sm="12" lg="6">
          <v-card class="table">
            <v-card-text class="pa-5">
              <div>
                <span class="l-stick"></span>
                <h3 class="title font-weight-regular">Dernières ventes</h3>
                <h6
                  class="blue-grey--text text-no-wrap text--lighten-2 font-weight-regular subtitle-1"
                >
                  Contrats clôturés
                </h6>
              </div>
            </v-card-text>

            <div class="pa-5 blue-grey lighten-5 d-md-flex align-center">
              <div>
                <h3 class="title font-weight-regular">
                  Montant total des ventes
                </h3>
                <h6 class="text-no-wrap font-weight-regular subtitle-1">
                  depuis Janvier 2022
                </h6>
              </div>
              <v-spacer></v-spacer>
              <div>
                <div v-if="this.loaders.loadingWonLeads">
                  <Loader />
                </div>
                <h2 class="primary--text font-weight-regular" v-else>
                  {{ this.formatterEuro.format(this.crm.salesTotal) }}
                </h2>
              </div>
            </div>
            <v-card-text class="pa-10" v-if="this.loaders.loadingWonLeads">
              <Loader size="150" />
            </v-card-text>
            <v-card-text class="pa-5" v-else>
            
             <v-data-table
                :headers="headersTableVentes"
                :items="crm.leads.won"
                :options="{ itemsPerPage: 5 }"
                :footer-props="{
                  itemsPerPageOptions: [],
                }"
              >
                <template v-slot:item.amount="{ item }">
                  <span>{{ formatterEuro.format(item.amount) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Line chart -->

        <v-col cols="12" sm="12" lg="6">
          <v-card>
            <v-card-text class="pa-5">
              <div>
                <span class="l-stick"></span>
                <h3 class="title font-weight-regular">Rapport Closing</h3>
                <h6
                  class="blue-grey--text text-no-wrap text--lighten-2 font-weight-regular subtitle-1"
                >
                  Contrats clôturés
                </h6>
              </div>
            </v-card-text>

            <div class="pa-5 blue-grey lighten-5 d-md-flex align-center">
              <div>
                <h3 class="title font-weight-regular">
                  Nombre de contrats vendus
                </h3>
                <h6 class="text-no-wrap font-weight-regular subtitle-1">
                  depuis Janvier 2022
                </h6>
              </div>
              <v-spacer></v-spacer>
              <div>
                <div v-if="this.loaders.loadingWonLeads">
                  <Loader />
                </div>
                <h2 class="primary--text font-weight-regular" v-else>
                  {{ this.crm.nbSalesTotal }}
                </h2>
              </div>
            </div>
            <v-card-text class="pa-10" v-if="this.loaders.loadingWonLeads">
              <Loader size="150" />
            </v-card-text>
            <v-card-text class="pa-5" v-else>
              <div id="chart">
                <apexchart
                  type="line"
                  height="350"
                  :options="options"
                  :series="series"
                ></apexchart>
              </div>
            </v-card-text>
            <div></div>
          </v-card>
        </v-col>
      </v-row>
      <!-- Table Dernières Contacts -->
      <v-row>
        <!-- Table: Dernières ventes professionnelles de vendeur -->
        <v-col cols="12" sm="12" lg="9">
          <v-card class="d-flex flex-column table">
            <v-card-text class="pa-5">
              <div>
                <span class="l-stick"></span>
                <h3 class="title font-weight-regular">Dernières Contacts</h3>
                <h6
                  class="blue-grey--text text-no-wrap text--lighten-2 font-weight-regular subtitle-1"
                >
                  Contacts
                </h6>
              </div>
            </v-card-text>

            <v-card-text
              class="my-auto pa-10"
              v-if="this.loaders.loadingRecentLeads"
            >
              <Loader size="150" />
            </v-card-text>

            <v-card-text class="pa-5" v-else>
            
                    <v-data-iterator
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        :items="crm.leads.recent"
                        
                        sort-by="updated_at"
                        :sort-desc="true"
                        item-key="id"
                        hide-default-footer
                        >
                        <template v-slot:default="props">
                        <v-row>
                            <v-col
                              v-for="item in props.items"
                              :key="item.id"
                              cols="12"
                              sm="12"
                              md="12"
                              lg="12"
                              >
                              <v-card>
                                  <v-list dense >
                                    <v-list-item>
                                        <v-list-item-content>
                                          <v-row>
                                              <v-col cols="12" sm="4" md="4" lg="4" xl='4'>
                                                  <div style="max-height:130px; overflow: auto;">
                                                      <p><span class="text-body-1 grey--text  mb-2">Entreprise:</span>{{item.title_company}}</p>
                                                  </div>
                                              </v-col>
                                              <v-col cols="12" sm="4" md="4" lg="4" xl='4'>
                                                      <span class="text-body-1 grey--text  mb-2">Contact: </span>
                                                      <p>{{item.full_name}}</p>
                                              </v-col>
                                              <v-col cols="12" sm="3" md="3" lg="3" xl='3'>
                                                  <span class="text-body-1 grey--text  mb-2">Mise à jour: </span>
                                                  <p>{{item.updated_at}}</p>
                                              </v-col>
                                              <v-col cols="12" sm="1" md="1" lg="1" xl='1'>
                                                  <a target="_blank" :href="`https://gazelecmoinscher.nocrm.io/leads/${item.id}`">
                                                      ouvrir
                                                  </a>
                                              </v-col>
                                          </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                              </v-card>
                            </v-col>
                        </v-row>
                        </template>
                        
                        <template v-slot:footer>
                          <v-row
                            class="mt-2"
                            align="center"
                            justify="center"
                          >
                            <span class="grey--text">Items per page</span>
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  dark
                                  text
                                  :color="$colors[0]"
                                  class="ml-2 text-white"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ itemsPerPage }}
                                  <v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(number, index) in itemsPerPageArray"
                                  :key="index"
                                  @click="updateItemsPerPage(number)"
                                >
                                  <v-list-item-title>{{ number }}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                  
                            <v-spacer></v-spacer>
                  
                            <span
                              class="mr-4
                              grey--text"
                            >
                              Page {{ page }} of {{ numberOfPages }}
                            </span>
                            <v-btn
                              dark
                              text
                              :color="$colors[0]"
                              class="ml-2 text-white"
                              @click="formerPage"
                            >
                              <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn
                              dark
                              text
                              :color="$colors[0]"
                              class="ml-2 text-white"
                              @click="nextPage"
                            >
                              <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                          </v-row>
                        </template>
                    </v-data-iterator>
            </v-card-text>

            <div class="py-3 pl-6 blue-grey lighten-5">
              <v-btn
                href="https://gazelecmoinscher.nocrm.io/leads/new"
                target="_blank"
                :color="$colors[0]"
                class="text-white"
                depressed
                style="texttransform: none"
                large
                >Ajouter +
              </v-btn>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="12" lg="3">
          <v-row>
            <v-col cols="12" sm="6" md="3" lg="12" xl="12">
              <!-- Contacts total periode -->
              <v-card class="mb-5">
                <v-card-text class="pa-5">
                  <div v-if="this.loaders.loadingRecentLeads">
                    <Loader />
                  </div>
                  <div class="d-flex align-center cool" v-else>
                    <span class="l-stick"></span>
                    <v-avatar class="bordered">
                      <v-icon> mdi-account-box </v-icon>
                    </v-avatar>
                    <div class="ml-4 mr-1">
                      <h5
                        class="subtitle-1 grey--text text-no-wrap text-truncate"
                      >
                        Contacts periode
                      </h5>
                      <h2 class="font-weight-regular">
                        {{ this.crm.totalContactRecent }}
                      </h2>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="6" md="3" lg="12" xl="12">
              <!-- A contacter aujourdhui -->
              <v-card class="mb-5">
                <v-card-text class="pa-5">
                  <div v-if="this.loaders.loadingRecentLeads">
                    <Loader />
                  </div>
                  <div class="d-flex align-center win" v-else>
                    <span class="l-stick"></span>
                    <v-avatar class="bordered">
                      <v-icon> mdi-phone </v-icon>
                    </v-avatar>
                    <div class="ml-4 mr-1">
                      <h5
                        class="subtitle-1 grey--text text-no-wrap text-truncate"
                      >
                        Contacter aujourdhui
                      </h5>
                      <h2 class="font-weight-regular">
                        {{ this.crm.leadsContactToday }}
                      </h2>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col xs="12" sm="6" md="3" lg="12" xl="12">
              <!-- A contacter bientot -->
              <v-card class="mb-5">
                <v-card-text class="pa-5">
                  <div v-if="this.loaders.loadingRecentLeads">
                    <Loader />
                  </div>
                  <div class="d-flex align-center objectif" v-else>
                    <span class="l-stick"></span>
                    <v-avatar class="bordered">
                      <v-icon> mdi-phone </v-icon>
                    </v-avatar>
                    <div class="ml-4 mr-1">
                      <h5
                        class="subtitle-1 grey--text text-no-wrap text-truncate"
                      >
                        Contacter bientot
                      </h5>
                      <h2 class="font-weight-regular">
                        {{ this.crm.leadsContactSoon }}
                      </h2>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col xs="12" sm="6" md="3" lg="12" xl="12">
              <!-- Contrats en retard -->
              <v-card class="mb-5">
                <v-card-text class="pa-5">
                  <div v-if="this.loaders.loadingRecentLeads">
                    <Loader />
                  </div>
                  <div class="d-flex align-center alert" v-else>
                    <span class="l-stick"></span>
                    <v-avatar class="bordered">
                      <v-icon> mdi-phone-alert </v-icon>
                    </v-avatar>
                    <div class="ml-4 mr-1">
                      <h5
                        class="subtitle-1 grey--text text-no-wrap text-truncate"
                      >
                        Contacts en retard
                      </h5>
                      <h2 class="font-weight-regular">
                        {{ this.crm.leadsContactLate }}
                      </h2>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios'
import { leads } from '../../assets/data/leads.js'
import Loader from '../../components/ui/Loader.vue'
import { users } from '../../assets/data/users.js'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import cardComponent from "./components/cardComponent"

export default {
  components: {
    apexchart: VueApexCharts,
    Loader: Loader,
    cardComponent: cardComponent
  },
  data() {
    return {
      
      itemsPerPageArray: [3, 6, 9, 12],
      search: '',
      filter: {},
      sortDesc: true,
      page: 1,
      itemsPerPage: 3,
      loaders: {
        //crm
        loadingWonLeads: true,
        loadingRecentLeads: true,
        //bd gazelec
        loadingVendeurInfo: true,
      },

      formatterEuro: new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      }),
      crm: {
        salesByMonth: { labels: [], data: [] },
        salesTotal: 0,
        nbSalesTotal: 0,
        contractsTotal: 0,
        totalContactRecent: 0,
        leadsContactSoon: 0,
        leadsContactLate: 0,
        leadsContactToday: 0,
        leads: { won: [], recent: [] },
      },
      user: {
        email: null,
        name: null,
        type: null,
        crmUserId: null,
      },
      //liste de demandes faites par utilisateur:  Autorisations de Collecte et Demandes de Cotaion
      demandesVendeur: [],
      statistiquesDemandes: {
        dc: { gaz: 0, elec: 0 },
        acd: { gaz: 0, elec: 0 },
      },

      //Tables
      leads: leads,
      filteredLeads: [],
      filteredWonLeads: [],

      headersTableVentes: [
        {
          text: 'Entreprise',
          align: 'left',
          sortable: false,
          //nom de variable dans data
          value: 'title_company',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: 'Date de clôture',
          align: 'left',
          //nom de variable dans data
          value: 'closed_at',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'font-weight-light text-no-wrap subtitle-2 body-1 ',
        },
        {
          text: 'Montant',
          align: 'right',
          //nom de variable dans data
          value: 'amount',
          class: 'font-weight-medium subtitle-1 ',
          cellClass: 'text-no-wrap subtitle-2 body-1 primary--text ',
        },
      ],
    }
  },

  async mounted() {
    //va attendre la fin d'execution pour avoir les auth de vendeur
    //va attendre la fin d'execution pour avoir les données de vendeur internes
    //affiche les loaders de la partie Recap contrats pro
    await this.refreshVendeurACDetDC()

    const crmUserId = await this.getUserIdCRM(
      this.user.email
      // 'clement@gaz-elec-moinscher.fr'
    )

    if (crmUserId != null) {
      this.user.crmUserId = crmUserId
      //va attendre la fin d'execution de la fonction pour avoir les won leads de CRM
      //affiche les loaders sur la partie recapitulatif CRM / Dernieres ventes , Rapport closing
      await this.refreshUserWonLeadsCRM(crmUserId)
      //va attendre la fin d'execution de la fonction pour avoir les recent leads de CRM
      //affiche les loaders sur la partie recapitulatif CRM / Derniers contacts
      await this.refreshUserRecentLeadsCRM(crmUserId)
    }
  },

  watch: {
    demandesVendeur: function () {
      this.formatUserDemandsData()
    },
  },
  computed: {
    
    numberOfPages () {
      return Math.ceil(this.crm.leads.recent.length / this.itemsPerPage)
    },
    //les données line chart
    series: function () {
      return [
        {
          name: 'Amount',
          data: this.crm.salesByMonth.data,
        },
      ]
    },
    //les parametres line chart & étiquetes de axe x
    options: function () {
      return {
        fill: { colors: '#d21989' },
        chart: {
          height: 350,
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
          offsetX: 10,
          offsetY: -10,
          formatter: function (value) {
            const euro = new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 0,
            })
            return euro.format(value)
          },

          style: {
            fontSize: '0.8rem',
            fontFamily: 'Roboto,  sans-serif',
            colors: ['#90a4ae'],
            fontWeight: 400,
          },
          background: {
            enabled: false,
          },
        },
        stroke: {
          curve: 'straight',
          colors: '#53ABFF',
          width: 3,
        },

        grid: {
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: this.crm.salesByMonth.labels,
          labels: {
            style: {
              colors: '#90a4ae',
              fontSize: '0.9rem',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          axisBorder: {
            show: true,
            color: '#90a4ae',
            height: 1,
            width: '100%',
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#90a4ae',
            height: 6,
            offsetX: 0,
            offsetY: 0,
          },
        },

        yaxis: {
          min: 0,
          forceNiceScale: true,
          labels: {
            offsetX: -10,
            formatter: function (value) {
              const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
              })
              return euro.format(value)
            },
            style: {
              colors: '#90a4ae',
              fontSize: '0.9rem',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: 400,
            },
          },
        },

        markers: {
          size: 3,
          colors: '#53ABFF',
          strokeColors: '#1976d2',
        },
      }
    },
  },

  async created() {
    var whoIam = ""
    await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
    if(whoIam == "noToken"){
        localStorage.removeItem('vendeurName')
        localStorage.removeItem('typeUser') 
        localStorage.removeItem('token')
        this.$router.push('/login')
    }else{
      localStorage.setItem('typeUser', whoIam)
    }
  },
  methods: {
    
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    },
    moment: function (date) {
      return moment(date)
    },
    async getUserIdCRM(email) {
            var crmUserId
            var formData = new FormData()
            formData.append('email', localStorage.getItem('email'))
            await axios({
                url:
                process.env.VUE_APP_URL_API_CLIENT + 'getNocrmUser/',
                method: 'POST',
                data: formData,
                
            })
            .then((res) => {
              if(res.data[0].Erreur == false){
                crmUserId = res.data[0].id
              }else{
                crmUserId = null
              }
            }).catch((err) => {
              console.log('email not on no crm')
            })
        //const { id: crmUserId } = responseParsed[0]
        //sets user
        //return crmUserId
        return crmUserId
    },

    async refreshUserRecentLeadsCRM(crmUserId) {
      this.loaders.loadingRecentLeads = true

      const recentLeads = await this.getUserRecentLeadsCRM(
        crmUserId,
        '2022-01-01T00:00:00.000Z'
      )

      this.crm.leads.recent = recentLeads

      let {
        totalContactRecent,
        leadsContactSoon,
        leadsContactLate,
        leadsContactToday,
      } = await this.agregateRecentLeadsData(recentLeads)

      this.crm.totalContactRecent = totalContactRecent
      this.crm.leadsContactSoon = leadsContactSoon
      this.crm.leadsContactLate = leadsContactLate
      this.crm.leadsContactToday = leadsContactToday

      this.loaders.loadingRecentLeads = false
    },

    async refreshUserWonLeadsCRM(crmUserId) {
      this.loaders.loadingWonLeads = true

      const wonLeads = await this.getUserWonLeadsCRM(
        crmUserId,
        '2022-01-01T00:00:00.000Z',
        'won'
      )
      this.crm.leads.won = wonLeads

      let { nbTotalWonLeads, totalWonLeads, labelsWon, dataWon } =
        await this.agregateWonLeadsData(wonLeads)

      this.crm.salesByMonth.labels = labelsWon
      this.crm.salesByMonth.data = dataWon
      this.crm.salesTotal = totalWonLeads
      this.crm.nbSalesTotal = nbTotalWonLeads

      this.loaders.loadingWonLeads = false
    },

    async getUserWonLeadsCRM(userId, startDate, typeLeads) {
      var result = null
      var formData = new FormData()
      formData.append('userId', userId)
      formData.append('dateDebut', startDate)
      await axios({
          url:
          process.env.VUE_APP_URL_API_CLIENT + 'getNocrmWonLeads/',
          method: 'POST',
          data: formData,
          
      })
      .then((res) => {
          result =res.data
        //return response.data

      }).catch((err)=>{
        console.log('error')
      })
      return result
    },

    async getUserRecentLeadsCRM(userId, startDate) {
      var result = null
      var formData = new FormData()
      formData.append('userId', userId)
      formData.append('dateDebut', startDate)
      await axios({
          url:
          process.env.VUE_APP_URL_API_CLIENT + 'getNocrmRecentLeads/',
          method: 'POST',
          data: formData,
          
      })
      .then((res) => {
          result =res.data
        //return response.data

      }).catch((err)=>{
        console.log('error')
      })
      return result
    },

    ///verifie si l'utilisateur est authentifié et autorisé
    //recupere email d'utilisateur dans localStorage
    
    async refreshVendeurACDetDC() {
      this.loaders.loadingVendeurInfo = true

      const demandesVendeur = await this.getVendeurACDetDC()
      //les données sont stockées dans demandesVendeur
      this.demandesVendeur = demandesVendeur

      this.loaders.loadingVendeurInfo = false
    },

    ///recupere les demandes de cotations et les authorisations de collecte des données faites par utilisateur
    async getVendeurACDetDC() {
      try {
        //récupére le mail d'utilisateur dans localstorage pour l'identifier
        var formData = new FormData()
        formData.append('token', this.getToken('token'))
        //requête POST vers getAcdParCompte.php avec email d'utilisateur comme paramètre
        const res = await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + 'getAcdParCompte/',
          method: 'POST',
          data: formData,
        })
        return res.data
      } catch (err) {
        console.log(err)
      }
    },

    formatUserDemandsData() {
      var stats = this.statistiquesDemandes

      for (var demande of this.demandesVendeur) {
        //autorsation de collecte ou demande de cotaion
        var typeDemande = demande.type
        //elec ou gaz
        var typeContrat = demande.typeDemande

        if (typeContrat) {
          typeContrat = typeContrat.toLowerCase()
        }

        // si autorisation de collecte des données
        if (typeDemande === 'acd') {
          //si electricité
          if (typeContrat === 'elec') {
            stats['acd']['elec'] = ++stats['acd']['elec'] || 1
            //si gaz
          } else if (typeContrat === 'gaz') {
            stats['acd']['gaz'] = ++stats['acd']['gaz'] || 1
          } //si dual
          else if (typeContrat === 'dual') {
            stats['dc']['gaz'] = ++stats['dc']['gaz'] || 1
            stats['dc']['elec'] = ++stats['dc']['elec'] || 1
          }
        }

        // si demande de cotation
        if (typeDemande === 'demandeCotation') {
          //si electricité
          if (typeContrat === 'elec') {
            stats['dc']['elec'] = ++stats['dc']['elec'] || 1
          }
          //si gaz
          else if (typeContrat === 'gaz') {
            stats['dc']['gaz'] = ++stats['dc']['gaz'] || 1
          }
        }

        this.statistiquesDemandes = stats
      }
    },

    async agregateWonLeadsData(leads) {
      const recapVentes = [
        { label: 'janv', amount: null },
        { label: 'févr', amount: null },
        { label: 'mars', amount: null },
        { label: 'avr', amount: null },
        { label: 'mai', amount: null },
        { label: 'juin', amount: null },
        { label: 'juil', amount: null },
        { label: 'août', amount: null },
        { label: 'sept', amount: null },
        { label: 'oct', amount: null },
        { label: 'nov', amount: null },
        { label: 'déc', amount: null },
      ]

      leads.map((lead) => {
        //récupere l'index du mois de date de clôture du contrat(lead)
        //Janvier = 0 pour .getMonth()
        let monthIndex = new Date(lead.closed_at).getMonth()

        // increment le montant pour le mois
        recapVentes[monthIndex].amount = recapVentes[monthIndex].amount
          ? recapVentes[monthIndex].amount + lead.amount
          : lead.amount
      })
      const monthList = []
      const amountList = []
      let total = 0
      let nbTotal = 0

      recapVentes.map((month) => {
        total += month.amount
        monthList.push(month.label)
        amountList.push(month.amount)
      })

      nbTotal = leads.length

      return {
        nbTotalWonLeads: nbTotal,
        totalWonLeads: total,
        labelsWon: monthList,
        dataWon: amountList,
      }
    },

    agregateRecentLeadsData(leads) {
      let total = 0
      let contactSoon = 0
      let contactToday = 0
      let contactLate = 0

      total = leads.length

      leads.map((lead) => {
        if (this.moment().isAfter(lead.remind_date, 'day')) contactLate++
        if (this.moment().isBefore(lead.remind_date, 'day')) contactSoon++
        if (this.moment().isSame(lead.remind_date, 'day')) contactToday++
      })

      return {
        totalContactRecent: total,
        leadsContactSoon: contactSoon,
        leadsContactToday: contactToday,
        leadsContactLate: contactLate,
      }
    },
  },
}
</script>

<style>
div.v-avatar.bordered {
  border: 2px solid;
}

span.l-stick {
  width: 2px;

  height: 40px;
  margin-left: -20px;
  margin-right: 18px;
  position: absolute;
}

.cool div.v-avatar {
  border-color: #1976d2;
}

.cool span.l-stick {
  background: #1976d2;
}

.cool i.v-icon {
  color: #1976d2;
}

.win span.l-stick {
  background: #4caf50;
}

.win div.v-avatar {
  border-color: #4caf50;
}

.win i.v-icon {
  color: #4caf50;
}

.objectif span.l-stick {
  background: #fb8c00;
}

.objectif div.v-avatar {
  border-color: #fb8c00;
}

.objectif i.v-icon {
  color: #fb8c00;
}

.alert span.l-stick {
  background: #ff5252;
}

.alert div.v-avatar {
  border-color: #ff5252;
}

.alert i.v-icon {
  color: #ff5252;
}

div.v-card.table {
  height: 100%;
  width: 100%;
}
div.v-card.table {
  height: 100%;
  width: 100%;
}
div.v-card.page {
  padding-bottom: 10vh !important;
}

div.v-data-footer {
  justify-content: end;
  margin-top: 20px !important;
}

div.header {
  background-color: #f5f5f5;
}
</style>
