<template>
  <div @dragover="dragover" @dragleave="dragleave" @drop="drop">
   <v-alert v-if="maxSizeFile" type="warning" icon="mdi-alert-outline">
          Veuillez supprimer un ou plusieurs fichier(s) pour rajouter d'autres fichiers
        </v-alert>
    <input
      type="file"
      style="display: none"
      name="fields"
      id="assetsFieldHandle"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      @change="onChange()"
      ref="file"
    />
    <h4>{{ name }}</h4>
    <label for="assetsFieldHandle" class="block cursor-pointer">
      <div :class="fileRequiredData ? 'requireddropzone' : 'dropzone'">
        <v-icon x-large>cloud_upload</v-icon>
        <p>
          Déposer votre/vos fichier(s) ici <span><br />(ou)</span>
        </p>
        <p style="background-color: #e2e2e2">Séléctionnez un fichier à importer</p>
        <p>Types de fichier supportés : pdf, eml, msg, png, jpg, jpeg</p>
      </div>
    </label>
  </div>
</template>
<script>
export default {
  name: "uploadFile",
  props: ["name","fileProps",'maxNumberFileProps','fileRequired'],
  data() {
    return {
      file: [],
      maxSizeFile: false,
      fileRequiredData:this.fileRequired,
    };
  },
     watch :{

         fileRequired:function(newVal,oldVal){
          this.fileRequiredData = this.fileRequired
        },

        fileProps:function(newVal,oldVal){
          this.file = this.fileProps
        },
        maxNumberFileProps:function(newValue){
          this.maxSizeFile = this.maxNumberFileProps
        }
    },
  methods: {
    dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('bg-green-300')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-green-300');
            }
        },
        dragleave(event) {
            // Clean up
            event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-green-300');
        },
        drop(event) {
            event.preventDefault();
            if(event.dataTransfer.files.length>1){
                this.$refs.file.files=null;
            }else{
                
                    this.$refs.file.files = event.dataTransfer.files;
                    this.onChange(); // Trigger the onChange event manually
                    // Clean up
                    event.currentTarget.classList.add('bg-gray-100');
                    event.currentTarget.classList.remove('bg-green-300');
                
            }
        },

    onChange() {
     if(this.file.length < 3){
      this.file.push(this.$refs.file.files[0])
      this.$emit("FileData",this.file)
     }else{
       this.maxSizeFile = true
       this.$emit('maxNumberFile',this.maxSizeFile)
     }
    },
  },
};
</script>
<style scoped lang="scss">
.dropzone {
  border: 2px dashed #90a4ae;
  border-radius: 8px;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
    &:first-of-type {
      font-weight: 500;
      font-size: 1rem;
      color: #263238;
      span {
        color: gray;
      }
    }
    &:last-of-type {
      font-size: 0.8rem;
    }
  }
}
.requireddropzone {
  border: 2px dashed red;
  border-radius: 8px;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
    &:first-of-type {
      font-weight: 500;
      font-size: 1rem;
      color: #263238;
      span {
        color: gray;
      }
    }
    &:last-of-type {
      font-size: 0.8rem;
    }
  }
}
/* The Modal (background) */
.modall {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modall-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}
.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
