<template>
    <div style="background-color: #eee;">
        <LoadingComponent @closeDialog="dialog = $event" :messageDialogeProps="messageDialoge" :dialogProps="dialog" :loadingProps="loading" :errorProps="errorModal" />

      <section style="background-color: #eee;">
        <div class="container-fluid py-5" style="height: 100%;">
          <div class="card mb-3">
            <div class="card-header text-center">
              <h5>Courbe de charge</h5>
              
            </div>
            <div class="card-body">
                <VueApexCharts
                  type="line"
                  ref="chartRefHoraire"
                  :options="chartOptions"
                  :series="seriesGlob"
                  :height="350"
                  v-show="typeCdcCourbe == 'Horaire'"
                />
                <VueApexCharts
                  type="line"
                  ref="chartRefJournalier"
                  :options="chartOptions"
                  :series="seriesGlob"
                  :height="350"
                  v-show="typeCdcCourbe == 'Journalier'"
                />
                <VueApexCharts
                  type="bar"
                  ref="chartRefBar"
                  :options="chartOptionsBar"
                  :series="seriesGlob"
                  :height="350"
                  v-show="typeCdcCourbe == 'Mensuel'"
                />
                <div class="card-header text-center d-flex justify-center">
                  <v-radio-group dense row v-model="typeCdcCourbe" @change="renderChart">
                    <v-radio label="Horaire" value="Horaire"></v-radio>
                    <!-- <v-radio label="Journalier" value="Journalier"></v-radio> -->
                    <v-radio label="Mensuel" value="Mensuel"></v-radio>
                  </v-radio-group>
                </div>
              </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
<script>

import { DateTime } from "luxon";
import dayjs from "dayjs";
import VueApexCharts from "vue-apexcharts";
import axios from "axios";
import LoadingComponent from "../../../components/ui/loading-component.vue";


export default {
  components: {
    VueApexCharts,
    LoadingComponent,
  },
  name: "DetailCompteur",
  props: ["id"],
  data() {
    return {
      //loader data
      responseStatus: 0,
      dialog: false,
      loading: false,
      messageDialoge: "",
      errorModal: false,
      status: null,
      //
      courbeLoad: false,
      selected: null,
      compteurDetail: {},
      pageNumber: 10,
      loaded: false,
      donnerMesure: [],
      donnerCDC: {},
      mesure: {},
      typeCdcCourbe: "Horaire",
      dataCdcHours: null,
      dataCdcDays: null,
      dataCdcMonths: null,
      maxValue: 0,
      dateMaxValue: null,
      showMensuel: true,
      showJournalier: true,
      showHoraire: true,
      samplingRate: 30,
      seriesGlob: [],
      chartOptions: {
        noData: {
          text: "Loading data...", // The custom "No Data" message or loader
          align: "center", // Position of the message ('left', 'center', or 'right')
          verticalAlign: "middle", // Vertical position of the message ('top', 'middle', or 'bottom')
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000",
            fontSize: "14px",
            fontFamily: "Arial",
          },
        },
        chart: {
          id: "line-chart",
          animations: {
            enabled: false, // Disable animations for better performance with large data
          },
          toolbar: {
            show: true, // Show the chart toolbar
            tools: {
              // Customize the tools in the toolbar
              download: true, // Remove download button
              selection: true, // Keep the selection button
              zoom: true, // Remove zoom button
              zoomin: true, // Keep the zoomin button
              zoomout: true, // Keep the zoomout button
              pan: false, // Keep the pan button
              reset: false, // Remove reset zoom button
            },
          },
        },
        xaxis: {
          type: "datetime",
        },
        dataLabels: {
          enabled: false,
        },
        annotations: {
          yaxis: [
            {
              y: 0, // Replace '100' with your desired max value
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
                text: "Max Value", // Replace with your desired label
              },
            },
          ],
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        legend: {
          height: 50,
          horizontalAlign: "center",
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              " - " +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              " Kva"
            );
          },
        },
        tooltip: {
          shared: false,
          x: {
            format: "dd MMM yyyy HH:mm", // Format the date-time display in the tooltip
          },
          y: {
            formatter: function (val) {
              return "";
            },
            title: {
              formatter: function (val, opts) {
                return (
                  val +
                  " " +
                  opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                  " Kva"
                );
              },
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
      chartSeriesJournalier: [],
      chartSeriesHoraire: [],
      chartOptionsBar: {
        chart: {
          type: "bar",
          stacked: false,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime", // Use datetime type for x-axis
          labels: {
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM yyyy",
              day: "dd MMM",
            },
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return "";
            },
            title: {
              formatter: function (val, opts) {
                return (
                  Number(
                    opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]
                  ).toLocaleString("fr-FR") + " Kva"
                );
              },
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            barWidth: "10", // Set to true for horizontal bars, false for vertical bars
            dataLabels: {
              background: "transparent", // Set to 'transparent' to remove the background texture on the bars
            },
          },
        },
      },
      chartSeriesBar: [],
    };
  },
  created() {
    this.getCourbeDataBySocieteAndPdl();
  },
  mounted() {
    this.loaded = true;
  },
  watch: {},
  methods: {
    formatDateTime(date) {
      return dayjs(date).format("DD/MM/YYYY-HH:mm");
    },
    closeLoading(event) {
      this.dialog = event;
      this.errorModal = event;
      if (this.responseStatus === 401) {
        this.$store.dispatch("logout");
      }
    },
    async renderChart() {
      if (this.typeCdcCourbe == "Horaire") {
        this.maxValue = this.donnerCDC.maxValueHours;
        this.dateMaxValue = this.donnerCDC.dateMaxHours;
        //this.$refs.chartRefHoraire.updateOptions();
        this.showHoraire = true;
        this.showJournalier = false;
        this.showMensuel = false;
        this.seriesGlob = this.chartSeriesHoraire;
        console.log(this.chartSeriesHoraire);
        //this.$refs.chartRefHoraire.updateOptions();
      } else if (this.typeCdcCourbe == "Journalier") {
        this.maxValue = this.donnerCDC.maxValuedays;
        this.dateMaxValue = this.donnerCDC.dateMaxdays;
        //this.$refs.chartRefJournalier.updateOptions();
        console.log(this.chartSeriesJournalier);
        this.showHoraire = false;
        this.showJournalier = true;
        this.showMensuel = false;
        this.seriesGlob = this.chartSeriesJournalier;
        //this.$refs.chartRefJournalier.updateOptions();
        console.log("1");
      } else if (this.typeCdcCourbe == "Mensuel") {
        this.maxValue = this.donnerCDC.maxValueMonths;
        this.dateMaxValue = this.donnerCDC.dateMaxMonths;
        //this.$refs.chartRefBar.updateOptions();
        console.log(this.chartSeriesBar);
        this.seriesGlob = this.chartSeriesBar;
        this.showHoraire = false;
        this.showJournalier = false;
        this.showMensuel = true;
        //this.$refs.chartRefBar.updateOptions();
      }
    },
    async initGraphs() {
      this.chartSeriesHoraire = [
        {
          name: "Value",
          data: this.dataCdcHours.map((item) => ({
            x: new Date(item.date).getTime(),
            y: item.value,
          })),
        },
      ];
      this.chartSeriesJournalier = [
        {
          name: "Value",
          data: this.dataCdcDays.map((item) => ({
            x: new Date(item.date).getTime(),
            y: item.value,
          })),
        },
      ];
      this.chartSeriesBar = [
        {
          name: "Values",
          data: this.dataCdcMonths.map((item) => ({
            x: DateTime.fromISO(item.date).toFormat("yyyy-MM"),
            y: item.value,
          })),
        },
      ];
      var maxValueHoraire = this.donnerCDC.maxValueHours;
      var dateMaxValueHoraire = this.donnerCDC.dateMaxHours;
      var maxValueJournalier = this.donnerCDC.maxValuedays;
      var dateMaxValueJournalier = this.donnerCDC.dateMaxdays;
      this.$refs.chartRefHoraire.updateOptions({
        annotations: {
          yaxis: [
            {
              y: maxValueHoraire,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
                text:
                  "Max: " +
                  Number(maxValueHoraire).toLocaleString("fr-FR") +
                  " Kva; Le : " +
                  this.$dayjs(dateMaxValueHoraire).format("DD/MM/YYYY - HH:mm"),
              },
            },
          ],
        },
      });
      this.$refs.chartRefJournalier.updateOptions({
        annotations: {
          yaxis: [
            {
              y: maxValueJournalier,
              borderColor: "#00E396",
              label: {
                borderColor: "#00E396",
                style: {
                  color: "#fff",
                  background: "#00E396",
                },
                text:
                  "Max: " +
                  Number(maxValueJournalier).toLocaleString("fr-FR") +
                  " Kva; Le : " +
                  this.$dayjs(dateMaxValueJournalier).format("DD/MM/YYYY - HH:mm"),
              },
            },
          ],
        },
      });
      this.maxValue = this.donnerCDC.maxValueHours;
      this.seriesGlob = this.chartSeriesHoraire;
    },
    async getCourbeDataBySocieteAndPdl() {
        try {
            this.dialog = true;
      this.loading = true;
      this.messageDialoge = "";
      this.errorModal = false;
          const response = await axios.post(process.env.VUE_APP_URL_API_CLIENT + 'getCourbeDataBySocieteAndPdl/', {
            token: this.getToken('token'),
            numCompteur: this.$route.query.numCompteur,
            societeId: this.$route.query.societeId
          });
  
          if (response.data.result === true) {
            this.dataCdcHours = JSON.parse(response.data.DataHours);
            this.dataCdcDays = JSON.parse(response.data.resultDays);
            this.dataCdcMonths = JSON.parse(response.data.resultDays) || [];
            this.courbeLoad = true;
            await this.initGraphs();
              this.showHoraire = true;
              this.showJournalier = false;
              this.showMensuel = false;
              this.courbeLoad = true;
              this.loading = false;
              this.dialog = false;
          } else {
            console.error("Error fetching data:", response.data);
          }
        } catch (error) {
          console.error("API call error:", error);
        }
      },
  
    checkEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  background-color: #ffffff !important;
}
.containe_comp {
  background-color: #f2f2f2 !important;
  border-radius: 10px;
  padding: 50px 70px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 500;
  min-height: 300;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
}

.card-header {
  margin-bottom: 0;
  background-color: #ffffff;
  color: #103a5e;
  font-weight: 600;
}
.table-responsive {
  display: block;
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table .thead-light th {
  color: #109d9c;
  border: 0;
  font-size: 0.775rem;
  font-weight: 600;
}
.green-text {
  color: #109d9c;
  font-weight: 600;
}
.thead-light {
  border-radius: 50px;
  background-color: #f2f2f2;
  padding: 10px 20px;
}
.table-flush td,
.table-flush th {
  padding: 1.25rem 1.5rem;
  margin: 10;
}
.green_title {
  color: #007991;
  font-weight: 600;
}

.table-flush tbody tr:first-child td,
.table-flush tbody tr:first-child th {
  border-top: 0;
}

.table-flush tbody tr:last-child td,
.table-flush tbody tr:last-child th {
  border-bottom: 0;
}
.table thead th {
  vertical-align: center;
  border-bottom: 1px solid #8f8f8f;
}
.table th {
  font-weight: 600;
}

.table td .progress {
  width: 120px;
  height: 7px;
  margin: 0;
}

.table.align-items-center td,
.table.align-items-center th {
  vertical-align: middle;
}
.table td {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  font-size: 0.8125rem;
}
.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}
.code_c {
  color: #3a4858;
  font-size: 0.8rem;
  font-weight: 600;
}
.max-day {
  color: #6bdd8d;
  font-size: 0.8rem;
  font-weight: 600;
}

.chart {
  padding: 0;
  border-radius: 10px;
}

.chart-container {
  height: 250px; /* Set the desired height as a percentage */
}

::v-deep .v-input--selection-controls .v-radio > .v-label {
  margin: 0px !important;
}
</style>
