<style scoped>
.width-text-field {
    width: 30%
}

.width-button-responsive {
    width: 25%
}

@media only screen and (max-width:500px) {
    .width-text-field {
        width: 90%
    }

    .width-button-responsive {
        width: 75%,
    }
}
</style>
<template>
    <div class="ma-3">
        <!-- la form pricipale -->
        <v-card elevation="5">
            <v-toolbar :color="$colors[0]" dark>
                <span class="Poppins-Bold pl-0">Autorisation de Collecte de Données</span>
            </v-toolbar>
            <v-row>
                <div>
                    <loadingComponent :dialogProps="dialog" :messageDialogeProps="messageDialoge"
                        :loadingProps='loading' :errorProps="error" @closeDialog="dialog = $event" />
                </div>
            </v-row>
            <div style='d-flex' class="ma-0">
                <div class="d-flex  flex-row mt-8">
                    <v-form class="width-text-field d-flex flex-row  ma-8 mb-0" ref="formSiret" lazy-validation>
                        <v-text-field outlined dense :rules="[rules.required, rules.isNumber, rules.siretLength]"
                            v-model="siret" @keydown.enter="rechercheParSiret" label="Siret/Siren"></v-text-field>
                        <v-btn style="height:60%" @click="rechercheParSiret" depressed elevation="1" :color='$colors[0]'
                            class=" text-white ml-2">
                            <v-icon size="30">mdi-clipboard-text-search-outline</v-icon>
                        </v-btn>
                    </v-form>
                </div>
                <v-col class="pa-0" cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-divider class="ma-4" v-if="siretInccorect == false && inseeResponse == true"></v-divider>
                </v-col>
                <v-col class="pa-0" cols="12" sm="10" md="10" lg="10" xl="12"
                    v-if="siretInccorect == false && inseeResponse == true">
                    <span class="Poppins-Bold ml-4">Informations Entreprise :</span>
                    <v-form class="ma-4 pt-2" ref="formUpload" lazy-validation>
                        <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="align-self-center text-center"
                                v-if="regie.indexOf(typeContrat) != -1">
                                <v-radio-group row v-model="traitemantEnedisRegie">
                                    <v-radio label="C'est un régie" value="oui" />
                                    <v-radio label="Non" value="non" />
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined dense :rules="[rules.required]" v-model="raison"
                                    label="Raison sociale"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined dense
                                    :rules="[rules.required, rules.isString]" v-model="nom"
                                    label="Nom"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined dense
                                    :rules="[rules.required, rules.isString]" v-model="prenom"
                                    label="Prénom"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
                                <v-text-field outlined dense
                                    :rules="[rules.required, rules.isString]" v-model="fonction"
                                    label="Poste"></v-text-field>
                            </v-col>
                            <v-col class="pa-0" cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-divider class="ma-4"
                                    v-if="siretInccorect == false && inseeResponse == true"></v-divider>
                            </v-col>
                            <div class="d-flex flex-column">
                                <span class="Poppins-Bold ml-4">Informations Energie :</span>
                                <v-radio-group class="ml-4" v-model="typeContrat" row>
                                    <v-radio label="GAZ" value="GAZ" :color="$colors[2]" @click="choisirGaz"></v-radio>
                                    <v-radio label="ELECTRICITÉ" value="ELEC" :color="$colors[2]"
                                        @click="choisirElec"></v-radio>
                                    <v-radio label="ELECTRICITÉ & GAZ" value="ELEC/GAZ" :color="$colors[2]"></v-radio>
                                </v-radio-group>

                            </div>
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <v-row>
                                    <!-- compteur gaz avec test sur le format non regie -->
                                    <v-col cols="10" sm="6" md="6" lg="6" xl="4"
                                        v-if="(typeContrat === 'GAZ' || typeContrat === 'ELEC/GAZ') && traitemantEnedisRegie == 'non'">

                                        <v-text-field outlined dense :rules="[rules.required, rules.isGazCompteur]"
                                            v-model="num_comp_gaz[0]" label="Numéro de compteur GAZ n°1">
                                        </v-text-field>
                                        <v-text-field outlined dense :rules="[rules.required, rules.isGazCompteur]"
                                            v-for="index in lengthCompteurgaz" :key="index"
                                            v-model="num_comp_gaz[index]"
                                            :label="'Numéro de compteur GAZ n°' + (index + 1)">
                                        </v-text-field>
                                        <v-btn depressed elevation="1" class="responsiveBtn ma-auto text-white"
                                            width="45%" :color='$colors[0]' @click="ajouterCompteurGaz">
                                            Ajouter
                                        </v-btn>
                                        <v-btn v-if="lengthCompteurgaz > 0" depressed elevation="1"
                                            class="error responsiveBtn ma-auto text-capitalize ml-2" width="45%"
                                            @click="supprimerCompteurGaz">
                                            Supprimer
                                        </v-btn>
                                    </v-col>
                                    <!-- compteur gaz sans test sur le format regie -->
                                    <v-col cols="10" sm="6" md="6" lg="6" xl="4"
                                        v-if="(typeContrat === 'GAZ' || typeContrat === 'ELEC/GAZ') && traitemantEnedisRegie == 'oui'">

                                        <v-text-field outlined dense :rules="[rules.required]" v-model="num_comp_gaz[0]"
                                            label="Numéro de compteur gaz n°1">
                                        </v-text-field>
                                        <v-text-field outlined dense :rules="[rules.required]"
                                            v-for="index in lengthCompteurgaz" :key="index"
                                            v-model="num_comp_gaz[index]"
                                            :label="'Numéro de compteur gaz n°' + (index + 1)">
                                        </v-text-field>
                                        <v-btn depressed elevation="1" class="responsiveBtn ma-auto text-white"
                                            width="45%" :color='$colors[0]' @click="ajouterCompteurGaz">
                                            Ajouter
                                        </v-btn>
                                        <v-btn v-if="lengthCompteurgaz > 0" depressed elevation="1"
                                            class="error responsiveBtn ma-auto text-capitalize ml-2" width="45%"
                                            @click="supprimerCompteurGaz">
                                            Supprimer
                                        </v-btn>
                                    </v-col>
                                    <!-- compteur elec avec test sur le format non regie -->
                                    <v-col cols="10" sm="6" md="6" lg="6" xl="4"
                                        v-if="(typeContrat === 'ELEC' || typeContrat === 'ELEC/GAZ') && traitemantEnedisRegie == 'non'">
                                        <v-text-field outlined dense
                                            :rules="[rules.required, rules.compteurElecLength, rules.isNumber]"
                                            v-model="num_comp_elec[0]"
                                            label="Numéro de compteur ELEC n°1"></v-text-field>
                                        <v-text-field outlined dense
                                            :rules="[rules.required, rules.compteurElecLength, rules.isNumber]"
                                            v-for="index in lengthCompteurelec" :key="index"
                                            v-model="num_comp_elec[index]"
                                            :label="'Numéro de compteur ELEC n°' + (index + 1)"></v-text-field>
                                        <v-btn depressed elevation="1" :color='$colors[0]' class="ma-auto text-white"
                                            width="45%" @click="ajouterCompteurElec">
                                            Ajouter
                                        </v-btn>
                                        <v-btn v-if="lengthCompteurelec > 0" depressed elevation="1"
                                            class="ml-2 error ma-auto text-capitalize" width="45%"
                                            @click="supprimerCompteurElec">
                                            Supprimer
                                        </v-btn>
                                    </v-col>
                                    <!-- compteur elec sans test sur le format regie -->
                                    <v-col cols="10" sm="6" md="6" lg="6" xl="4"
                                        v-if="(typeContrat === 'ELEC' || typeContrat === 'ELEC/GAZ') && traitemantEnedisRegie == 'oui'">
                                        <v-text-field outlined :rules="[rules.required]" v-model="num_comp_elec[0]"
                                            label="Numéro de compteur ELEC n°1"></v-text-field>
                                        <v-text-field outlined :rules="[rules.required]"
                                            v-for="index in lengthCompteurelec" :key="index"
                                            v-model="num_comp_elec[index]"
                                            :label="'Numéro de compteur ELEC n°' + (index + 1)"></v-text-field>
                                        <v-btn depressed elevation="1" large :color='$colors[0]'
                                            class="ma-auto text-white" width="45%" @click="ajouterCompteurElec">
                                            Ajouter
                                        </v-btn>
                                        <v-btn v-if="lengthCompteurelec > 0" depressed elevation="1" large
                                            class="ml-2 error ma-auto text-capitalize" width="45%"
                                            @click="supprimerCompteurElec">
                                            Supprimer
                                        </v-btn>
                                    </v-col>

                                    <!--<v-btn
                                    v-if="hasGeneratePDF && isSend == 1"
                                    @click="enregistrer"
                                    depressed
                                    elevation="1"
                                    large
                                    :color='$colors[0]'
                                    class="px-6 text-white align-self-end"
                                    >
                                    Enregistrer 
                                </v-btn>-->
                                </v-row>
                            </v-col>
                        </v-row>

                    </v-form>
                    <div class="flex-col align-items-center justify-start pa-4">
                        <v-checkbox v-if="typeUser == 'admin' || typeUser == 'ADV'" label="Power Conseils"
                            v-model="isPowerConseil" value="Power Conseils"></v-checkbox>
                        <v-checkbox v-if="typeUser == 'admin' || typeUser == 'ADV'" label="Smart Electricité"
                            v-model="isSmartElectricite" value="Smart Electricité"></v-checkbox>

                        <v-btn v-if="siretInccorect == false && inseeResponse == true" style="width-button-responsive"
                            @click="generatePdf" depressed elevation="1" :color='$colors[0]' class="text-white">
                            Examiner le document
                        </v-btn>
                    </div>
                </v-col>
            </div>
        </v-card>

        <!-- entete du pdf -->
        <div style="position:relative; left:-400%; width:600px;">
            <p id="para" style="margin-left:50px; margin-top:105px;font-size:10px; max-width: 500px;">
                Je soussigné {{ nom }} {{ prenom }} autorise gaz-elec-moinscher.fr a
                acceder aux données mentionnées ci-dessous, pour la société
                {{ raison }} &nbsp;au siren {{ siret }} et
                pour le/les point(s) de mesure suivant:
            </p>
        </div>
    </div>
    </div>
</template>

<script>
import loadingComponent from "../components/ui/loading-component.vue"
import axios from 'axios'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import dialogeModal from '../components/ui/dialogeModal.vue'

import { degrees, PDFDocument, rgb, StandardFonts, layoutMultilineText } from 'pdf-lib'
import download from 'downloadjs'
import 'jspdf-autotable'

export default {
    name: 'creationDeACD',
    components: {
        dialogeModal,
        loadingComponent
    },
    data() {
        return {
            isPowerConseil: "",
            isSmartElectricite: "",
            hasGeneratePDF: false,
            search: "",
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "L'e-mail a été envoyé avec succès",
            //enedis data
            enedisRecherchePointResult: null,
            missedCompteur: null,
            //radio button data for add compteur
            num_comp_gaz: [],
            lengthCompteurgaz: 0,
            lengthCompteurelec: 0,
            num_comp_elec: [],
            typeContrat: "GAZ",
            //data for test exist siret and siren
            siretFound: null,
            siretExist: [],
            //insee response data
            inseeResponse: null,
            //data for pdf 
            siret: "",
            raison: "",
            nom: "",
            prenom: "",
            fonction: "",
            regie: false,
            traitemantEnedisRegie: 'non',
            //form data for upload file
            formData: null,
            //l'etat deximination de fichier
            examine_file: true,
            isSend: 0,
            //les rules du formulaire
            rules: {
                required: value => !!value || 'Ce champ est obligatoire.',
                isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
                sirenLength: v => !!v == 0 || v?.length == 9 || 'Le siren doit comporter 9 chiffres',
                siretLength: v => !!v == 0 || v?.length == 14 || v?.length == 9 || 'Ce champ doit contenir soit 9 chiffres, soit 14 chiffres.',
                isNumber: v => v?.match(/^[0-9]+$/) != null || 'Ce champ doit être un nombre',
                compteurElecLength: v => v?.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
                isGazCompteur: v => (v?.match(/^(GI)[0-9]{6}$/) || (v?.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
                phoneLength: v => v?.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
                phoneisNumber: v => v?.match(/^[0-9]+$/) != null || 'Le numéro de téléphone est invalide',
                margeMaxMin: v => (v <= 10 && v >= 2) || 'La marge doit être comprise entre 2 et 10',

                emailRules: v => !!v && /.+@.+\.+.+/.test(v) || 'E-mail invalide',
            },
            verifyLength() {
                return this.siret?.length == 14 || 'Le siret doit comporter 14 chiffres.'
            },
            siretAcceptNumber() {
                return this.siret.match(/^[0-9]+$/) != null || 'Ce champ doit être un nombre'
            },
            siretInccorect: null,
        }
    },
    created() {
        this.typeUser = localStorage.getItem('typeUser')
    },
    methods: {
        // debut dans l'ajoute et suppression d'un compteur dans le formulaire
        ajouterCompteurGaz() {
            this.lengthCompteurgaz += 1
        },
        supprimerCompteurGaz() {
            this.num_comp_gaz.splice(this.lengthCompteurgaz, 1)
            this.lengthCompteurgaz -= 1
        },

        ajouterCompteurElec() {
            this.lengthCompteurelec += 1
        },
        supprimerCompteurElec() {
            this.num_comp_elec.splice(this.lengthCompteurelec, 1)
            this.lengthCompteurelec -= 1
            this.enedisRecherchePointResult = null;
            this.missedCompteur = null;
        },
        // fin dans l'ajoute et suppression d'un compteur dans le formulaire

        //debut test sur le type de choix du compteur GAZ ELEC GAZ&ELEC
        choisirElec() {
            this.lengthCompteurgaz = 0
            this.num_comp_gaz = []
        },
        choisirGaz() {
            this.lengthCompteurelec = 0
            this.num_comp_elec = []
        },
        // cette fonction vérifie si la sirène était correcte et elle renvoie la liste des sirets associés à cette sirène
        getSiretData() {
            this.$refs.formSiren.validate()
            if (this.$refs.formSiren.validate()) {
                let self = this
                this.dialog = true
                this.loading = true
                this.error = false

                self.siretFound = 0
                self.inseeResponse = null
                self.siretExist = []
                var formData = new FormData()
                formData.append('siren', this.siren)
                formData.append('token', this.getToken('token'))
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getListeSiret/',
                    method: 'POST',
                    data: formData,

                })
                    .then((res) => {
                        if (res.data['result'] === 1) {
                            if (res.data['value'] == 0) {
                                self.siretFound = 2
                                self.dialog = true
                                self.error = true
                                self.messageDialoge = "Il y a trop de siret associé a ce siren merci de completer le SIRET manuellement"
                            } else if (res.data['value'] == 1) {
                                self.siretFound = 1
                                var j = 0
                                for (var i = 0; i < res.data['data'].length; i++) {
                                    self.siretExist.push({ name: res.data['data'][i].adresse, disable: true })
                                    self.siretExist.push({ name: res.data['data'][i].siret, disable: false })
                                }

                                self.dialog = false
                                self.loading = false
                                self.error = false
                                //self.siretExist = res.data['data'][0].siret;
                            } else {
                                self.siretFound = 3
                                self.dialog = true
                                self.error = true
                                self.messageDialoge = "Votre siren est incorrect, veuillez vérifier vos données"
                            }
                            self.loading = false
                        } else {
                            self.dialog = false
                            self.loading = false
                            self.error = false
                        }
                    })
                    .catch((err) => {

                        self.dialog = false
                        self.loading = false
                        self.error = false
                        console.log(err)
                    })
            }
        },
        //recherche toutes les données sur une siret choisie
        rechercheParSiret() {
            this.$refs.formSiret.validate()
            if (this.$refs.formSiret.validate()) {
                this.hasGeneratePDF = false;
                let self = this
                this.dialog = true
                this.loading = true
                this.error = false
                var formData = new FormData()
                formData.append('siret', this.siret)
                formData.append('token', this.getToken('token'))
                axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getDonneDeSiretSimple/',
                    method: 'POST',
                    data: formData,

                }).then((res) => {
                    if (res.data.result == true) {
                        self.siretInccorect = false
                        self.raison = res.data.raison
                        self.siret = res.data.siret
                        self.regie = res.data.regie
                        if (res.data.infoPerson == true) {
                            self.nom = res.data.nom
                            self.prenom = res.data.prenom
                            self.fonction = res.data.fonction
                        } else {
                            self.nom = ''
                            self.prenom = ''
                            self.fonction = ""
                        }
                        self.num_comp_gaz = ['']
                        self.num_comp_elec = ['']
                        self.elec = 'GAZ'
                        self.inseeResponse = true
                        self.dialog = false
                        self.loading = false
                        self.error = false
                    } else {
                        this.messageDialoge = "Votre siret est incorrect, veuillez vérifier vos données"
                        this.dialog = true
                        this.loading = false
                        this.error = true
                    }

                }).catch((err) => {
                    this.messageDialoge = "Votre siret est incorrect, veuillez vérifier vos données"
                    this.dialog = true
                    this.loading = false
                    this.error = true
                })
            }

        },
        verifPageEnd(pageHeight, doc, currentPage, currentY) {

            if (currentY < 10) {
                var page = doc.addPage()
                var startingPositon = 750
            } else {
                var page = currentPage
                var startingPositon = currentY
            }
            return { page, startingPositon };
        },
        //la fonction appelle l'une des fonctions précédentes qui génèrent le type de leur pdf sellon
        async generateSmartACDELEC() {

            var raison = this.raison
            var nom = this.nom
            var prenom = this.prenom
            var email = this.email
            var fonction = this.fonction

            var url0 = '/pdf/acd/acdSmart.pdf'
            const pageZero = await fetch(url0).then((res) =>
                res.arrayBuffer()
            )
            // Load a PDFDocument from each of the existing PDFs
            const pageZeroDoc = await PDFDocument.load(pageZero)

            var getPages = pageZeroDoc.getPages()
            var firstPage = getPages[0]
            const { width, height } = firstPage.getSize()
            var pageHeight = height
            var startingPositon = 725
            firstPage.drawText("Je soussigné(e) " + nom + " " + prenom, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            firstPage.drawText("en ma qualité de " + fonction, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
            var lineRaison = "dûment habilité(e) à représenter la société " + raison
            var multiText = layoutMultilineText(lineRaison, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {

                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 25
            firstPage.drawText("immatriculé(e) au RCS de " + this.RCS, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("et enregistré(e) sous le numéro (SIRET) :" + this.siret, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("autorise expressément SMART-ELECTRICITE et  tout autre fournisseur d'énergie ", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            var lines = "à demander et recevoir les données de consommation (et courbe de charge, le cas échéant) liées à" +
                " mon/mes site(s) de consommation raccordé(s) aux réseaux publics de distribution d’électricité et/ou de gaz dans le but de me proposer une offre commerciale."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            startingPositon = startingPositon - 25
            for (let i = 0; i < multiText.lines.length; i++) {
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 30
            firstPage.drawText('N° PDL(s)', {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 20
            for (var i = 0; i < this.num_comp_elec.length; i++) {
                firstPage.drawText('-' + this.num_comp_elec[i], {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 15
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
            }
            startingPositon = startingPositon - 25
            lines = "J’accepte expressément que mes données soient conservées par SMART-ELECTRICITE et tout autre fournisseur d'énergie " +
                "à des fins de gestion et de traçabilité, pour une durée de 12 mois"
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("Cette autorisation de collecte de données est obligatoire et n'engage aucun contrat ni engagement ni aucun frais.", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("le " + DateAujourdhui + " Signature + Tampon : ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })

            startingPositon = startingPositon - 50
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("SMART-ELECTRICITÉ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("RCS Chambéry n° 84783865300016 F.MARCHAND ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("(06.27.79.34.93)", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            /*firstPage.drawText("Cette autorisation reproduit celle du partenaire", {
                x: 100,
                y: startingPositon,
                size: 6,
                color: rgb(0, 0, 0),
            })*/
            //fin contact
            const pdfBytes = await pageZeroDoc.save()
            var file = download(pdfBytes, 'ACD_' + this.raison + '_.pdf', 'application/pdf')
            //create form data

            var formData = new FormData()
            const pdfDataUri = await pageZeroDoc.saveAsBase64({ dataUri: true });
            var pdfFile = await this.urltoFile(pdfDataUri, 'acd.pdf', "application/pdf").then((file) => {
                return file
            })
            formData.append('siret', this.siret)
            formData.append('RCS', this.RCS)
            formData.append('siren', this.siren)
            formData.append('raison', raison)
            formData.append('adresse', this.adressePostale)
            formData.append('numTele', this.num_tel)
            formData.append('nom', nom)
            formData.append('prenom', prenom)
            formData.append('email', email)
            formData.append('type', 'ELEC')
            formData.append('numCompteurGaz', this.num_comp_gaz)
            formData.append('numCompteurElec', this.num_comp_elec)
            formData.append('pdf', pdfFile)
            formData.append('token', this.getToken('token'))
            this.formData = formData
            this.isSend = 1
            this.dialog = false
            this.loading = false
            this.error = false
        },
        async generateSmartACDGAZ() {

            var raison = this.raison
            var nom = this.nom
            var prenom = this.prenom
            var email = this.email
            var fonction = this.fonction

            var url0 = '/pdf/acd/acdSmart.pdf'
            const pageZero = await fetch(url0).then((res) =>
                res.arrayBuffer()
            )
            // Load a PDFDocument from each of the existing PDFs
            const pageZeroDoc = await PDFDocument.load(pageZero)

            var getPages = pageZeroDoc.getPages()
            var firstPage = getPages[0]
            const { width, height } = firstPage.getSize()
            var pageHeight = height
            var startingPositon = 725
            firstPage.drawText("Je soussignée " + nom + " " + prenom, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            firstPage.drawText("en ma qualité de " + fonction, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
            var lineRaison = "dûment habilité(e) à représenter la société " + raison
            var multiText = layoutMultilineText(lineRaison, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {

                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 25
            firstPage.drawText("immatriculé(e) au RCS de " + this.RCS, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("et enregistré(e) sous le numéro (SIRET) :" + this.siret, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("autorise expressément SMART-ELECTRICITE et  tout autre fournisseur d'énergie ", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            var lines = "à demander et recevoir les données de consommation (et courbe de charge, le cas échéant) liées à" +
                " mon/mes site(s) de consommation raccordé(s) aux réseaux publics de distribution d’électricité et/ou de gaz dans le but de me proposer une offre commerciale."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            startingPositon = startingPositon - 25
            for (let i = 0; i < multiText.lines.length; i++) {
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 30
            firstPage.drawText('N° PCE(s)', {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 20
            for (var i = 0; i < this.num_comp_gaz.length; i++) {
                firstPage.drawText('-' + this.num_comp_gaz[i], {
                    x: 100,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
                startingPositon = startingPositon - 15
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
            }
            startingPositon = startingPositon - 25
            lines = "J’accepte expressément que mes données soient conservées par SMART-ELECTRICITE et tout autre fournisseur d'énergie " +
                "à des fins de gestion et de traçabilité, pour une durée de 12 mois"
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("Cette autorisation de collecte de données est obligatoire et n'engage aucun contrat ni engagement ni aucun frais.", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("le " + DateAujourdhui + " Signature + Tampon : ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })

            startingPositon = startingPositon - 50
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("SMART-ELECTRICITÉ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("RCS Chambéry n° 84783865300016 F.MARCHAND ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("(06.27.79.34.93)", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon

            //fin contact
            const pdfBytes = await pageZeroDoc.save()
            download(pdfBytes, 'ACD_' + this.raison + '_.pdf', 'application/pdf')
            //create form data
            var formData = new FormData()
            const pdfDataUri = await pageZeroDoc.saveAsBase64({ dataUri: true });
            var pdfFile = await this.urltoFile(pdfDataUri, 'acd.pdf', "application/pdf").then((file) => {
                return file
            })
            formData.append('siret', this.siret)
            formData.append('RCS', this.RCS)
            formData.append('siren', this.siren)
            formData.append('raison', raison)
            formData.append('adresse', this.adressePostale)
            formData.append('numTele', this.num_tel)
            formData.append('nom', nom)
            formData.append('prenom', prenom)
            formData.append('email', email)
            formData.append('type', 'GAZ')
            formData.append('numCompteurGaz', this.num_comp_gaz)
            formData.append('numCompteurElec', this.num_comp_elec)
            formData.append('pdf', pdfFile)
            formData.append('token', this.getToken('token'))
            this.formData = formData
            this.isSend = 1
            this.dialog = false
            this.loading = false
            this.error = false
        },
        async generateSmartACDDUAL() {

            var raison = this.raison
            var nom = this.nom
            var prenom = this.prenom
            var email = this.email
            var fonction = this.fonction

            var url0 = '/pdf/acd/acdSmart.pdf'
            const pageZero = await fetch(url0).then((res) =>
                res.arrayBuffer()
            )
            // Load a PDFDocument from each of the existing PDFs
            const pageZeroDoc = await PDFDocument.load(pageZero)

            var getPages = pageZeroDoc.getPages()
            var firstPage = getPages[0]
            const { width, height } = firstPage.getSize()
            var pageHeight = height
            var startingPositon = 725
            firstPage.drawText("Je soussignée " + nom + " " + prenom, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            firstPage.drawText("en ma qualité de " + fonction, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
            var lineRaison = "dûment habilité(e) à représenter la société " + raison
            var multiText = layoutMultilineText(lineRaison, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {

                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 25
            firstPage.drawText("immatriculé(e) au RCS de " + this.RCS, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("et enregistré(e) sous le numéro (SIRET) :" + this.siret, {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 25
            firstPage.drawText("autorise expressément SMART-ELECTRICITE et  tout autre fournisseur d'énergie ", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            var lines = "à demander et recevoir les données de consommation (et courbe de charge, le cas échéant) liées à" +
                " mon/mes site(s) de consommation raccordé(s) aux réseaux publics de distribution d’électricité et/ou de gaz dans le but de me proposer une offre commerciale."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            startingPositon = startingPositon - 25
            for (let i = 0; i < multiText.lines.length; i++) {
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 30
            firstPage.drawText('N° PDL(s)', {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            firstPage.drawText('N° PCE(s)', {
                x: 250,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 20
            var lengthCompteur = 0
            if (this.num_comp_gaz.length > this.num_comp_elec.length) {
                lengthCompteur = this.num_comp_gaz.length
            } else {
                lengthCompteur = this.num_comp_elec.length
            }
            for (var i = 0; i < lengthCompteur; i++) {
                if (i < this.num_comp_gaz.length) {
                    firstPage.drawText('-' + this.num_comp_gaz[i], {
                        x: 250,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                if (i < this.num_comp_elec.length) {
                    firstPage.drawText('-' + this.num_comp_elec[i], {
                        x: 100,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                    })
                }
                startingPositon = startingPositon - 15
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
            }
            startingPositon = startingPositon - 25
            lines = "J’accepte expressément que mes données soient conservées par SMART-ELECTRICITE et tout autre fournisseur d'énergie " +
                "à des fins de gestion et de traçabilité, pour une durée de 12 mois"
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: timesRomanFont,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 50,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("Cette autorisation de collecte de données est obligatoire et n'engage aucun contrat ni engagement ni aucun frais.", {
                x: 50,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("*Consulter la liste de nos partenaires fournisseurs d’énergie : https://gaz-elec-moinscher.fr/acd-fournisseurs-partenaires/ ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })

            startingPositon = startingPositon - 50
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("SMART-ELECTRICITÉ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("RCS Chambéry n° 84783865300016 F.MARCHAND ", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            firstPage.drawText("(06.27.79.34.93)", {
                x: 100,
                y: startingPositon,
                size: 9,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 12
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("Cette autorisation reproduit celle du partenaire", {
                x: 100,
                y: startingPositon,
                size: 6,
                color: rgb(0, 0, 0),
            })
            //fin contact
            const pdfBytes = await pageZeroDoc.save()
            download(pdfBytes, 'ACD_' + this.raison + '_.pdf', 'application/pdf')
            //create form data
            var formData = new FormData()
            const pdfDataUri = await pageZeroDoc.saveAsBase64({ dataUri: true });
            var pdfFile = await this.urltoFile(pdfDataUri, 'acd.pdf', "application/pdf").then((file) => {
                return file
            })
            formData.append('siret', this.siret)
            formData.append('RCS', this.RCS)
            formData.append('siren', this.siren)
            formData.append('raison', raison)
            formData.append('adresse', this.adressePostale)
            formData.append('numTele', this.num_tel)
            formData.append('nom', nom)
            formData.append('prenom', prenom)
            formData.append('email', email)
            formData.append('type', 'DUAL')
            formData.append('numCompteurGaz', this.num_comp_gaz)
            formData.append('numCompteurElec', this.num_comp_elec)
            formData.append('pdf', pdfFile)
            formData.append('token', this.getToken('token'))
            this.formData = formData
            this.isSend = 1
            this.dialog = false
            this.loading = false
            this.error = false
        },
        async generatePowerConseACD(Energie) {

            var raison = this.raison
            var nom = this.nom
            var prenom = this.prenom
            var email = this.email
            var fonction = this.fonction
            var address = this.adressePostale

            var url0 = '/pdf/acd/powerConseille.pdf'
            const pageZero = await fetch(url0).then((res) =>
                res.arrayBuffer()
            )
            // Load a PDFDocument from each of the existing PDFs
            const pageZeroDoc = await PDFDocument.load(pageZero)

            var getPages = pageZeroDoc.getPages()
            var firstPage = getPages[0]
            const { width, height } = firstPage.getSize()
            var pageHeight = height
            var startingXPositon = 70;
            var startingPositon = pageHeight - 225
            const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
            const fontSize = 12;
            var lineRaison = "Je soussigné "
                + nom
                + " "
                + prenom
                + " dûment habilité à représenter la société "
                + raison
                + ", dont le siège social se situe au "
                + address +
                ", immatriculée sous le numéro SIRET"
                + this.siret
                + ", donne l’autorisation à la société gestionnaires de réseaux d’électricité et / ou de gaz aux données techniques (courbe de charge,consommations et profils, capacité journalière en gaz, puissance en électricité et tarif d’acheminement sur les 36 derniers mois), associées aux points de livraison ci-après, dans le but de préparer une offre de fourniture d’électricité et / ou de gaz :"
            var multiText = layoutMultilineText(lineRaison, {
                alignment: 'left',
                fontSize: fontSize,
                font: timesRomanFont,
                lineHeight: 28,
                bounds: { width: width - (startingXPositon*2), height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {

                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: startingXPositon,
                    y: startingPositon,
                    size: fontSize,
                    maxWidth: width - (startingXPositon*2),
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 28
            }

            var xPDLs = 100;
            var xPCEs = 250;
            // ELEC PDLs
            startingPositon = startingPositon - 10
            if (Energie == 'ELEC' || Energie == 'DUAL') {
                firstPage.drawText('N° PDL(s)', {
                    x: xPDLs,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })  
            } else {
                xPCEs = xPDLs
            }
            if (Energie == 'GAZ' || Energie == 'DUAL') {
                firstPage.drawText('N° PCE(s)', {
                    x: xPCEs,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
            }
            startingPositon = startingPositon - 25
            var lengthCompteur = 0
            if (this.num_comp_gaz.length > this.num_comp_elec.length) {
                lengthCompteur = this.num_comp_gaz.length
            } else {
                lengthCompteur = this.num_comp_elec.length
            }
            for (var i = 0; i < lengthCompteur; i++) {
                if (i < this.num_comp_gaz.length && (Energie == 'GAZ' || Energie == 'DUAL')) {
                    firstPage.drawText('-' + this.num_comp_gaz[i], {
                        x: xPCEs,
                        y: startingPositon,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    })
                }
                if (i < this.num_comp_elec.length && (Energie == 'ELEC' || Energie == 'DUAL')) {
                    firstPage.drawText('-' + this.num_comp_elec[i], {
                        x: xPDLs,
                        y: startingPositon,
                        size: fontSize,
                        color: rgb(0, 0, 0),
                    })
                }
                startingPositon = startingPositon - 25
                var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
            }

            startingPositon = startingPositon - 25
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            firstPage.drawText("Cette autorisation reste valable pour une durée de 12 mois à compter de la date de signature.", {
                x: startingXPositon,
                y: startingPositon,
                size: fontSize,
                color: rgb(0, 0, 0),
            })

            startingPositon = startingPositon - 30
            var resultatDeVerificationDeHeight = this.verifPageEnd(pageHeight, pageZeroDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("Fait à " + "_________________ " + " le " + DateAujourdhui, {
                x: startingXPositon,
                y: startingPositon,
                size: fontSize,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 30
            firstPage.drawText("Signature :", {
                x: startingXPositon,
                y: startingPositon,
                size: fontSize,
                color: rgb(0, 0, 0),
            })
          
            //fin contact
            const pdfBytes = await pageZeroDoc.save()
            var file = download(pdfBytes, 'ACD_' + this.raison + '_.pdf', 'application/pdf')
            //create form data

            var formData = new FormData()
            const pdfDataUri = await pageZeroDoc.saveAsBase64({ dataUri: true });
            var pdfFile = await this.urltoFile(pdfDataUri, 'acd.pdf', "application/pdf").then((file) => {
                return file
            })
            formData.append('siret', this.siret)
            formData.append('RCS', this.RCS)
            formData.append('siren', this.siren)
            formData.append('raison', raison)
            formData.append('adresse', this.adressePostale)
            formData.append('numTele', this.num_tel)
            formData.append('nom', nom)
            formData.append('prenom', prenom)
            formData.append('email', email)
            formData.append('type', 'ELEC')
            formData.append('numCompteurGaz', this.num_comp_gaz)
            formData.append('numCompteurElec', this.num_comp_elec)
            formData.append('pdf', pdfFile)
            formData.append('token', this.getToken('token'))
            this.formData = formData
            this.isSend = 1
            this.dialog = false
            this.loading = false
            this.error = false
        },
        async generatePdf() {
            this.$refs.formUpload.validate()
            if (this.$refs.formUpload.validate()) {
                this.hasGeneratePDF = true;
                /*if (this.typeContrat === 'GAZ') {
                    this.generatePdfGaz()
                } else if (this.typeContrat === 'ELEC') {
                    this.generatePdfElec()
                }else{
                    this.generatePdfElecAndGaz()
                }*/
                //recherche point enedis
                let self = this

                self.dialog = true
                self.loading = true
                self.error = false

                // convert the html to pdf
                if (this.typeContrat === 'GAZ') {
                    await this.generateACDGAZ('GAZ')
                    if (this.isSmartElectricite) {
                        await this.generateSmartACDGAZ()
                    }
                    if (this.isPowerConseil) {
                        await this.generatePowerConseACD('GAZ')
                    }
                } else if (this.typeContrat === 'ELEC') {
                    let self = this;
                    if (this.traitemantEnedisRegie == 'non') {
                        var formDonne = new FormData()
                        formDonne.append('numCompteur', this.num_comp_elec)
                        formDonne.append('token', this.getToken('token'))

                        await axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + 'verifCompteurExist/',
                            method: 'POST',
                            data: formDonne,

                        }).then(async (res) => {
                            if (res.data.result === 'permission') {
                                localStorage.removeItem('vendeurName')
                                localStorage.removeItem('typeUser')
                                localStorage.removeItem('token')
                                this.$router.push('/login')
                            }
                            if (res.data[0].result === 'valide') {
                                self.enedisRecherchePointResult = res.data[0].result;
                                await self.generateACDGAZ('ELEC')
                                if (this.isSmartElectricite) {
                                    await this.generateSmartACDELEC()
                                }
                                if (this.isPowerConseil) {
                                    await this.generatePowerConseACD('ELEC')
                                }
                            } else if (res.data[0].result === 'notValide') {
                                self.dialog = false
                                self.loading = false
                                self.error = false
                                self.enedisRecherchePointResult = res.data[0].result;
                                self.missedCompteur = res.data[0].compteurError;
                                self.dialog = true
                                self.error = true
                                self.messageDialoge = "le compteur n°" + self.missedCompteur + " n'existe pas"
                            } else if (res.data[0].result === 'dataErreur') {
                                self.dialog = false
                                self.loading = false
                                self.error = false
                                self.enedisRecherchePointResult = res.data[0].result;
                                self.dialog = true
                                self.error = true
                                self.messageDialoge = "veuillez vérifier vos données."
                            } else {
                                self.dialog = false
                                self.loading = false
                                self.error = false
                                self.enedisRecherchePointResult = res.data[0].result;
                                self.dialog = true
                                self.error = true
                                self.messageDialoge = "Il semble qu'une erreur soit survenue !"
                            }
                        }).catch((err) => {

                            self.dialog = false
                            self.loading = false
                            self.error = false
                            console.log(err)
                        })
                    } else {
                        await self.generateACDGAZ('ELEC')
                        if (this.isSmartElectricite) {
                            await this.generateSmartACDELEC()
                        }
                        if (this.isPowerConseil) {
                            await this.generatePowerConseACD('ELEC')
                        }
                        self.loading = false;
                    }
                } else {
                    let self = this;
                    if (this.traitemantEnedisRegie == 'non') {
                        var formDonne = new FormData()
                        formDonne.append('numCompteur', this.num_comp_elec)
                        formDonne.append('token', this.getToken('token'))

                        await axios({
                            url: process.env.VUE_APP_URL_API_CLIENT + 'verifCompteurExist/',
                            method: 'POST',
                            data: formDonne,

                        }).then(async (res) => {
                            if (res.data.result === 'permission') {
                                localStorage.removeItem('vendeurName')
                                localStorage.removeItem('typeUser')
                                localStorage.removeItem('token')
                                this.$router.push('/login')
                            }
                            if (res.data[0].result === 'valide') {
                                self.enedisRecherchePointResult = res.data[0].result;
                                await self.generateACDGAZ('DUAL')
                                if (this.isSmartElectricite) {
                                    await this.generateSmartACDDUAL()
                                }
                                if (this.isPowerConseil) {
                                    await this.generatePowerConseACD('DUAL')
                                }
                            } else if (res.data[0].result === 'notValide') {

                                self.dialog = false
                                self.loading = false
                                self.error = false
                                self.enedisRecherchePointResult = res.data[0].result;
                                self.missedCompteur = res.data[0].compteurError;
                                self.dialog = true
                                self.error = true
                                self.messageDialoge = "le compteur n°" + self.missedCompteur + " n'existe pas"
                            } else if (res.data[0].result === 'dataErreur') {
                                self.dialog = false
                                self.loading = false
                                self.error = false
                                self.enedisRecherchePointResult = res.data[0].result;
                                self.dialog = true
                                self.error = true
                                self.messageDialoge = "veuillez vérifier vos données comme l'adresse (ou) code insee."
                            } else {
                                self.dialog = false
                                self.loading = false
                                self.error = false
                                self.enedisRecherchePointResult = res.data[0].result;
                                self.dialog = true
                                self.error = true
                                self.messageDialoge = "Il semble qu'une erreur soit survenue !"
                            }
                        }).catch((err) => {
                            self.dialog = false
                            self.loading = false
                            self.error = false
                            console.log(err)
                        }).finally(() => {
                            self.loading = false;
                        })
                    } else {
                        await self.generateACDGAZ('DUAL')
                        self.loading = false;
                    }
                }

                self.dialog = false
                self.loading = false
                self.error = false
            }

            self.dialog = false
            self.loading = false
            self.error = false
        },
        async generateACDGAZ(Energie) {
            let self = this
            var raison = this.raison
            var siret = this.siret
            var nom = this.nom
            var prenom = this.prenom
            var email = this.email
            var fonction = this.fonction



            var tableDoc = new jsPDF('p', 'pt', 'a4')
            if (Energie == "GAZ") {

                var tableRows = [["NOM, Prénom", nom + " " + prenom], ["Société", raison], ["N° SIREN", siret.substring(0, 9)],
                ["Point de Mesure(GAZ)", self.num_comp_gaz]]
            } else if (Energie == "ELEC") {

                var tableRows = [["NOM, Prénom", nom + " " + prenom], ["Société", raison], ["N° SIREN", siret.substring(0, 9)],
                ["Point de Mesure(ELEC)", self.num_comp_elec + ""]]
            } else if (Energie == "DUAL") {
                var tableRows = [["NOM, Prénom", nom + " " + prenom], ["Société", raison], ["N° SIREN", siret.substring(0, 9)],
                ["Point de Mesure(ELEC)", self.num_comp_elec + ""], ["Point de Mesure(GAZ)", self.num_comp_gaz]]

            }
            var columnStyle = {
                0: { halign: 'center', valign: 'middle', fillColor: [255, 255, 255], textColor: 0, lineColor: 0, fontSize: 9, lineWidth: 1, cellWidth: 120, minCellHeight: 25, fontStyle: 'bold' },
                1: { halign: 'center', valign: 'middle', fillColor: [255, 255, 255], textColor: 0, lineColor: 0, fontSize: 9, lineWidth: 1, cellWidth: 340, minCellHeight: 25, fontStyle: 'normal' },
            }
            tableDoc.autoTable(['', ''], tableRows,
                {
                    startX: 40,
                    margin: 70,
                    startY: 150,
                    columnStyles: columnStyle,
                    headStyles: { fillColor: [255, 255, 255], halign: 'center', valign: 'middle' }
                })
            let finalY = tableDoc.lastAutoTable.finalY; // The y position on the page
            let blob = tableDoc.output("arraybuffer");
            const tableSiteDoc = await PDFDocument.load(blob)
            var pages = tableSiteDoc.getPages()
            var firstPage = pages[0]
            const { width, height } = firstPage.getSize()
            var pageHeight = height
            var HelveticaBold = await tableSiteDoc.embedFont(StandardFonts.HelveticaBold)
            var Helvetica = await tableSiteDoc.embedFont(StandardFonts.Helvetica)
            var urlImage = `/pdf/gemc.png`
            const ImageLogo = await fetch(urlImage).then((res) =>
                res.arrayBuffer()
            )
            var pngImage = await tableSiteDoc.embedPng(ImageLogo)
            var pngDims = pngImage.scale(1)
            firstPage.drawImage(pngImage, {
                x: 430,
                y: 745,
                width: 100,
                height: 50,
            })

            var startingPositon = 720
            var title = "Autorisation collecte de données pour demander des offres"
            var lineRaison = "Je soussigné :"
            firstPage.drawText(`${title}`, {
                x: 70,
                y: startingPositon,
                font: HelveticaBold,
                size: 15,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
            })
            startingPositon -= 40
            firstPage.drawText(`${lineRaison}`, {
                x: 70,
                y: startingPositon,
                size: 9,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
            })
            startingPositon = 820 - finalY
            if (820 - finalY <= 160) {
                firstPage = tableSiteDoc.addPage()
                startingPositon = 720
            }
            
            var firstLine = "Autorisant ";
            var boldText = "Gaz-Elec Moins Cher";
            var remainingFirstLine = " à accéder aux données mentionnées ci-dessus.";
            var remainingText = "Cette autorisation permet à  Gaz-Elec Moins Cher et aux fournisseurs d’énergie* (au sens de l’article L333-1 et L443-1 du\n"+
                                "code de l’énergie) partenaires avec lesquels celle-ci est liée par contrat de demander et consulter les données suivantes : ";

            var multiTextFirstLine = layoutMultilineText(firstLine + boldText + remainingFirstLine, {
                alignment: 'left',
                fontSize: 9,
                font: Helvetica, // Default font for the rest of the text
                bounds: { width: 630 - 100, height: 1000 }
            })

            var multiTextRemaining = layoutMultilineText(remainingText, {
                alignment: 'left',
                fontSize: 9,
                font: Helvetica,
                bounds: { width: 630 - 100, height: 1000 }
            })

            for (let i = 0; i < multiTextFirstLine.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon

                // First line handling with bold text
                if (i === 0) {
                    var lineText = multiTextFirstLine.lines[i].text;
                    var startBoldIndex = lineText.indexOf(boldText);
                    if (startBoldIndex !== -1) {
                        firstPage.drawText(lineText.slice(0, startBoldIndex), {
                            x: 70,
                            y: startingPositon,
                            size: 9,
                            maxWidth: 630 - 100,
                            color: rgb(0, 0, 0),
                            font: Helvetica
                        });
                        firstPage.drawText(lineText.slice(startBoldIndex, startBoldIndex + boldText.length), {
                            x: 112,
                            y: startingPositon,
                            size: 9,
                            maxWidth: 630 - 100,
                            color: rgb(0, 0, 0),
                            font: HelveticaBold
                        });
                        firstPage.drawText(lineText.slice(startBoldIndex + boldText.length), {
                            x: 202,
                            y: startingPositon,
                            size: 9,
                            maxWidth: 630 - 100,
                            color: rgb(0, 0, 0),
                        });
                    }
                } else {
                    firstPage.drawText(multiTextFirstLine.lines[i].text, {
                        x: 70,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 630 - 100,
                        color: rgb(0, 0, 0),
                    });
                }

                // Move position down after each line
                startingPositon = startingPositon - 15
            }

            // Now handle the remaining text after the first line
            for (let i = 0; i < multiTextRemaining.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(multiTextRemaining.lines[i].text, {
                    x: 70,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                    font: Helvetica
                });

                // Move position down after each line
                startingPositon = startingPositon - 15
            }
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon - 7
            var txt = "ELECTRICITÉ"
            firstPage.drawText(txt, {
                x: 70,
                y: startingPositon,
                size: 10,
                font: HelveticaBold,
                color: rgb(0, 0, 0),
            })
            var lesTextsElec = ["•      L’historique des mesures en kWh du site, les puissances atteintes et dépassements de puissance",
                "•      L’historique des relevés d’index quotidien en kWh et la puissance maximale quotidienne en kVA ou kWh de votre site ",
                "•      L’historique de courbe de charge au pas restitué par Enedis de votre site ;",
                "•      Les données techniques et contractuelles disponibles de votre site."
            ]
            var lesTextsGaz = ["•       Les données publiées (données transmises à votre fournisseur d’énergie pour la facturation)",
                "•       Les données informatives journalières (si disponibles)",
                "•       Les données contractuelles (données relatives au contrat avec votre fournisseur)",
                "•       Les données techniques (données relatives aux détails techniques de votre compteur)"
            ]
            for (var i = 0; i < lesTextsElec.length; i++) {
                startingPositon = startingPositon - 17
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(lesTextsElec[i], {
                    x: 80,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
            }
            startingPositon = startingPositon - 25
            var txt = "GAZ"
            firstPage.drawText(txt, {
                x: 70,
                y: startingPositon,
                size: 10,
                font:HelveticaBold,
                color: rgb(0, 0, 0),
            })
            for (var i = 0; i < lesTextsGaz.length; i++) {
                startingPositon = startingPositon - 17
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(lesTextsGaz[i], {
                    x: 80,
                    y: startingPositon,
                    size: 9,
                    color: rgb(0, 0, 0),
                })
            }

            startingPositon = startingPositon - 22
            var lines = "Solliciter l’ensemble des fournisseurs d’énergie en votre nom afin qu’ils élaborent des offres de fourniture."
            firstPage.drawText(lines, {
                x: 70,
                y: startingPositon,
                size: 9,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 20
            var lines = "Vous donnez l’autorisation à la société Gaz-Elec Moins Cher de demander et recevoir des différents fournisseurs d’énergie" +
                " des offres de fourniture de gaz naturel et d'électricité pour votre compteurs mentionnés ci-dessus."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: HelveticaBold,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                const resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon);
                firstPage = resultatDeVerificationDeHeight.page;
                startingPositon = resultatDeVerificationDeHeight.startingPositon;

                const currentLine = multiText.lines[i].text;

                // Vérifie si la ligne contient "Gaz-Elec Moins Cher"
                if (currentLine.includes('Gaz-Elec Moins Cher')) {
                    const beforeBold = currentLine.split('Gaz-Elec Moins Cher')[0];
                    const afterBold = currentLine.split('Gaz-Elec Moins Cher')[1];

                    // Texte avant en régulier
                    firstPage.drawText(beforeBold, {
                        x: 70,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                    });

                    // Texte en gras
                    const beforeBoldWidth = HelveticaBold.widthOfTextAtSize(beforeBold, 9);
                    firstPage.drawText('Gaz-Elec Moins Cher', {
                        x: 225,
                        y: startingPositon,
                        size: 9,
                        font: HelveticaBold,
                        color: rgb(0, 0, 0),
                    });

                    // Texte après en régulier
                    const boldTextWidth = HelveticaBold.widthOfTextAtSize('Gaz-Elec Moins Cher', 9);
                    firstPage.drawText(afterBold, {
                        x: 315,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                    });
                } else {
                    // Texte normal si la ligne ne contient pas "Gaz-Elec Moins Cher"
                    firstPage.drawText(currentLine, {
                        x: 70,
                        y: startingPositon,
                        size: 9,
                        color: rgb(0, 0, 0),
                    });
            }

                // Descendre la position
            startingPositon -= 15;
            }
            startingPositon = startingPositon - 5
            var lines = "Vous autorisez expressément Gaz-Elec Moins Cher ainsi que et l'ensemble des fournisseurs d'énergie partenaires" +
                " avec lesquels nous sommes liée par contrat, à demander aux gestionnaires de réseau de distribution de gaz naturel et " +
                "d'électricité (Enedis, GRDF…) et au(x) fournisseur(s) actuel(s).\nNous autorisons expressément Gaz-Elec Moins Cher à :"

            // Fonction pour mettre en gras "Gaz-Elec Moins Cher"
            function formatTextWithBold(text) {
                // Remplacer toutes les occurrences de "Gaz-Elec Moins Cher" par une version marquée
                return text.replace(/Gaz-Elec Moins Cher/g, "{{BOLD}}Gaz-Elec Moins Cher{{/BOLD}}");
            }

            var formattedText = formatTextWithBold(lines);
            var textParts = formattedText.split(/(\{\{BOLD\}\}.*?\{\{\/BOLD\}\})/g);

            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: Helvetica, // Utiliser la police normale par défaut
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })

            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                
                // Traiter le texte pour cette ligne
                var currentLine = multiText.lines[i].text;
                var parts = currentLine.split(/Gaz-Elec Moins Cher/g);
                
                var currentX = 70;
                
                // Dessiner chaque partie avec la police appropriée
                for (let j = 0; j < parts.length; j++) {
                    
                    if (parts[j].length > 0) {
                        firstPage.drawText(parts[j], {
                            x: currentX,
                            y: startingPositon,
                            size: 9,
                            font: Helvetica, 
                            maxWidth: 630 - 100,
                            color: rgb(0, 0, 0),
                        });
                        
                        currentX += Helvetica.widthOfTextAtSize(parts[j], 9);
                    }
                    
                    if (j < parts.length - 1) {
                        firstPage.drawText("Gaz-Elec Moins Cher", {
                            x: currentX,
                            y: startingPositon,
                            size: 9,
                            font: HelveticaBold, // Police en gras
                            maxWidth: 630 - 100,
                            color: rgb(0, 0, 0),
                        });
                        
                        currentX += HelveticaBold.widthOfTextAtSize("Gaz-Elec Moins Cher", 9);
                    }
                }
                
                // Déplacer la position vers le bas
                startingPositon = startingPositon - 15;
            }
            startingPositon = startingPositon - 5
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var lines = "•      Demander à tout fournisseur des offres de fourniture de gaz naturel et/ou d’électricité pour votre compteurs mentionnés ci-dessus."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: HelveticaBold,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 85,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }
            startingPositon = startingPositon - 5
            var lines = "•      Recevoir les offres et poster toutes les questions utiles au(x) fournisseur(s) sur les conditions techniques et financières de la fourniture d’énergie."
            var multiText = layoutMultilineText(lines, {
                alignment: 'left',
                fontSize: 9,
                font: HelveticaBold,
                lineHeight: 9,
                bounds: { width: 630 - 100, height: 1000 }
            })
            for (let i = 0; i < multiText.lines.length; i++) {
                var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
                firstPage = resultatDeVerificationDeHeight.page
                startingPositon = resultatDeVerificationDeHeight.startingPositon
                firstPage.drawText(`${multiText.lines[i].text}`, {
                    x: 85,
                    y: startingPositon,
                    size: 9,
                    maxWidth: 630 - 100,
                    color: rgb(0, 0, 0),
                })
                // move position down
                startingPositon = startingPositon - 15
            }

            startingPositon = startingPositon - 5
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var lines = "transmettra la présente autorisation au(x) fournisseur(s) interrogé(s) dans le cadre de sa mission."
            firstPage.drawText('Gaz-Elec MoinsCher', {
                x: 70,
                y: startingPositon,
                size: 9,
                font: HelveticaBold,
                color: rgb(0, 0, 0),
            });
            firstPage.drawText(lines, {
                x: 160,
                y: startingPositon,
                size: 9,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
            })
            startingPositon = startingPositon - 15
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var lines = "Cette autorisation est valable pour une durée de 12 mois à compter de sa signature et n’engage aucun frais de votre part."
            firstPage.drawText(lines, {
                x: 70,
                y: startingPositon,
                size: 9,
                maxWidth: 630 - 100,
                color: rgb(0, 0, 0),
                htmlTags: true,
            })
            startingPositon = startingPositon - 20
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("Date : " + DateAujourdhui + "                                           Signature avec pour mention “Bon pour accord” : ", {
                x: 70,
                y: startingPositon,
                font: HelveticaBold,
                size: 9,
                color: rgb(0, 0, 0),
            })

            startingPositon = startingPositon - 45
            var resultatDeVerificationDeHeight = self.verifPageEnd(pageHeight, tableSiteDoc, firstPage, startingPositon)
            firstPage = resultatDeVerificationDeHeight.page
            startingPositon = resultatDeVerificationDeHeight.startingPositon
            var DateAujourdhui = new Date();
            DateAujourdhui = DateAujourdhui.getDate() + "/" + (DateAujourdhui.getMonth() + 1) + "/" + DateAujourdhui.getFullYear()
            firstPage.drawText("*Consulter la liste de nos partenaires fournisseurs d’énergie :", {
                x: 70,
                y: startingPositon,
                font: HelveticaBold,
                size: 7.5,
                color: rgb(0, 0, 0),
            });

            // Lien en bleu
            firstPage.drawText(" https://gaz-elec-moinscher.fr/acd-fournisseurs-partenaires/", {
                x: 290, // Ajuste cette valeur si le lien ne s'aligne pas bien
                y: startingPositon,
                font: HelveticaBold,
                size: 7.5,
                color: rgb(0, 0, 1), // Bleu
            });

            //fin contact
            //create form data
            var formData = new FormData()

            const pdfBytes = await tableSiteDoc.save()
            download(pdfBytes, 'Autorisation de collecte de données_' + this.raison + '_' + DateAujourdhui + '.pdf', 'application/pdf')

            const pdfDataUri = await tableSiteDoc.saveAsBase64({ dataUri: true });
            var pdfFile = await this.urltoFile(pdfDataUri, 'acd.pdf', "application/pdf").then((file) => {
                return file
            })
            formData.append('siret', self.siret)
            formData.append('raison', raison)
            formData.append('nom', nom)
            formData.append('prenom', prenom)
            formData.append('type', 'GAZ')
            formData.append('numCompteurGaz', self.num_comp_gaz)
            formData.append('numCompteurElec', self.num_comp_elec)
            formData.append('pdf', pdfFile)
            formData.append('token', self.getToken('token'))
            self.formData = formData
            self.isSend = 1
            self.dialog = false
            self.loading = false
            self.error = false
        },
        async urltoFile(url, filename, mimeType) {
            return (fetch(url)
                .then(function (res) { return res.arrayBuffer(); })
                .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            );
        },
        //envoyer le document et reinitiliser tout les valeur
        enregistrer() {
            let self = this
            self.dialog = true
            self.loading = true
            self.error = false

            //upload pdf file to php server le form data remplire dans les function generate pdf...
            axios({
                url: process.env.VUE_APP_URL_API_CLIENT + 'autorisationDeCollebctionDeDonner/',
                method: 'POST',
                data: self.formData,

            }).then((res) => {
                if (res.data.result == false) {
                    self.examine_file = false
                    self.dialog = false
                    self.loading = false
                    self.error = false
                    self.dialog = true
                    self.loading = false
                    self.error = true
                    self.messageDialoge = "Une erreur s'est produite veuillez réessayer"
                } else if (res.data.result == true) {

                    self.dialog = false
                    self.loading = false
                    self.error = false
                    self.dialog = true
                    self.loading = false
                    self.error = false
                    self.messageDialoge = "Cette demande a été enregistrée avec succès"
                    self.siretFound = 0
                    self.isSend = 0
                    self.inseeResponse = false
                    self.siren = null
                    self.nom = null
                    self.prenom = null
                    self.raison = null
                    self.email = null
                    self.num_tel = null
                    self.elec = null
                    self.num_comp_gaz = []
                    self.lengthCompteurgaz = 0
                    self.lengthCompteurelec = 0
                    self.num_comp_elec = []
                } else if (res.data.result === 'permission') {
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser')
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                    //access denied
                } else {
                    self.examine_file = false

                    self.dialog = false
                    self.loading = false
                    self.error = false
                    self.dialog = true
                    self.loading = false
                    self.error = true
                    self.messageDialoge = "Une erreur s'est produite veuillez réessayer"
                }
            }).catch((err) => {
                console.log(err)
                self.examine_file = false

                self.dialog = false
                self.loading = false
                self.error = false
                self.dialog = true
                self.loading = false
                self.error = true
                self.messageDialoge = "Une erreur s'est produite veuillez réessayer"
            })
        },
        // fonction qui crée la date du jour pour la mettre à la fin du fichier pdf "fait le"
        currentDateTime() {
            const current = new Date()
            const date =
                current.getDate() +
                '/' +
                (current.getMonth() + 1) +
                '/' +
                current.getFullYear()
            return date
        },
    }
}

</script>
<style scoped>
::v-deep .v-label {
    margin: 0px;
}
</style>
<style scoped>
@media only screen (max-width:350px) {
    ::v-deep .v-application .ml-2 {
        margin-left: 0px !important;
    }
}
</style>
<style>
@media only screen and (max-width:400px) {
    .title {
        font-size: small,
    }
}
</style>
