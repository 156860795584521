<template>
  <div>
    <v-form ref="updateCourbe">
      <v-row class="pt-1 text-center text-md-start">
        <v-col cols="12" sm="12" md="12" lg="12">
          <span class="my-2 font-weight-bold">Recherche par plage de date</span>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4">
          <v-text-field
            :rules="[
              rules.verifyDate,
              dateDebutInferieureDateFin(firstDateSearch, lastDateSearch),
            ]"
            outlined
            dense
            v-model="firstDateSearch"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="4">
          <v-text-field
            :rules="[rules.verifyDate]"
            outlined
            dense
            v-model="lastDateSearch"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4">
          <v-btn
            dense
            @click="updateCourbe()"
            class="white--text Poppins-Bold"
            :color="$colors[0]"
            ><span class="Poppins-Bold">Rechercher</span></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "research-date",
  components: {},
  data() {
    return {
      firstDateSearch: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
      lastDateSearch: dayjs().format("YYYY-MM-DD"),

      rules: {
        verifyDate: (value) =>
          !!value ||
          dayjs(value, "YYYY-MM-DD", true).isValid() ||
          "Date invalide",
        requiredDate: (value) => !!value || "Vous devez mettre une date.",
        regexDate: (value) => {
          const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
          return regex.test(value) || "Date invalide !";
        },
      },

      dateDebutInferieureDateFin(dateDebut, dateFin) {
        if (dateDebut < dateFin) {
          return true;
        } else {
          return "La date de début ne doit pas etre supérieur ni égale à la date de fin";
        }
      },
    };
  },
  methods: {
    updateCourbe() {
      if (this.$refs.updateCourbe.validate()) {
        this.$emit("updateCourbe", {
          dateDebut: this.firstDateSearch,
          dateFin: this.lastDateSearch,
        });
      }
    },
  },
};
</script>
<style scoped>
::v-deep .theme--light.v-input input,
.theme--light.v-input textarea {
  padding: 0px !important;
}
</style>
