<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-btn
                    v-if="(whoIam == 'admin' || whoIam == 'superadmin' || whoIam == 'Chef équipe')" :color="'error'" 
                    class="white--text mb-5" @click="closeTableObjectif"
                    >
                    Annuler
                    <v-icon class="ml-2">mdi-minus</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="8" lg="9" xl="10">
                <v-radio-group @change="getAllObjectifByComptes" v-model="typeObjectif" row>
                    <v-radio label="CA" value="CA"></v-radio>
                    <v-radio label="cotation" value="cotation"></v-radio>
                    <v-radio label="vente" value="vente"></v-radio>
                </v-radio-group>
            </v-col>
            
        </v-row>
        <div style="max-height: 300px; overflow-x: auto; overflow: auto;">
            <table class="table table-striped table-bordered" v-if="itemsInTable.length>0">
                <thead>
                    <tr>
                        <th class="thTitle" style="text-align: center;" v-for="(column, index) in headers" :key="'header'+column.name"
                            :style="'width:'+column.width+';'">
                            {{column.name}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(compte, index) in itemsInTable" :key="'compteLine'+compte.compteId" style="background-color: #DDE6ED;" v-if="typeObjectif=='CA'">
                        <td style="text-align: center;" v-for="(month, index1) in headers" :key="compte.compteId+'value'+month.name">
                            <div v-if="index1 == 0"> {{compte.compteName }}</div>
                            <div v-else-if="index1 != 13">
                                <input 
                                    v-model="compte.objectif.CA[month.name]"
                                    type="text"
                                    class="input"
                                />
                            </div> 
                            <div v-else>
                                <v-btn color="primary" @click="ajouterOrUpdate(index, 'CA')">Modifier</v-btn>
                            </div> 
                        </td>
                    </tr>
                    <tr v-for="(compte, index) in itemsInTable" :key="'compteLine'+compte.compteId" style="background-color: #DDE6ED;" v-if="typeObjectif=='cotation'">
                        <td style="text-align: center;" v-for="(month, index1) in headers" :key="compte.compteId+'value'+month.name">
                            <div v-if="index1 == 0"> {{compte.compteName }}</div>
                            <div v-else-if="index1 != 13">
                                <input 
                                    v-model="compte.objectif.cotation[month.name]"
                                    type="text"
                                    class="input"
                                />
                            </div> 
                            <div v-else>
                                <v-btn color="primary" @click="ajouterOrUpdate(index, 'cotation')">Modifier</v-btn>
                            </div> 
                        </td>
                    </tr>
                    <tr v-for="(compte, index) in itemsInTable" :key="'compteLine'+compte.compteId" style="background-color: #DDE6ED;" v-if="typeObjectif=='vente'">
                        <td style="text-align: center;" v-for="(month, index1) in headers" :key="compte.compteId+'value'+month.name">
                            <div v-if="index1 == 0"> {{compte.compteName }}</div>
                            <div v-else-if="index1 != 13">
                                <input 
                                    v-model="compte.objectif.vente[month.name]"
                                    type="text"
                                    class="input"
                                />
                            </div> 
                            <div v-else>
                                <v-btn color="primary" @click="ajouterOrUpdate(index, 'vente')">Modifier</v-btn>
                            </div> 
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import axios from "axios";
    export default{
        props: ['selectedCompteFilter', 'selectedEquipeFilter', 'selectedYearFilter', 'filteredCompteList', 'whoIam'],
        data() {
            return {
                itemsInTable: [],
                defaultItemsInTable: [],
                typeObjectif: 'CA',
                headers: [
                    {name: 'Compte/Month', width:'9vw'}, 
                    {name: "01", width:'7vw'},
                    {name: "02", width:'7vw'},
                    {name: "03", width:'7vw'},
                    {name: "04", width:'7vw'},
                    {name: "05", width:'7vw'},
                    {name: "06", width:'7vw'},
                    {name: "07", width:'7vw'},
                    {name: "08", width:'7vw'},
                    {name: "09", width:'7vw'},
                    {name: "10", width:'7vw'},
                    {name: "11", width:'7vw'},
                    {name: "12", width:'7vw'},
                    {name: "action", width:'7vw'}
                ],

            }
        },
        async created() {
            await this.remplireDefaultObjectifListe(this.selectedCompteFilter)
            await this.getAllObjectifByComptes()
        },
        watch:{
            selectedCompteFilter:async function(newvalue,oldvalue){
                await this.remplireDefaultObjectifListe(newvalue)
                await this.getAllObjectifByComptes()
            },
        },
        methods: {
            closeTableObjectif(){
                this.$emit('closeTableObjectif',)
            },
            changeObjectifData(){
                this.$emit('changeObjectifData',)
            },
            async remplireDefaultObjectifListe(newvalue){
                this.defaultItemsInTable = []
                if(newvalue.length>0){
                    for(var i =0; i< newvalue.length; i++){
                        var compte = this.filteredCompteList.find(item => item.id === newvalue[i]);
                        var d = {
                            compteId: compte.id,
                            compteName: compte.displayName,
                            objectif: {
                            }
                        }
                        this.defaultItemsInTable.push(d)
                    }
                }else if(this.selectedEquipeFilter.length>0){
                    for(var i =0; i< this.filteredCompteList.length; i++){
                        var compte = this.filteredCompteList[i]
                        var d = {
                            compteId: compte.id,
                            compteName: compte.displayName,
                            objectif: {
                            }
                        }
                        this.defaultItemsInTable.push(d)
                    }
                }
            },
            async getAllObjectifByComptes(){
                 await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + "getAllObjectifByComptes/",
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    data: {
                        token: this.getToken("token"),
                        annee: this.selectedYearFilter,
                        typeObjectif: this.typeObjectif,
                        lesComptes: JSON.stringify(this.defaultItemsInTable),
                    },
                }).then((res) => {
                    if(res.data.result != 'permission'){
                        this.itemsInTable = res.data.result
                    }else{
                        this.itemsInTable = []
                    }
                }).catch((err) =>{
                    console.log(err)
                })
            },
            async ajouterOrUpdate(index, typeObjectif){
                var item = this.itemsInTable[index]
                
                 await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + "ajouterOrUpdateObjectifsComptes/",
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    data: {
                        token: this.getToken("token"),
                        annee: this.selectedYearFilter,
                        typeObjectif: this.typeObjectif,
                        leCompte: JSON.stringify(item),
                    },
                }).then(async (res) => {
                    if(res.data.result == 'updated'){
                        //await this.getAllObjectifByComptes()
                        alert("Les données ont été enregistrées.")
                    }else if(res.data.result == 'userNotInEquipe'){
                        //await this.getAllObjectifByComptes()
                        alert("Utilisateur non présent dans l'équipe.")
                    }else{
                        alert("Vous n'avez pas la permission.")
                    }
                }).catch((err) =>{
                    console.log(err)
                })
                this.changeObjectifData()
            }
        }
    }

</script>
<style scoped>
.thTitle{
    background-color:#103A5E;
    color:white;
    font-weight:bold;
    position: sticky; 
    top: 0; 
    z-index:1;
}
.input {
  display: block;
  min-width: 80px;
  width: 100%;
  padding: 8px 6px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  appearance: none;
  color: #99A3BA;
  border: #CDD9ED;
  background: #fff;
  transition: border .3s ease;
  outline: none;
}
.input::placeholder {
  color: #CBD1DC;
}
.input:focus {
  border-color: #275EFE;
}

</style>