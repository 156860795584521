<template>
  <div>
    <v-card>
      <v-toolbar
          v-if="propositions.length > 0"
          :color="$colors[0]"
          dark>
          <span class="Poppins-Bold pl-0">Propositions pour les particuliers</span>
      </v-toolbar>
      <Proposition
        v-for="proposition in propositions"
        v-bind:key="proposition.name"
        :proposition="proposition"
        :fta="fta"
      />
    </v-card>
  </div>
</template>
<script>
import Proposition from './Proposition.vue'
export default {
  name: 'ListPropositions',
  props: {
    propositions: [],
    fta: null,
  },
  components: {
    Proposition,
  },
  methods: {
    getImageLogo(logo) {
      return process.env.VUE_APP_URL_API_CLIENT + 'logoParticulier/' + logo
    },
  },
}
</script>
<style scoped>
@media only screen and (max-width:350px){
  .page-title{
    font-size:1.75em;
  }
}
</style>
