<template>
  <div>
    <div v-if="!this.typeContrat">
      <v-card
        elevation="6"
        height="30vh"
        min-height="400px"
        width="50vw"
        min-width="350px"
        class="mx-auto d-flex flex-column justify-space-between mt-12"
      >
        <v-card-title class="pa-0">
              <v-toolbar
              :color="$colors[0]"
              class="text-white"
              dark>                                    
                  <p
                  class="text-h6 mb-0"
                  >
                    Veuillez choisir le type de client
                  </p>
              </v-toolbar>
        </v-card-title>

        <v-card-actions>
          <v-row justify="center">
            <v-col
              cols="12"
              sm="12"
              md="4"
              xl="3"
              class="d-flex justify-xs-center justify-sm-center justify-md-end justify-lg-end justify-xl-start"
            >
              <v-checkbox
                :color="$colors[2]"
                v-model="checkboxProfessionnel"
                @change="checkboxParticulier = !checkboxProfessionnel"
                class="radio"
                label="Professionnel"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
              xl="3"
              class="d-flex justify-xs-center justify-sm-center justify-md-end justify-xl-end"
            >
              <v-checkbox
                :color="$colors[2]"
                v-model="checkboxParticulier"
                @change="checkboxProfessionnel = !checkboxParticulier"
                class="radio"
                label="Particulier"
              />
            </v-col>
          </v-row>
        </v-card-actions>

        <v-card-actions class="d-flex justify-end">
          <v-row>
            <v-spacer></v-spacer>
            <v-col
              class="d-flex justify-end"
              cols="12"
              sm="12"
              md="6"
              lg="5"
              xl="4"
            >
              <v-btn
                depressed
                elevation="2"
                large
                :color="$colors[0]"
                class="text-white"
                style="textTransform: none"
                width="80%"
                @click="handleTypeContratButtonClick"
              >
                Suivant
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeContrat: null,
      checkboxProfessionnel: true,
      checkboxParticulier: false,
      checkboxACDNon: false,
      checkboxACDOui: true,
    }
  },
  created() { 
    this.testUserType()
  },
  methods: {
    testUserType(){
      var typeUser = localStorage.getItem('typeUser')
      
      if(typeUser === 'part'){
        this.$router.push({ path: '/nouvelle-opportunite/elec-particuliers' })
      }
    },
    handleTypeContratButtonClick() {
      if (this.checkboxProfessionnel) {
        this.$router.push({ path: '/nouvelle-opportunite/demande-cotation' })
      }

      if (this.checkboxParticulier) {
        this.$router.push({ path: '/nouvelle-opportunite/elec-particuliers' })
      }
    },
    handlePrecedantClick() {
      this.typeContrat = null
    },
  },
}
</script>

<style>
label.v-label {
  width: 100%;
  white-space: nowrap;
  margin:0px;
}

.radio {
  width: 90%;
}
</style>
