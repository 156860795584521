<template>
  <div>
    <v-card class="ma-8 pa-0" elevation="2">
    <v-toolbar
        :color="$colors[0]"
        dark>
        <span class="Poppins-Bold pl-0">Electricité Particuliers</span>
    </v-toolbar>

      <FormeNouvelleProposition class="pa-4"
        @load-propositions="loadPropositions"
        @set-option-tariffaire="getOptionTarifaire"
      />
    </v-card>
    <v-card class="ma-8" v-if="status.success">
      <ListPropositions :propositions="propositions" :fta="optionTarifaire" />
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import Proposition from './Proposition.vue'
import ListPropositions from './ListPropositions.vue'
import FormeNouvelleProposition from './FormeNouvelleProposition.vue'

export default {
  name: 'ElecParticuliers',
  components: {
    FormeNouvelleProposition,
    Proposition,
    ListPropositions,
  },

  data() {
    return {
      status: {
        loading: false,
        success: false,
      },
      //variables authentification
      typeUser: null,
      isUserLogged: false,
      //parametrage list propositions
      propositions: [],
      optionTarifaire: 'MU',
    }
  },
  created() {
    const loggedUser = this.getToken('token')
    this.typeUser = localStorage.getItem('typeUser')
    //this.verifyApplicationDataSecurity()

    if (loggedUser) {
      this.isUserLogged = true
    } else {
      this.isUserLogged = false
      this.$router.push('/login')
    }
  },

  methods: {
    //verifie l'identité de l'utilisateur
    verifyApplicationDataSecurity() {
      var formData = new FormData()
      formData.append('type', localStorage.getItem('typeUser'))
      formData.append('vueAfficher', "part")

      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          'verifyApplicationDataSecurity.php',
        method: 'POST',
        data: formData,
        
      })
        .then((res) => {
          var data = JSON.parse(JSON.stringify(res.data))
          if (data[0].result === 'notLogin') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          } else if (data[0].result === 'accessDenied') {
            localStorage.setItem('vueAfficher', 'particulier')
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    //recoit les propositions en fonction de parametres saisis dans la forme
    loadPropositions(formData) {
      this.propositions = []
      

      this.status.success = false
      this.status.loading = true
       var donner = new FormData()
      donner.append('token', this.getToken('token'))
      donner.append('fta', formData.optionTarifaire)
      donner.append('kva', formData.puissanceSouscrite)

      if (formData.optionTarifaire === 'MU') {
        donner.append('mwhp', formData.consommationPleinesMU)
        donner.append('mwhc', formData.consommationCreusesMU)
      }else if (formData.optionTarifaire === 'CU'){
        donner.append('mwh', formData.consommationCU)
      }
      axios({
        url:process.env.VUE_APP_URL_API_CLIENT +
          'calculeCoutPart/',
        method: 'POST',
        data: donner,
      }).then((res) => {
          if (res.data.result === 'permission') {
            localStorage.removeItem('vendeurName')
            localStorage.removeItem('typeUser')
            localStorage.removeItem('token')
            this.$router.push('/login')
          } else if(res.data.result==true){
            this.status.success = true
            this.propositions = res.data.listDesProposition
          }else if(res.data.result==false){
            this.status.success = false
            alert('veuillez vérifier votre saisie')
          }
          this.status.loading = false
        })
    },
    //recupere la valeur d'option Tarifaire de la forme
    getOptionTarifaire(option) {
      this.propositions = []
      this.optionTarifaire = option
    },
    async envoyerNotification(message){
        var formData = new FormData()
        formData.append('msg', message)
        axios({
            url:'https://app.volto.fr/slackBotSendMessage/',
            method: 'POST',
            data: formData,
        }).then((res) => {
        })
    },
    
  },
}
</script>
<style scoped>
@media only screen and (max-width:350px){
  .page-title{
    font-size:1.75em;
  }
}
</style>
