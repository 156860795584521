<template>
  <section style="background-color: #eee">
    <div class="container-fluid pt-5" style="height: 100%">
      <div class="row">
        <div class="col-lg-12">
          <div class="card mb-4">
            <v-btn
              style="width: 250px; position: absolute; right: 310px; margin-top: 8px"
              @click="NavigateToCotationPage()"
              :color="$colors[0]"
              class="white--text"
              >Editer l'offre</v-btn
            >
            <ListeDesPropositions
              class="pt-8"
              v-if="cotation != null"
              :optionTarifaire="null"
              :parametreCompteur="null"
              :Propositions="Propositions"
              :SocieteData="null"
              :enedisData="null"
              :numCompteur="null"
              :turpeData="null"
              :taxeData="null"
              :typeCompteur="null"
              :fournisseurActuelle="null"
              :listCompteur="[]"
              :selected="selectedData"
              :donnerCompteur="null"
              :cotationObject="cotation.cotation"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import jQuery from "jquery";
import axios from "axios";
import ListeDesPropositions from "../../components/proposition-professionnels/newElecCalcul/newListePropositions.vue";
export default {
  components: {
    ListeDesPropositions,
  },
  name: "newHistoriqueCotation",
  data() {
    return {
      cotation: null,
      Propositions: [],
      selectedData: [],
    };
  },
  async created() {
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", whoIam);
    }
    this.getCotation();
  },
  methods: {
    NavigateToCotationPage() {
      let listCompteur = [];
      let siret = "";
      let idHisto = this.$route.query.id;
      let cotation = this.cotation.cotation;
      for (const [key, value] of Object.entries(cotation)) {
        listCompteur.push(key);
        if (siret.length == 0) {
          siret = value.dataSociete.siret;
        }
        this.$router.push({
          path: "/historiqueCotationElecPreparation",
          query: { siret: siret, idHistorique: idHisto, listCompteurSelected: listCompteur},
        });
      }
    },
    getCotation() {
      var formData = new FormData();
      formData.append("token", this.getToken("token"));
      formData.append("idCotation", this.$route.query.id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAncienCotation/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.cotation = res.data.cotation;
            for (let pdl of Object.keys(res.data.cotation.cotation)) {
              this.Propositions.push({
                NumCompteur: pdl,
                resultat: res.data.cotation.cotation[pdl],
              });
            }
            console.log(this.cotation);
          } else if (res.data.result == false) {
            alert("Il semble que la société n'existe pas");
          } else if (res.data.result == "permission") {
            alert("permission");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          alert("permission refusée");
          localStorage.removeItem("vendeurName");
          localStorage.removeItem("typeUser");
          localStorage.removeItem("token");
          this.$router.push("/login");
        });
    },
  },
};
</script>
