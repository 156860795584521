<template>
    <div>
        <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog == true">
                <dialogeModal
                    :error="error"
                    :dialog="dialog"
                    :loading="loading"
                    @closeDialog="dialog = false"
                    titre="Ajouter une vente professionnelle"
                    :messageDialogeSucces="this.messageDialogeSucces"
                    :messageDialogeError="this.messageDialogeError" />
            </v-col>
        </v-row>
        <v-form ref="form" lazy-validation>
            <v-row class="mx-2">
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-row>
                        <v-col class="mt-2" cols="12" sm="12" md="12" lg="12" xl="12">
                            <span style='font-weight:bold;font-size:medium'>N° Compteur selectionné: <small>{{vente.numCompteur}}</small></span>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" md="6" lg="6" xl="6">
                            <v-autocomplete 
                                v-model="selectedCompteur" 
                                :items="listCompteur" 
                                :loading="isLoading" 
                                hide-no-data 
                                hide-selected
                                label="Autre compteur" 
                                placeholder="Ajouter un compteur" 
                                prepend-icon="mdi-account-search-outline"
                                chips

                                deletable-chips
                                multiple
                                >
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </template>
                                <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggle">
                                        <v-list-item-content>
                                            <v-list-item-title>Select All</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <v-col cols="12" sm="4" md="3" lg="3" xl="3" v-if="selectedCompteur.length>0">
                                <v-btn class="text-white" :color="$colors[0]"  @click="appelleEnedis" >Récupérer la consommation</v-btn>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-row style="max-height: 300px; overflow:auto;">
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="listCompteurResult.length>0 && typeVente=='GAZ'">
                            <b-table :busy="loadingTableHistorique"  :fields="fieldhistoriqueDeCotationGAZ" :items="listCompteurResult" responsive bordered>
                                <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <template #cell(numCompteur)='data'>
                                    <small>{{data.item.numCompteur}}</small>
                                </template>
                                <template #cell(conso)='data'>
                                    <v-text-field dense outlined type="number" step="0.01" label="Consommation (Mwh)"  @input="calculeValue(data.item.numCompteur)" v-model="data.item.conso"></v-text-field>
                                </template>
                                <template #cell(dateDebut)="data">
                                    <v-text-field dense type="date" outlined label="Début de contrat" v-model="data.item.dateDebut"></v-text-field>
                                </template>
                                <template #cell(marge)='data'>
                                    <v-text-field dense outlined type="number" step="0.01" label="marge (€/Mwh)" @input="calculeValue(data.item.numCompteur)" v-model="data.item.marge"></v-text-field>
                                </template>
                                <template #cell(durree)='data'>
                                    <v-text-field dense outlined type="number" step="1" label="durree (Mois)" @input="calculeValue(data.item.numCompteur)" v-model="data.item.durree"></v-text-field>
                                </template>
                                <template #cell(valeur)="data">
                                    <v-text-field dense type="number" step="0.01" outlined label="Valeur" v-model="data.item.valeur"></v-text-field>
                                </template>
                                <template #cell(action)="data">
                                    <v-btn @click="supprimerCompteur(data.item.numCompteur)">Supprimer</v-btn>
                                </template>
                            </b-table>       
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="listCompteurResult.length>0 && typeVente=='ELEC'">
                            <b-table :busy="loadingTableHistorique"  :fields="fieldhistoriqueDeCotationELEC" :items="listCompteurResult" responsive bordered>
                                <template #table-busy>
                                        <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Chargement...</strong>
                                    </div>
                                </template>
                                <template #cell(numCompteur)='data'>
                                    <small>{{data.item.numCompteur}}</small>
                                </template>
                                <template #cell(conso)='data'>
                                    <v-text-field dense outlined type="number" step="0.01" label="Consommation (Mwh)" @input="calculeValue(data.item.numCompteur)" v-model="data.item.conso"></v-text-field>
                                </template>
                                <template #cell(dateDebut)="data">
                                    <v-text-field dense type="date" outlined label="Début de contrat" v-model="data.item.dateDebut"></v-text-field>
                                </template>
                                <template #cell(marge)='data'>
                                    <v-text-field dense outlined type="number" step="0.01" label="marge (€/Mwh)" @input="calculeValue(data.item.numCompteur)" v-model="data.item.marge"></v-text-field>
                                </template>
                                <template #cell(durree)='data'>
                                    <v-text-field dense outlined type="number" step="1" label="durree (Mois)" @input="calculeValue(data.item.numCompteur)" v-model="data.item.durree"></v-text-field>
                                </template>
                                <template #cell(valeur)="data">
                                    <v-text-field dense type="number" step="0.01" outlined label="Valeur" v-model="data.item.valeur"></v-text-field>
                                </template>
                                <template #cell(action)="data">
                                    <v-btn @click="supprimerCompteur(data.item.numCompteur)">Supprimer</v-btn>
                                </template>
                            </b-table>       
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div>
                        <p class="mt-2" style='font-weight:bold;font-size:medium' data-toggle="collapse" :data-target="'#multiCollapseScoring'" aria-expanded="true" :aria-controls="'multiCollapseScoring'">
                            Données pré-remplies<v-icon right >  mdi-chevron-down</v-icon>
                        </p>
                        
                        <div class="collapse show multi-collapse" :id="'multiCollapseScoring'">
                            <v-row>
                                <v-col  cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('une PDL valide')]"
                                        v-model="vente.numCompteur"
                                        label="PDL"
                                    ></v-text-field> 
                                </v-col>
                                <v-col  cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('une raison valide')]"
                                        v-model="vente.raison"
                                        label="Raison Sociale"
                                    ></v-text-field> 
                                </v-col>
                                <v-col  cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('un siret valide')]"
                                        v-model="vente.siret"
                                        label="Siret"
                                    ></v-text-field> 
                                </v-col>
                                <v-col  cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('un nom valide'),rules.isString]"
                                        v-model="vente.info.nom"
                                        label="Nom"
                                    ></v-text-field> 
                                </v-col>
                                <v-col  cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('un prénom valide'),rules.isString]"
                                        v-model="vente.info.prenom"
                                        label="Prenom"
                                    ></v-text-field> 
                                </v-col>
                                <v-col  cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('un email valide'),rules.emailRules]"
                                        v-model="vente.info.email"
                                        label="Email"
                                    ></v-text-field> 
                                </v-col>
                                <v-col  cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('un numéro de téléphone valide'),rules.phoneisNumber]"
                                        v-model="vente.info.num_tel"
                                        label="Numéro de téléphone"
                                    ></v-text-field> 
                                </v-col>
                                
                                <v-col cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        type="number"
                                        step="0.01"
                                        :rules="[required('une CAR valide'),rules.value]"
                                        v-model="vente.consommation"
                                        @input="calculeValue(-1)"
                                        label="CAR"
                                    ></v-text-field> 
                                </v-col>
                                <v-col cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('une marge valide'),rules.margeMaxMin]"
                                        type="number"
                                        step="0.01"
                                        v-model="vente.marge"
                                        @input="calculeValue(-1)"
                                        label="Marge"
                                    ></v-text-field> 
                                </v-col>
                                <v-col cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field 
                                        type="date" 
                                        outlined
                                        :rules="[required('une date de début de contrat valide'), isValidDate('DD/MM/YYYY', minDebutContrat)]"
                                        label="Début de contrat"
                                        v-model="vente.dateDF"
                                        :min="minDebutContrat"/>
                                </v-col>
                                <v-col cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('une durée de contrat valide'), rules.value]"
                                        type="number"
                                        step="0.01"
                                        @input="calculeValue(-1)"
                                        v-model="vente.duree"
                                        label="Durée de contrat"
                                        suffix="mois"
                                    ></v-text-field> 
                                </v-col>
                                <v-col cols="12"
                                    sm="6"
                                    md="3"
                                    lg="3"
                                    xl="3">
                                    <v-text-field
                                        outlined
                                        :rules="[required('une valeur valide')]"
                                        type="number"
                                        step="0.001"
                                        v-model="vente.valeur"
                                        label="Valeur"
                                        suffix="€"
                                    ></v-text-field> 
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                    
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div>
                        <p style='font-weight:bold;font-size:medium'>Données relatives au fournisseur</p>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                                lg="6"
                                xl="6"
                                >
                                    <v-autocomplete
                                        outlined
                                        ref="compteur"
                                        :rules="[required('un Fournisseur valide')]"
                                        :items="listeFournisseursElec"
                                        v-model="vente.fournisseur"
                                        @change="choisieFournisseur"
                                        label="Fournisseur"
                                    ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-row>
                        <v-col cols="12" md="12" lg="12" xl="12"  v-if="vente.fournisseur=='Ekwateur'">
                            <v-card>
                                <v-toolbar
                                    :color="$colors[0]"
                                    dark>
                                    <span class="text-h6 pl-0">Informations d'Annexe 5</span>
                                </v-toolbar>
                                <v-row class='mx-2 mt-4'>
                                    <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                                        <v-text-field
                                            outlined
                                            :rules="[required('un IBAN valide')]"
                                            v-model="infoAnnexe.IBAN"
                                            label="IBAN">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                                        <v-text-field
                                            outlined
                                            :rules="[required('un nom de banque valide')]"
                                            v-model="infoAnnexe.bank_name"
                                            label="Nom de la banque">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="4" xl="4">
                                        <v-text-field
                                            outlined
                                            :rules="[required('un BIC valide')]"
                                            v-model="infoAnnexe.bic"
                                            label="BIC">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                        <v-btn :color='$colors[0]' class="white--text" @click="getBankName">
                                        obtenir infos banque<v-icon dense class="ml-2">mdi-bank</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- input file -->
                <inputFilePDF class='mt-12' :numberOfDuplicate="fileTypes.length" v-if="vente.fournisseur!=null && vente.fournisseur!=''" 
                @clearFileList="clearFileList"  @removeSplitPDF="removeSplitPDF" @sendPDFSplit="receivePDFSplit" :filePossible="fileTypes"/>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12"  v-if="vente.fournisseur!=null && vente.fournisseur!=''">
                    <v-row>
                        <v-col class="" cols="10" sm="5" md="4" lg="3" v-for="index in fileTypes" :key="index">
                            <div class="flex w-full h-screen items-center justify-center text-center">
                                <div class="p-1 bg-gray-100 border border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                                    <h4>{{index}}</h4>
                                    <input type="file" style="display: none;" :name="index" :id="index" 
                                    class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange(index, null)" :ref="index" accept=".pdf,.eml,.msg" />

                                    <label :for="index" class="block cursor-pointer">
                                        <div :class="fileRequired?'requireddropzone':'dropzone'">
                                        <v-icon x-large>cloud_upload </v-icon>
                                            <p>
                                                Déposez votre fichier ici <span><br>(ou)</span>
                                            </p>
                                            <p style="background-color: #e2e2e2;">Séléctionnez le fichier à importer</p>
                                            <p>Types de fichier supportées: pdf, eml</p>
                                        </div>
                                    </label>
                                    
                                    <ul class="mt-4" v-if="filelist[index]" v-cloak>
                                        <li class="text-sm p-1" v-if="filelist[index][0] && filelist[index]!=='undefined'" >
                                            <a :href="filelist[index][0].url" target="_blank">{{filelist[index][0].name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- commentaire -->
                <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mt-16" align="center" justify="center">
                    <v-row >
                        <v-col cols="12" md="12" lg="12">
                        <v-textarea
                            auto-grow
                            solo
                            name="comment"
                            label="Commentaire"
                            v-model="vente.comment"
                        ></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
                <!-- button -->
                <v-row align="center" justify="end">
                    <!-- Validation forme -->
                          
                                <v-btn
                                large
                                text
                                color="red"
                                @click="$emit('closeDialog')">Fermer</v-btn>
                            
                            <v-btn
                                depressed
                                elevation="1"
                                large
                                :color="$colors[0]" 
                                class="text-white"
                                :loading="false"
                                @click="ajouterVente"
                            >
                                Envoyer
                            </v-btn>
                </v-row>
            </v-row>
        </v-form>
        </div>
</template>
<script>
    import moment from 'moment'
    import axios from 'axios'
    import dialogeModal from '../../components/ui/dialogeModal.vue'
    import inputFilePDF from '../ventePro/inputFilePDF'
    export default {
        name: "ajouterVenteElecComposent",
        props:{'dataVente':'', 'societe':'', 'typeVente':'', 'pourcentage':'', 'listCompteur':[]},
        components:{dialogeModal,inputFilePDF},
        data() {
            return{
                isLoading: false,
                valGlobale: 0,
                loadingTableHistorique: false,
                fieldhistoriqueDeCotationELEC:[
                    {key:"numCompteur", label:"Numéro de compteur"},
                    {key:"conso",label:"Consommation en (Mwh)"},
                    {key:"dateDebut", label:"Début de debut"},
                    {key: "durree", label:"Durée"},
                    {key:"marge", label:"marge"},
                    {key:"valeur", label:"Valeur"},
                    {key:"action",label:"Action"}
                ],
                fieldhistoriqueDeCotationGAZ:[
                    {key:"numCompteur", label:"Numéro de compteur"},
                    {key:"conso",label:"Consommation en (Mwh)"},
                    {key:"dateDebut", label:"Début de debut"},
                    {key: "durree", label:"Durée"},
                    {key:"marge", label:"marge"},
                    {key:"valeur", label:"Valeur"},
                    {key:"action",label:"Action"}
                ],
                dialog: false,
                error: false,
                title: '',
                loading: false,
                selectedCompteur: [],
                listCompteurResult:[],
                loading: false,
                dialog: false,
                loading: false,
                error: false,
                messageDialogeError: '',
                messageDialogeSucces:'',
                infoAnnexe:{
                    nomDeSiteSurFacture: "",
                    puissance:"",
                    Nvoie:"",
                    libelleVoie: "",
                    codePostal: "",
                    commune: "",
                    bank_name: "",
                    bic: "",
                    IBAN: "",
                },
                listeFournisseursElec:[],
                fileTypes: [],
                typeFile: '',
                vente:{
                    siret:'',
                    adressePostale: '',
                    codeNaf: '',
                    codeInsee: '',
                    raison: '',
                    numCompteur: '',
                    fournisseur: '',
                    valeur: "",
                    consommation: '',
                    fonction: 'gérant',
                    marge: 0,
                    duree: 0,
                    dateDF: null,
                    comment: '',
                    info: {
                        nom: '',
                        prenom: '',
                        num_tel: '',
                        email: '',
                    },
                },
                fileRequired: false,
                filelist: {
                    'CGV': [],
                    'IBAN': [],
                    'contrat': [],
                    'RIB': [],
                    'facture': [],
                    'SEPA': [],
                },
                chargerToutFichier: true,
                //les rules
                required(fieldName) {
                    return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
                rules: {
                    isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
                    
                    isSiren: (v) => (v.length == 9 && v.match(/^[0-9]+$/)) != null|| 'Le siren doit être un nombre de 9 chiffres',        
                    required: (value) => !!value || 'Ce champ est obligatoire.',
                    value: (val) => val>=0 || "Ce champ doit être supérieur à zéro",

                    sirenLength: (v) =>
                    v.length == 9 || 'Le siren doit comporter 9 chiffres',
                    isNumber: (v) => (""+v).match(/^[0-9]+$/) != null || 'Ce champ doit être constitué uniquement de chiffres',
                    compteurElecLength: (v) =>
                    v.length == 14 || "Le RAE / PDL est une suite de 14 chiffres",
                    isGazCompteur: (v) =>
                    v.match(/^(GI)[0-9]{6}$/) ||
                    (v.length == 14 && v.match(/^[0-9]+$/) != null) ||
                    "Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres",
                    phoneLength: (v) =>
                    v.length == 10 ||
                    'Le numéro de téléphone est une suite de 10 chiffres',
                    phoneisNumber: (v) =>
                    v.match(/^[0-9]+$/) != null || 'Le numéro de téléphone est invalide',
                    margeMaxMin: (v) =>
                    (v <= 25 && v >= 2) || "La marge doit être comprise entre 2 et 25",

                    emailRules: (v) => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || "l'adresse mail est invalide",
                },   
                minDebutContrat: new Date().toISOString().substr(0, 10),   
                verifyLength() {
                    return (
                    this.numSiret.length == 14 || 'Le siret doit comporter 14 chiffres.'
                    )
                },
                siretAcceptNumber() {
                    return this.numSiret.match(/^[0-9]+$/) != null || 'Ce champ doit être constitué uniquement de nombres'
                },
                siretInList() {
                    return (
                    this.toutLesSiret.indexOf(this.numSiret) != -1 ||
                    'Choisissez un siret à partir de la liste'
                    )
                },
            }
        },
        watch:{
            dataVente(val) {
                this.clearFileList();
                this.getListeFileAndFournisseur()
                this.vente.siret=this.societe.siret
                this.vente.adressePostale=this.societe.AdressePostal
                this.vente.codeNaf=this.societe.codeNaf
                this.vente.codeInsee=this.societe.codeInsee
                this.vente.raison=this.societe.Raison
                if(this.societe.fonction!=null){
                    if(this.societe.fonction.length>=3 ){
                        this.vente.fonction=this.societe.fonction
                    }
                }
                this.vente.numCompteur=this.dataVente.numCompteur
                if(this.typeVente=="ELEC"){
                    this.vente.consommation = this.dataVente.CAR
                }else{
                    this.vente.consommation = this.dataVente.consommation

                }
                this.vente.marge=this.dataVente.marge
                try{
                    this.vente.dateDF=this.dateFormatMinus(this.dataVente.dateDebut)
                }catch(errr){
                    this.vente.dateDF=this.dataVente.dateDebut
                }
                this.vente.duree=this.calculeNbrMonth(this.dataVente.dateDebut, this.dataVente.dateFin)
                this.vente.info.nom=this.societe.nom
                this.vente.info.prenom=this.societe.prenom
                this.vente.info.num_tel=this.societe.numTele
                this.vente.info.email=this.societe.AdresseEmail
                this.vente.valeur = (this.vente.consommation * this.vente.marge)*(this.vente.duree/12)*(this.pourcentage/100)
            },
            selectedCompteur(newVal, oldVal){
                if(newVal.length != oldVal.length){
                    var index = -1
                    for (var i = 0; this.listCompteurResult.length; i++){
                        index = newVal.findIndex(x => x === this.listCompteurResult[i].numCompteur)
                        if (index == -1) { 
                            this.listCompteurResult.splice(i, 1);
                            break
                        }
                    }
                }
            },
            listCompteurResult(val){
                for(var i=0; i<this.listCompteurResult.length; i++){
                    try{
                        this.listCompteurResult[i]['valeur'] = (this.listCompteurResult[i]['conso'] * this.listCompteurResult[i]['marge'])*(this.listCompteurResult[i]['durree']/12)*(this.pourcentage/100)
                        break;
                    }catch(err){
                        this.listCompteurResult[i]['valeur'] =0
                    }
                }
            }
        },
        async created() {
            this.clearFileList();
            this.getListeFileAndFournisseur()
            this.vente.siret=this.societe.siret
            this.vente.adressePostale=this.societe.AdressePostal
            this.vente.codeNaf=this.societe.codeNaf
            if(this.societe.fonction!=null){
                if(this.societe.fonction.length>=3 ){
                    this.vente.fonction=this.societe.fonction
                }
            }
            this.vente.codeInsee=this.societe.codeInsee
            this.vente.raison=this.societe.Raison
            if(this.typeVente=="ELEC"){
                this.vente.consommation = this.dataVente.CAR
            }else{
                this.vente.consommation = this.dataVente.consommation

            }
            this.vente.numCompteur=this.dataVente.numCompteur
            this.vente.marge=this.dataVente.marge
            try{
                this.vente.dateDF=this.dateFormatMinus(this.dataVente.dateDebut)
            }catch(errr){
                this.vente.dateDF=this.dataVente.dateDebut
            }
            this.vente.duree=this.calculeNbrMonth(this.dataVente.dateDebut, this.dataVente.dateFin)
            this.vente.info.nom=this.societe.nom
            this.vente.info.prenom=this.societe.prenom
            this.vente.info.num_tel=this.societe.numTele
            this.vente.info.email=this.societe.AdresseEmail
            this.vente.valeur = (this.vente.consommation * this.vente.marge)*(this.vente.duree/12)*(this.pourcentage/100)
            
            var index = this.listCompteur.findIndex(x => x === this.numCompteur)
            if (index > -1) { 
                this.listCompteur.splice(index, 1);
            }
        },
        methods:{
            calculeConsommationGlobale(){
                this.consommationGlobale = this.vente.consommation
                for(var i=0; i<this.listCompteurResult.length; i++){
                    this.consommationGlobale = this.consommationGlobale + this.listCompteurResult[i]['conso']
                }
            },
            async appelleEnedis(){
                this.loadingTableHistorique = true;
                await this.getConsoAllCompteur(this.typeVente)
                this.loadingTableHistorique = false;
            },
            async getConsoAllCompteur(typeEnergie){
                if(typeEnergie == "GAZ"){
                    this.dialog=true
                    this.loading=true
                    this.title = "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!"
                    this.messageDialoge="veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!"
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('listCompteur', this.selectedCompteur)
                    formData.append('dateDebut', this.dataVente.dateDebut)
                    formData.append('dateFin', this.dataVente.dateFin)

                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'getAllCompteurConsoGaz/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        this.listCompteurResult = res.data.result
                        this.dialog=false
                    }).catch((err)=>{
                        this.listCompteurResult=[]
                        this.error = true
                        this.messageDialogeError = "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!"
                    })
                }else if(typeEnergie == "ELEC"){
                    this.dialog=true
                    this.loading=true
                    this.title = "veuillez patienter jusqu'à ce que nous avons obtenu les informations relatives aux numéros des compteurs!"
                    this.messageDialoge="veuillez patienter jusqu'à ce que nous ayons obtenu les informations relatives aux numéros des compteurs!"
                    var formData = new FormData()
                    formData.append('token', this.getToken('token'))
                    formData.append('listCompteur', this.selectedCompteur)
                    formData.append('dateDebut', this.dataVente.dateDebut)
                    formData.append('dateFin', this.dataVente.dateFin)
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'getAllCompteurConso/',
                        method: 'POST',
                        data: formData,
                    }).then((res) => {
                        this.listCompteurResult = res.data.result
                        this.dialog=false
                    }).catch((err)=>{
                        this.listCompteurResult=[]
                        this.error = true
                        this.messageDialogeError = "nous n'arrivons pas à obtenir les informations relatives à tous les numéros de compteur présents dans votre Demande de cotation!"
                    })
                }
            },
            toggle(){
                this.selectedCompteur = [...this.listCompteur]
            },
            supprimerCompteur(numComp){
                var index = this.selectedCompteur.findIndex(x => x === numComp)
                if (index > -1) { 
                    this.selectedCompteur.splice(index, 1);
                }
                index = this.listCompteurResult.findIndex(x => x.numCompteur === numComp)
                if (index > -1) { 
                    this.listCompteurResult.splice(index, 1);
                }
                index = this.listCompteur.findIndex(x => x === numComp)
                if(index == -1){
                    this.listCompteur.push(numComp)
                }
            },
            calculeValue(numCompt = -1){
                if(numCompt == -1){
                    this.vente.valeur = (this.vente.consommation * this.vente.marge)*(this.vente.duree/12)*(this.pourcentage/100)
                }else{
                    for(var i=0; i<this.listCompteurResult.length; i++){
                        if(this.listCompteurResult[i]['numCompteur'] == numCompt){
                            try{
                                this.listCompteurResult[i]['valeur'] = (this.listCompteurResult[i]['conso'] * this.listCompteurResult[i]['marge'])*
                                    (this.listCompteurResult[i]['durree']/12)*(this.pourcentage/100)
                                break;
                            }catch(err){
                                this.listCompteurResult[i]['valeur'] =0
                            }
                        }
                    }
                }

            },
            dateFormatSlach(date){
                return date.split('-').reverse().join('/')
            },
            dateFormatMinus(date){
                return date.split('/').reverse().join('-')
            },
            isValidDate(formatDate, minDate) {
                return (date) => {
                    console.log(date)
                    var tmpdate = this.dateFormatMinus(date)
                    if (!date) {
                        return 'Veuillez saisir une date.'
                    }
                    if (moment(tmpdate).isBefore(minDate)) {
                        return `La date doit être supérieure  au ${this.dateFormatSlach(minDate)}.`
                    }
                    return true
                }
            },
            ajouterVente(){
                let self = this
                this.$refs.form.validate()
                if (this.$refs.form.validate()){
                    self.dialog = true
                    self.loading = true
                    self.error = false
                    self.messageDialogeError = ''
                    self.messageDialogeSucces = ''
                    var formData = new FormData()
                    formData.append('infoAnnexe', JSON.stringify(this.infoAnnexe))
                    formData.append('siret', this.vente.siret)
                    formData.append('type', this.typeVente)
                    formData.append('nom', this.vente.info.nom)
                    formData.append('prenom', this.vente.info.prenom)
                    formData.append('email', this.vente.info.email)
                    formData.append('numTele', this.vente.info.num_tel)
                    formData.append('numCompteur', this.vente.numCompteur)
                    formData.append('raison', this.vente.raison)
                    formData.append('fournisseur', this.vente.fournisseur)
                    formData.append('valeur', this.vente.valeur)
                    formData.append('status', 'en Attend')
                    formData.append('commentaire', this.vente.comment)
                    formData.append('duree', this.vente.duree)
                    formData.append('dateDF', this.vente.dateDF)
                    formData.append('consommation', this.vente.consommation)
                    formData.append('marge', this.vente.marge)
                    formData.append('fonction', this.vente.fonction)
                    formData.append('listeDesAutreVente', JSON.stringify(this.listCompteurResult))
                    formData.append('listeToutesVente', JSON.stringify(this.listCompteurResult));
                    formData.append('token', this.getToken('token'))
                    for(var i =0; i< this.fileTypes.length; i++){
                        formData.append(this.fileTypes[i], this.filelist[this.fileTypes[i]][0])
                    }
                    axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'ajouterVenetePro/',
                        method: 'POST',
                        data: formData,
                        
                    }).then((res) => {
                        if(res.data.result == true){
                            self.loading = false
                            self.error = false
                            self.messageDialogeSucces = 'Vente ajoutée avec succès !'
                        }else if (res.data.result == "compte"){
                            self.loading = false
                            self.error = true
                            self.messageDialogeError = "Le numéro de compteur n'est pas rattaché à votre compte"
                        }else if (res.data.result == "DC"){
                            self.loading = false
                            self.error = true
                            self.messageDialogeError = "Le numéro de compteur n'est associé à aucune demande de cotation"
                        }else if (res.data.result == false){
                            self.loading = false
                            self.error = true
                            self.messageDialogeError = "Ajout de vente impossible ! Société introuvable ou non rattachée à votre compte"
                        }else if (res.data.result == "societe"){
                            self.loading = false
                            self.error = true
                            self.messageDialogeError = "Il semble qu'il y ait un problème. Veuillez contacter un administrateur"
                        }else if (res.data.result == 'IBAN'){
                            self.loading = false
                            self.error = true
                            self.messageDialogeError = "Vérifier le numéro IBAN!"
                        }else if (res.data.result == 'Error'){
                            self.loading = false
                            self.error = true
                            self.messageDialogeError = "la clé de L'Api qui vérifie l'IBAN a expiré!"
                        }else if(res.data.result == "permission"){
                            self.loading = false
                            self.error = true
                            self.messageDialogeError = 'Permission refusée'
                        }
                    }).catch((err)=>{
                        self.dialog = false
                        self.loading = false
                        self.error = false
                        self.messageDialogeSucces = ''
                        self.messageDialogeError = ''
                    })
                    
                }
            },
            calculeNbrMonth(d1, d2) {
                var d1 = new Date(d1.split('/').reverse().join('-'))
                var d2 = new Date(d2.split('/').reverse().join('-'))
                var months;
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth();
                months += d2.getMonth();
                var day1 = d1.getDate()
                var day2 = d2.getDate()
                if (day2 - day1 > 0){
                    months+=1
                }
                return months <= 0 ? 0 : months;
            },
            renitailise(){
                Object.entries(this.vente).forEach(element => {
                    if(element[0] != "siret" && element[0] != "numCompteur" && element[0] != "raison" && element[0] != "info"){
                        this.vente[element[0]] = ""
                    }
                    if(element[0] == 'info'){
                            Object.entries(this.vente.info).forEach(element=>{
                                this.vente.info[element[0]] = ""
                            })
                        }
                });
                this.$refs.form.resetValidation();
            },
            clearFileList(){
                for(const [key,value] of Object.entries(this.filelist)){
                    this.filelist[key] = []
                }
            },
            removeSplitPDF(filePDF){
                for(const [key,value] of Object.entries(this.filelist)){
                    if(value[0] != null && value[0].name == filePDF.name){
                        this.remove(key)
                    }
                }
            },
            receivePDFSplit({listFilePDF, listeIndex}){ 
                for(var j =0; j< this.fileTypes.length; j++){
                    var index = listeIndex.findIndex(x => x === j)
                    if (index == -1){
                    this.onChange(this.fileTypes[j],null)
                    }
                }
                for(var i =0; i< listeIndex.length; i++){
                    this.onChange(this.fileTypes[listeIndex[i]],[listFilePDF[i]])
                }
            
            },
            getBankName(){
                if (this.infoAnnexe.IBAN != "" || this.infoAnnexe.IBAN != null){
                    var formDonne = new FormData()
                    formDonne.append('iban', this.infoAnnexe.IBAN)
                    axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'getBankName/',
                        method: 'POST',
                        data: formDonne,
                        
                    }).then((res) => {
                        if(res.data.result==true){
                        this.infoAnnexe.bank_name = res.data.bank_name
                        this.infoAnnexe.bic = res.data.bic
                        }else{
                        this.infoAnnexe.bank_name = ""
                        this.infoAnnexe.bic = ""
                        alert("Impossible de trouver le nom de la banque à partir de vos informations, essayez de la saisir manuellement s'il vous plaît")
                        }
                    }).catch((err)=>{
                        this.infoAnnexe.bank_name = ""
                        this.infoAnnexe.bic = ""
                        alert("Impossible de trouver le nom de la banque à partir de vos informations, essayez de la saisir manuellement s'il vous plaît")
                        console.log(err)
                    })
                }else{
                    return false;
                }
            },
            
            choisieFournisseur(){
                this.fileTypes = this.lesFilesPossible[this.vente.fournisseur]
                this.typeFile = this.fileTypes[0]
                for(var i = 0; i< this.fileTypes.length; i++){
                    this.filelist[this.fileTypes[i]]=[]
                }
            },
            getListeFileAndFournisseur(){
                var formData1 = new FormData()
                formData1.append('type', this.typeVente)
                  axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getFournisseurAndFiles/',
                    method: 'POST',
                    data: formData1,
                    
                }).then((res)=>{
                    this.listeFournisseursElec = res.data.fournisseur
                    this.lesFilesPossible = res.data.files
                    
                }).catch((err)=>{

                })
            },
            verifierQueToutLesFichierCharger(){
                for(var i =0; i< this.fileTypes.length; i++){
                    if(this.filelist[this.fileTypes[i]].length==0){
                        return false
                    }
                }
                return true
            },
            onChange(file, data) {
                if(data==null){
                    this.filelist[file] = this.$refs[file][0].files
                }else{
                    let blobPDF = new Blob([data[0]], { type: 'application/pdf' });
                    data[0].url = window.URL.createObjectURL(blobPDF)
                    this.filelist[file] = data;
                }
            },

            //files
            remove(file) {
                this.filelist[file]= []
            },
            dragover(event) {
                event.preventDefault();
                // Add some visual fluff to show the user can drop its files
                if (!event.currentTarget.classList.contains('bg-green-300')) {
                    event.currentTarget.classList.remove('bg-gray-100');
                    event.currentTarget.classList.add('bg-green-300');
                }
            },
            dragleave(event) {
                // Clean up
                event.currentTarget.classList.add('bg-gray-100');
                event.currentTarget.classList.remove('bg-green-300');
            },
            drop(event) {
                let self = this
                event.preventDefault();
                var nameInput = event.currentTarget.querySelector('input').name
                if(event.dataTransfer.files.length>1){
                    self.$refs.file.files=null;
                }else{
                    var posExtension = event.dataTransfer.files[0].name.split('.').length-1;
                    var extension = event.dataTransfer.files[0].name.split('.')[posExtension];
                    if(extension != 'pdf' && extension != 'PDF' && extension != 'EML' && extension != 'eml'  && extension != 'MSG' && extension != 'msg'){
                    self.$refs[nameInput].files=null;
                    }else{
                    
                    self.$refs[nameInput].files = event.dataTransfer.files;
                        self.onChange(nameInput, event.dataTransfer.files); // Trigger the onChange event manually
                        // Clean up
                        event.currentTarget.classList.add('bg-gray-100');
                        event.currentTarget.classList.remove('bg-green-300');
                    }
                    
                }
            },
        },
    }
</script>