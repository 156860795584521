<template>
  <v-dialog v-model="isOpen" persistent width="500">
    <v-card elevation="0" class="pa-4" style="width: 100%">
      <div>
        <div class="d-flex justify-space-between flex-row">
          <span class="font-weight-bold align-self-center" style="color: #103a5e"
            >{{ title }}</span
          >
          <div class="pa-1" style="border: 2px solid #103a5e; border-radius: 10px">
            <v-icon :color="$colors[0]">{{ icon }}</v-icon>
          </div>
        </div>
        <div>
          <span style="font-size: 40px" class="font-weight-bold"
            >{{ progress }} / {{ total }}</span
          >
        </div>
      </div>

      <div class="d-flex flex-row justify-end">
        <v-btn @click="closeDialog()" color="error" text> Fermer </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: ['isOpen', 'title', 'icon', 'progress','total'],
  methods: {
    closeDialog() {
      // emit close dialog
      this.$emit('close');
    }
  },
}
</script>