<template>
    <div style="background-color: #eee">
        <v-card style="overflow: hidden;">
            <v-toolbar :color="$colors[0]" dark>
                <span class="text-h6 text-white">Generer courbe de charge</span>
            </v-toolbar>
            <v-row>
                <div>
                    <loadingComponent :dialogProps="dialog" :messageDialogeProps="messageDialoge"
                        :loadingProps='loading' :errorProps="error" @closeDialog="dialog = $event" />
                </div>
            </v-row>
            <v-card-text class="my-6">
                <v-form ref="formAcd" lazy-validation>
                    <v-text-field :rules="[rules.required,rules.dateRange]" type="date" v-model="dateFin" label="Date Fin" outlined />

                    <v-autocomplete v-model="selectedCompteur" :items="listCompteur" hide-no-data
                        :rules="[rules.required]" label="Autre compteur" placeholder="Ajouter un compteur"
                        prepend-icon="mdi-account-search-outline" chips item-text="numCompteur" deletable-chips multiple
                        return-object>
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.numCompteur }}</v-list-item-title>
                                    <v-list-item-subtitle>{{
                                        data.item.typeDemande
                                        }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list-item ripple @click="toggle">
                                <v-list-item-content>
                                    <v-list-item-title>Select All</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-autocomplete>
                    <p v-if="this.isGenerated" class="body-1 my-4" style="font-size: 18px; color: #4CAF50;">
                        La courbe de charge est en cours de création. Vous recevrez un email une fois le processus
                        terminé.
                    </p>
                    <div class="d-flex justify-content-end">

                        <v-btn :color="$colors[0]" class="mt-8 mr-4 text-white" @click="genererCourbeDeCharge">
                            Valider
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>
  <script>
import axios from 'axios';

import loadingComponent from "../../../../components/ui/loading-component.vue"

export default {
    name: "CreationAcdEngie",
    props: { listCompteur: [], societe: "" },
    components: {
        loadingComponent
    },
    data() {
        return {
            selectedCompteur: [],
            isGenerated: false,
            dateFin: this.getYesterdayDate(),
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "L'e-mail a été envoyé avec succès",
            rules: {
                required: value => (!!value && ("" + value).length > 0) || 'Ce champ est obligatoire.',
                requiredString: value => ("" + value).length > 8 || 'Ce champ est obligatoire.',
                siretLength: v => v.length == 14 || 'Le siret doit comporter 14 chiffres',
                isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                compteurElecLength: v => v.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
                isGazCompteur: v => (v.match(/^(GI)[0-9]{6}$/) || (v.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
                phoneLength: v => v.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
                phoneisNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
                margeMaxMin: v => (v <= 25 && v >= 2) || 'La marge doit être comprise entre 2 et 25',

                emailRules: [
                    v => !!v || 'E-mail obligatoire',
                    v => /.+@.+\.+.+/.test(v) || "l'adresse mail est invalide",
                ],
                dateRange: value => {
                    const selectedDate = new Date(value);
                    const yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);

                    const oneYearAgo = new Date();
                    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

                    if (selectedDate > yesterday) {
                        return 'La date ne peut pas être supérieure à la date d\'hier.';
                    } else if (selectedDate < oneYearAgo) {
                        return 'La date ne peut pas être inférieure à un an.';
                    }
                    return true;
                }
            },
            required(fieldName) {
                return (value) => ((!!value || value >= 0) || (!!value && value != '')) || `Veuillez saisir ${fieldName}`
            },
            consoMaxMin(typeCompteur) {
                return (v) => {
                    if (typeCompteur == "T1") {
                        if (v >= 0 && v < 6) {
                            return true;
                        } else {
                            return "consomation pour un Tarif T1 doit être comprise entre 0 et 5.99";
                        }
                    } else if (typeCompteur == "T2") {
                        if (v >= 3 && v <= 350) {
                            return true;
                        } else {
                            return "consomation pour un Tarif T2 doit être comprise entre 3 et 350";
                        }
                    } else if (typeCompteur == "T3") {
                        if (v >= 100 && v < 5000) {
                            return true;
                        } else {
                            //250 4999.99
                            return "consomation pour un Tarif T3 doit être comprise entre 100 et 4999.99";
                        }
                    } else if (typeCompteur == "T4") {
                        if (v >= 5000) {
                            return true;
                        } else {
                            return "consomation pour un Tarif T4 doit être supérieur ou égal à 5000";
                        }
                    }
                };
            },
        }
    },
    computed: {

    },
    watch: {
        selectedCompteur(newValue, oldValue) {
            this.isGenerated = false
        }
    },
    methods: {
        async genererCourbeDeCharge() {
            if (this.$refs.formAcd.validate()) {

                this.dialog = true
                this.loading = true
                this.error = false
                var formData = new FormData()
                formData.append('compteurs', JSON.stringify(this.selectedCompteur))
                formData.append('dateFin', this.dateFin)
                formData.append('societeId', this.societe.id)
                formData.append('token', this.getToken('token'))
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'genererCourbeDeCharge/',
                    method: 'POST',
                    data: formData,

                }).then(() => { 
                    
                 }).finally(() => {
                    this.isGenerated = true;

                })
                
                this.dialog = false
                this.loading = false
                this.error = false
            }
        },
        getYesterdayDate() {
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return yesterday.toISOString().substr(0, 10);
        },
        toggle() {
            this.selectedCompteur = [...this.listCompteur];
        },
    },
};
</script>
  <style>
.tdCenter {
    vertical-align: middle;
}

#tableau-multisite-elec.table thead th,
#table-multisite-gaz.table thead th {
    background-color: #103A5E;
    color: white;
    font-weight: bold;
}
</style>
  <style scoped>
::v-deep .v-label {
    margin: 0px;
}

@media only screen and (max-width:400px) {
    .title-container {
        font-size: large;
        margin-left: 0px !important;
    }
}
</style>
  <style scoped lang="scss">
.dropzone {
    border: 2px solid #90a4ae56;
    border-radius: 8px;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    p {
        margin-bottom: 0;

        &:first-of-type {
            font-weight: 500;
            font-size: 1rem;
            color: #263238;

            span {
                color: gray;
            }
        }

        &:last-of-type {
            font-size: 0.8rem;
        }
    }
}

.requireddropzone {
    border: 2px dashed red;
    border-radius: 8px;
    min-height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    p {
        margin-bottom: 0;

        &:first-of-type {
            font-weight: 500;
            font-size: 1rem;
            color: #263238;

            span {
                color: gray;
            }
        }

        &:last-of-type {
            font-size: 0.8rem;
        }
    }
}
