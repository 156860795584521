<template>
    <div>
        <dialogeModal
        v-if="dialog==true"
        :error="error"
        :dialog="dialog"
        :loading="loading"
        @closeDialog="dialog = false"
        titre="Extraction du contrat"
        :messageDialogeSucces="this.messageDialogeSucces"
        :messageDialogeError="this.messageDialogeError" />
        
        <!-- form add plus data -->
        <v-dialog
            v-model="addData"
            max-width="850"
            persistent
            >
            <v-card>
                <v-card-title class="text-h5">
                    Informations complémentaires
                    
                </v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-form ref="form"  lazy-validation class="justify-left">
                    <v-row  class="d-flex justify-content-center">
                        
                        <v-col cols="10" md="10" sm="10" lg="10" xl="10" class="ml-3 mt-8">
                            <span>Voulez-vous ajouter une adresse de facturation ?</span>
                            <v-radio-group
                            class="d-flex align-content-center"
                                v-model="addAdrsFact"
                                row
                                >
                                <v-radio
                                    class="mb-0"
                                    label="oui"
                                    :value="true"
                                ></v-radio>
                                <v-radio
                                    class=""
                                    label="non"
                                    :value="false"
                                ></v-radio>
                                </v-radio-group>
                        </v-col>
                        <v-col cols="10" md="10" sm="10" lg="10" xl="10">
                        </v-col>
                        <v-col cols="10" md="10" sm="10" lg="10" xl="10">
                            <v-text-field
                                v-if="addAdrsFact==true"
                                outlined
                                v-model="adresseFacturation"
                                :rules="[rules.required]"
                                label="Adresse de facturation"
                            ></v-text-field>
                        </v-col>
                        
                        
                        
                        
                        <v-col cols="10" md="10" sm="10" lg="10" xl="10">
                            
                            <v-btn v-if="loading==false"
                                @click="createContrat"
                                depressed class="ma-auto text-capitalize text-white" 
                                :color="$colors[0]">
                                Confirmer
                            </v-btn>
                            <v-btn v-if="loading==false"
                                color="error"
                                text
                                @click="addData=false"
                            >
                                Annuler
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <div>
            <v-btn
            @click="replierForm"
            icon
            depressed class="ma-auto text-capitalize">
                <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'

    import dialogeModal from "../../components/ui/dialogeModal"
    import axios from 'axios'
    export default {
        name: 'gazelContrat',
        components:{dialogeModal:dialogeModal},
        props: {
            donner: null,
            numCompteur: null,
            offre: null,
            donnerEnedis: null,
            societe: null,
        },
        data: () => ({
            addData : false,
            addAdrsFact: false,
            adresseFacturation: '',
            RaisonFacturation: '',
            dialog: false,
            error: false,
            loading: false,
            messageDialogeError: "",
            messageDialogeSucces: "",
            data:{
                 dateAjourdhui: new Date().getDate()+"/"+(new Date().getMonth()+1)+"/"+new Date().getFullYear(),
                 dateDebut: "",
                 dateFin: "",
                 pdl: "",
                 siret: "", 
                 codeNaf: "", 
                 adresseSociete: "", 
                 adresseCompteur: "",
                 codePostale: '',
                 nom: "",
                 prenom: "", 
                 email: "", 
                 numTele: "", 
                 consommationAnuuelle: "",
                 typeCompteur: "",
                 kva: "",

                 prixHPH: "",
                 prixHCH: "",
                 prixHPE: "",
                 prixHCE: "",
                 prixPointe: "",
                 raison: '',

                 coefHph: '',
                 coefHch: '',
                 coefHpe: '',
                 coefHce: '',
                 coefPointe: '',

                 tva: '',
                 rcs: '',
                 capitale: '',
                 cee: '',
            },
            rules: {
                required: value => !!value || 'Ce champ est obligatoire.',
            }
        }),
        created() {
        },
        methods: {
            async createContrat(){
                this.$refs.form.validate()
                if(this.$refs.form.validate()){
                this.addData = false
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialogeError= ""
                this.messageDialogeSucces= ""

                

                    var autreDonner = {}
                autreDonner.societe="gazelec"
                autreDonner.addAdrsFact = this.addAdrsFact
                autreDonner.RaisonFacturation = this.RaisonFacturation
                autreDonner.adresseFacturation = this.adresseFacturation
                var dataToSend = {
                    donner: this.donner,
                    donnerEnedis: this.donnerEnedis,
                    optionTarifaire: this.optionTarifaire,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                    autreDonner: autreDonner,
                }
                await axios({
                    url: 'https://editioncontrat.volto.fr/gazelContrat',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur+'_GazelEnergie_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialogeError= ""
                this.messageDialogeSucces=""
            }
            },
            dateFinValidite(day){
                var result = new Date();
                 result.setDate(result.getDate() + day);
                 return result.getDate()+"/"+(result.getMonth()+1)+"/"+result.getFullYear()
            },
            async replierForm(){
                this.addData = true
            },
            async getOffreType(){
                
                this.dialog= true
                this.error= false
                this.loading= true
                this.messageDialogeError= ""
                this.messageDialogeSucces=""
                if(this.offre == 'Offre ARENH - sans plafond'){
                    await this.generatePdf_arenh_sansPlafond()
                }else if(this.offre == 'Offre ARENH - CAPA reduite'){
                    await this.generatePdfCapaRedui()
                }else if(this.offre == 'Offre ARENH'){
                    await this.generatePdf_elec_reduit()
                }else if(this.offre == 'Offre Marché'){
                    await this.generatePdf()
                }

                
                //await this.generatePdfUnique()
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialogeError= ""
                this.messageDialogeSucces= ""
                this.addData = false
            },
            async getCodePostale(adresse){
                var myRe = new RegExp('\\d\\d\\d\\d\\d', '');
                var myArray = myRe.exec(adresse);
                var codePostale =  myArray[0]
                var voie = adresse.split(codePostale)[0]
                var commune = adresse.split(codePostale)[1]
                return [codePostale, voie, commune]
            },
            async generatePdf_elec_reduit(){
                var para = await this.createParagraphe()
                var ref = await this.getTimeStamp()
                /////////////// page 1 ///////////////////////////////////////
                const url1 = '/pdf/gazel/arenh_elec_reduit/electronReduit-1.pdf'
                const pageZero = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                 // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                //interloculteur
                firstPage.drawText(this.data.raison, {
                    x: 315,
                    y: 280,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText("Nombre de site: 1 site(s))", {
                    x: 315,
                    y: 260,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                
                firstPage.drawText("Réf. Contrat : "+ref, {
                    x: 315,
                    y: 240,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 315,
                    y: 190,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText("Email: "+this.data.email, {
                    x: 315,
                    y: 170,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                //////////////////// page 2 /////////////////////////////////////////
                const url2 = '/pdf/gazel/arenh_elec_reduit/electronReduit-2.pdf'
                const pageOne = await fetch(url2).then((res) =>
                    res.arrayBuffer()
                )
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 3 /////////////////////////////////////////
                const url3 = '/pdf/gazel/arenh_elec_reduit/electronReduit-3.pdf'
                const pageTwo = await fetch(url3).then((res) =>
                    res.arrayBuffer()
                )
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]
                var bigParagraphe = para
                var multiText = layoutMultilineText(bigParagraphe, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 320 - 100, height: 1000  }
                })
                
                    var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 330,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }

                
                firstPage.drawText(this.data.dateDebut, {
                    x: 268,
                    y: 180,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 328,
                    y: 180,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                //////////////////// page 4 /////////////////////////////////////////
                const url4 = '/pdf/gazel/arenh_elec_reduit/electronReduit-4.pdf'
                const pageThree = await fetch(url4).then((res) =>
                    res.arrayBuffer()
                )
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]
                
                firstPage.drawText(this.data.pdl, {
                    x: 40,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateDebut, {
                    x: 125,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 195,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixPointe, {
                    x: 273,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHPH, {
                    x: 338,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHCH, {
                    x: 410,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHPE, {
                    x: 474,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHCE, {
                    x: 535,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText('4.58', {
                    x: 86,
                    y: 206,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 5 /////////////////////////////////////////
                const url5 = '/pdf/gazel/arenh_elec_reduit/electronReduit-5.pdf'
                const pageFour = await fetch(url5).then((res) =>
                    res.arrayBuffer()
                )
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
               
                //////////////////// page 6 /////////////////////////////////////////
                const url6 = '/pdf/gazel/arenh_elec_reduit/electronReduit-6.pdf'
                const pageFive = await fetch(url6).then((res) =>
                    res.arrayBuffer()
                )
                const pageFiveDoc = await PDFDocument.load(pageFive)
                var getPages = pageFiveDoc.getPages()
                var firstPage = getPages[0]
                 firstPage.drawText(this.data.pdl, {
                    x: 45,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateDebut, {
                    x: 125,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 195,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefPointe, {
                    x: 277,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHph, {
                    x: 347,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHch, {
                    x: 415,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHpe, {
                    x: 480,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHce, {
                    x: 540,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                var RomanFont = await pageFiveDoc.embedFont(StandardFonts.HelveticaBold)
                
                firstPage.drawText(this.data.raison, {
                    x: 60,
                    y: 370,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 250,
                    y: 347,
                    size: 9,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                //////////////////// page 7 /////////////////////////////////////////
                const url7 = '/pdf/gazel/arenh_elec_reduit/electronReduit-7.pdf'
                const pageSix = await fetch(url7).then((res) =>
                    res.arrayBuffer()
                )
                const pageSixDoc = await PDFDocument.load(pageSix)
                var getPages = pageSixDoc.getPages()
                var firstPage = getPages[0]
               
                firstPage.drawText(this.data.dateDebut, {
                    x: 277,
                    y: 619,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 415,
                    y: 619,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateAjourdhui, {
                    x: 225,
                    y: 534,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })


                
                //////////////////// page 7 /////////////////////////////////////////
                const url8 = '/pdf/gazel/arenh_elec_reduit/electronReduit-8.pdf'
                const pageSeven = await fetch(url8).then((res) =>
                    res.arrayBuffer()
                )
                const pageSevenDoc = await PDFDocument.load(pageSeven)
                var getPages = pageSevenDoc.getPages()
                var firstPage = getPages[0]
                
                 var RomanFont = await pageSevenDoc.embedFont(StandardFonts.HelveticaBold)
                firstPage.drawText(this.data.raison, {
                    x: 55,
                    y: 600,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 250,
                    y: 575,
                    size: 9,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
               
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-9.pdf'
                const pageEight = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const pageEightDoc = await PDFDocument.load(pageEight)
                var getPages = pageEightDoc.getPages()
                var firstPage = getPages[0]
                 var site = await this.getCodePostale(this.data.adresseSociete)
                
                var multiText = layoutMultilineText(site[2], {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 170 - 100, height: 1000  }
                })
                
                var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 35,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                var multiText = layoutMultilineText(this.data.adresseSociete, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 320 - 100, height: 1000  }
                })
                
                var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 125,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                firstPage.drawText(this.data.siret, {
                    x: 330,
                    y: 645,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.pdl, {
                    x: 415,
                    y: 645,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                if(this.data.cee != 'NON SOUMIS'){
                    firstPage.drawText(this.data.cee+"", {
                        x: 505,
                        y: 645,
                        size: 8,
                        color: rgb(0, 0.0, 0.0),
                    })
                }
                 //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-10.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page9Doc = await PDFDocument.load(page9)
                var getPages = page9Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-11.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page10Doc = await PDFDocument.load(page9)
                var getPages = page10Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-12.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page11Doc = await PDFDocument.load(page9)
                var getPages = page11Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-13.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page12Doc = await PDFDocument.load(page9)
                var getPages = page12Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-14.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page13Doc = await PDFDocument.load(page9)
                var getPages = page13Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-15.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page14Doc = await PDFDocument.load(page9)
                var getPages = page14Doc.getPages()
                var firstPage = getPages[0]
                
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-16.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page15Doc = await PDFDocument.load(page9)
                var getPages = page15Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-17.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page16Doc = await PDFDocument.load(page9)
                var getPages = page16Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-18.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page17Doc = await PDFDocument.load(page9)
                var getPages = page17Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-19.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page18Doc = await PDFDocument.load(page9)
                var getPages = page18Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-20.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page19Doc = await PDFDocument.load(page9)
                var getPages = page19Doc.getPages()
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-21.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page20Doc = await PDFDocument.load(page9)
                var getPages = page20Doc.getPages()
                
                var firstPage = getPages[0]
                
                firstPage.drawText(this.data.pdl, {
                    x: 30,
                    y: 667,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_elec_reduit/electronReduit-22.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page21Doc = await PDFDocument.load(page9)
                var getPages = page21Doc.getPages()
                var firstPage = getPages[0]
                var RomanFont = await page19Doc.embedFont(StandardFonts.HelveticaBold)
                firstPage.drawText(this.data.raison, {
                    x: 300,
                    y: 673,
                    size: 8,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                
                var multiText = layoutMultilineText(this.data.adresseSociete , {
                    alignment: 'left', 
                    fontSize: 8,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 400-100, height: 1000}
                })
                
                var startingPositon=650
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 280,
                        y: startingPositon,
                        size: 8,
                        maxWidth: 400-100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (10)
                }
                firstPage.drawText(this.data.email, {
                    x: 280,
                    y: 620,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.raison, {
                    x: 300,
                    y: 577,
                    size: 8,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText(this.data.pdl, {
                    x: 280,
                    y: 560,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.tva, {
                    x: 280,
                    y: 540,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.siret, {
                    x: 280,
                    y: 520,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 280,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })

                
                if(this.addAdrsFact == true){
                    firstPage.drawText(this.data.raison, {
                        x: 298,
                        y: 400,
                        size: 8,
                        font: RomanFont,
                        color: rgb(0, 0.509, 0.95),
                    })
                    var multiText = layoutMultilineText(this.adresseFacturation , {
                        alignment: 'left', 
                        fontSize: 8,   
                        font: timesRomanFont,
                        lineHeight: 9,
                        bounds: { width: 350-100, height: 1000}
                    })
                    
                    var startingPositon=368
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 195,
                            y: startingPositon,
                            size: 9,
                            maxWidth: 350-100,
                            color: rgb(0, 0, 0),
                        })
                        // move position down
                        startingPositon = startingPositon - (11)
                    }
                }
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFiveDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSixDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSevenDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageEightDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page9Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page10Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page11Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page12Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page13Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page14Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page15Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page16Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page17Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page18Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page19Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page20Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page21Doc)
                

                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_GazelEnergie_'+this.data.raison+'_.pdf', 'application/pdf')
            },
            async generatePdf_arenh_sansPlafond(){
                
               var para = await this.createParagraphe()
                var ref = await this.getTimeStamp()
                /////////////// page 1 ///////////////////////////////////////
                const url1 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-1.pdf'
                const pageZero = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                 // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                //interloculteur
                firstPage.drawText(this.data.raison, {
                    x: 315,
                    y: 280,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText("Nombre de site: 1 site(s))", {
                    x: 315,
                    y: 260,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                
                firstPage.drawText("Réf. Contrat : "+ref, {
                    x: 315,
                    y: 240,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 315,
                    y: 190,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText("Email: "+this.data.email, {
                    x: 315,
                    y: 170,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                //////////////////// page 2 /////////////////////////////////////////
                const url2 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-2.pdf'
                const pageOne = await fetch(url2).then((res) =>
                    res.arrayBuffer()
                )
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 3 /////////////////////////////////////////
                const url3 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-3.pdf'
                const pageTwo = await fetch(url3).then((res) =>
                    res.arrayBuffer()
                )
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]
                var bigParagraphe = para
                var multiText = layoutMultilineText(bigParagraphe, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 320 - 100, height: 1000  }
                })
                
                    var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 330,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                
                firstPage.drawText(this.data.dateDebut, {
                    x: 268,
                    y: 180,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 328,
                    y: 180,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 4 /////////////////////////////////////////
                const url4 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-4.pdf'
                const pageThree = await fetch(url4).then((res) =>
                    res.arrayBuffer()
                )
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.pdl, {
                    x: 40,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateDebut, {
                    x: 125,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 195,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixPointe, {
                    x: 273,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHPH, {
                    x: 338,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHCH, {
                    x: 410,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHPE, {
                    x: 474,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHCE, {
                    x: 535,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText('4.58', {
                    x: 86,
                    y: 293,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 5 /////////////////////////////////////////
                const url5 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-5.pdf'
                const pageFour = await fetch(url5).then((res) =>
                    res.arrayBuffer()
                )
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
               
                //////////////////// page 6 /////////////////////////////////////////
                const url6 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-6.pdf'
                const pageFive = await fetch(url6).then((res) =>
                    res.arrayBuffer()
                )
                const pageFiveDoc = await PDFDocument.load(pageFive)
                var getPages = pageFiveDoc.getPages()
                var firstPage = getPages[0]
                 firstPage.drawText(this.data.pdl, {
                    x: 45,
                    y: 590,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateDebut, {
                    x: 125,
                    y: 590,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 195,
                    y: 590,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefPointe, {
                    x: 277,
                    y: 590,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHph, {
                    x: 347,
                    y: 590,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHch, {
                    x: 415,
                    y: 590,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHpe, {
                    x: 480,
                    y: 590,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHce, {
                    x: 540,
                    y: 590,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                var RomanFont = await pageFiveDoc.embedFont(StandardFonts.HelveticaBold)
                
                firstPage.drawText(this.data.raison, {
                    x: 60,
                    y: 457,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 250,
                    y: 432,
                    size: 9,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                //////////////////// page 7 /////////////////////////////////////////
                const url7 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-7.pdf'
                const pageSix = await fetch(url7).then((res) =>
                    res.arrayBuffer()
                )
                const pageSixDoc = await PDFDocument.load(pageSix)
                var getPages = pageSixDoc.getPages()
                var firstPage = getPages[0]
                 var RomanFont = await pageSixDoc.embedFont(StandardFonts.HelveticaBold)
                 
                
                firstPage.drawText(this.data.dateDebut, {
                    x: 277,
                    y: 619,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 415,
                    y: 619,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateAjourdhui, {
                    x: 225,
                    y: 534,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 7 /////////////////////////////////////////
                const url8 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-8.pdf'
                const pageSeven = await fetch(url8).then((res) =>
                    res.arrayBuffer()
                )
                const pageSevenDoc = await PDFDocument.load(pageSeven)
                var getPages = pageSevenDoc.getPages()
                var firstPage = getPages[0]
                
                firstPage.drawText(this.data.raison, {
                    x: 55,
                    y: 600,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 250,
                    y: 575,
                    size: 9,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-9.pdf'
                const pageEight = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const pageEightDoc = await PDFDocument.load(pageEight)
                var getPages = pageEightDoc.getPages()
                var firstPage = getPages[0]
                 var site = await this.getCodePostale(this.data.adresseSociete)
                
                var multiText = layoutMultilineText(site[2], {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 170 - 100, height: 1000  }
                })
                
                var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 35,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                var multiText = layoutMultilineText(this.data.adresseSociete, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 320 - 100, height: 1000  }
                })
                
                var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 125,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                firstPage.drawText(this.data.siret, {
                    x: 330,
                    y: 645,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.pdl, {
                    x: 415,
                    y: 645,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                if(this.data.cee != 'NON SOUMIS'){
                    firstPage.drawText(this.data.cee+"", {
                        x: 505,
                        y: 645,
                        size: 8,
                        color: rgb(0, 0.0, 0.0),
                    })
                }
                 //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-10.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page9Doc = await PDFDocument.load(page9)
                var getPages = page9Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-11.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page10Doc = await PDFDocument.load(page9)
                var getPages = page10Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-12.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page11Doc = await PDFDocument.load(page9)
                var getPages = page11Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-13.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page12Doc = await PDFDocument.load(page9)
                var getPages = page12Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-14.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page13Doc = await PDFDocument.load(page9)
                var getPages = page13Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-15.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page14Doc = await PDFDocument.load(page9)
                var getPages = page14Doc.getPages()
                var firstPage = getPages[0]
                
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-16.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page15Doc = await PDFDocument.load(page9)
                var getPages = page15Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-17.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page16Doc = await PDFDocument.load(page9)
                var getPages = page16Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-18.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page17Doc = await PDFDocument.load(page9)
                var getPages = page17Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-19.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page18Doc = await PDFDocument.load(page9)
                var getPages = page18Doc.getPages()
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-20.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page19Doc = await PDFDocument.load(page9)
                var getPages = page19Doc.getPages()
                
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-21.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page20Doc = await PDFDocument.load(page9)
                var getPages = page20Doc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.pdl, {
                    x: 30,
                    y: 667,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/arenh_sans_plafond/sansPlafond-22.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page21Doc = await PDFDocument.load(page9)
                var getPages = page21Doc.getPages()
                var firstPage = getPages[0]
                var RomanFont = await page19Doc.embedFont(StandardFonts.HelveticaBold)
                firstPage.drawText(this.data.raison, {
                    x: 300,
                    y: 673,
                    size: 8,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                
                var multiText = layoutMultilineText(this.data.adresseSociete , {
                    alignment: 'left', 
                    fontSize: 8,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 400-100, height: 1000}
                })
                
                var startingPositon=650
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 280,
                        y: startingPositon,
                        size: 8,
                        maxWidth: 400-100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (10)
                }
                firstPage.drawText(this.data.email, {
                    x: 280,
                    y: 620,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.raison, {
                    x: 300,
                    y: 577,
                    size: 8,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText(this.data.pdl, {
                    x: 280,
                    y: 560,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.tva, {
                    x: 280,
                    y: 540,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.siret, {
                    x: 280,
                    y: 520,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 280,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })

                
                if(this.addAdrsFact == true){
                    firstPage.drawText(this.data.raison, {
                        x: 298,
                        y: 400,
                        size: 8,
                        font: RomanFont,
                        color: rgb(0, 0.509, 0.95),
                    })
                    var multiText = layoutMultilineText(this.adresseFacturation , {
                        alignment: 'left', 
                        fontSize: 8,   
                        font: timesRomanFont,
                        lineHeight: 9,
                        bounds: { width: 350-100, height: 1000}
                    })
                    
                    var startingPositon=368
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 195,
                            y: startingPositon,
                            size: 9,
                            maxWidth: 350-100,
                            color: rgb(0, 0, 0),
                        })
                        // move position down
                        startingPositon = startingPositon - (11)
                    }
                }

                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFiveDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSixDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSevenDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageEightDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page9Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page10Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page11Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page12Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page13Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page14Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page15Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page16Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page17Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page18Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page19Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page20Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page21Doc)
                

                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_GazelEnergie_'+this.data.raison+'_.pdf', 'application/pdf')
            },
            async generatePdfCapaRedui(){
                
                var para = await this.createParagraphe()
                var ref = await this.getTimeStamp()
                /////////////// page 1 ///////////////////////////////////////
                const url1 = '/pdf/gazel/capa_reduit/capareduit-1.pdf'
                const pageZero = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                 // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                //interloculteur
                firstPage.drawText(this.data.raison, {
                    x: 315,
                    y: 280,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText("Nombre de site: 1 site(s))", {
                    x: 315,
                    y: 260,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                
                firstPage.drawText("Réf. Contrat : "+ref, {
                    x: 315,
                    y: 240,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 315,
                    y: 190,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText("Email: "+this.data.email, {
                    x: 315,
                    y: 170,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                //////////////////// page 2 /////////////////////////////////////////
                const url2 = '/pdf/gazel/capa_reduit/capareduit-2.pdf'
                const pageOne = await fetch(url2).then((res) =>
                    res.arrayBuffer()
                )
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 3 /////////////////////////////////////////
                const url3 = '/pdf/gazel/capa_reduit/capareduit-3.pdf'
                const pageTwo = await fetch(url3).then((res) =>
                    res.arrayBuffer()
                )
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]
                var bigParagraphe = para
                var multiText = layoutMultilineText(bigParagraphe, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 320 - 100, height: 1000  }
                })
                
                    var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 330,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                firstPage.drawText(this.data.dateDebut, {
                    x: 268,
                    y: 180,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 328,
                    y: 180,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                //////////////////// page 4 /////////////////////////////////////////
                const url4 = '/pdf/gazel/capa_reduit/capareduit-4.pdf'
                const pageThree = await fetch(url4).then((res) =>
                    res.arrayBuffer()
                )
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.pdl, {
                    x: 40,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateDebut, {
                    x: 125,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 195,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixPointe, {
                    x: 273,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHPH, {
                    x: 338,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHCH, {
                    x: 410,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHPE, {
                    x: 474,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHCE, {
                    x: 535,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText('4.58', {
                    x: 86,
                    y: 142,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 5 /////////////////////////////////////////
                const url5 = '/pdf/gazel/capa_reduit/capareduit-5.pdf'
                const pageFour = await fetch(url5).then((res) =>
                    res.arrayBuffer()
                )
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
               
                //////////////////// page 6 /////////////////////////////////////////
                const url6 = '/pdf/gazel/capa_reduit/capareduit-6.pdf'
                const pageFive = await fetch(url6).then((res) =>
                    res.arrayBuffer()
                )
                const pageFiveDoc = await PDFDocument.load(pageFive)
                var getPages = pageFiveDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.pdl, {
                    x: 45,
                    y: 440,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateDebut, {
                    x: 125,
                    y: 440,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 195,
                    y: 440,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefPointe, {
                    x: 277,
                    y: 440,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHph, {
                    x: 347,
                    y: 440,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHch, {
                    x: 415,
                    y: 440,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHpe, {
                    x: 480,
                    y: 440,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHce, {
                    x: 540,
                    y: 440,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                var RomanFont = await pageFiveDoc.embedFont(StandardFonts.HelveticaBold)
                
                firstPage.drawText(this.data.raison, {
                    x: 55,
                    y: 305,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 250,
                    y: 280,
                    size: 9,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                //////////////////// page 7 /////////////////////////////////////////
                const url7 = '/pdf/gazel/capa_reduit/capareduit-7.pdf'
                const pageSix = await fetch(url7).then((res) =>
                    res.arrayBuffer()
                )
                const pageSixDoc = await PDFDocument.load(pageSix)
                var getPages = pageSixDoc.getPages()
                var firstPage = getPages[0]
                 var RomanFont = await pageSixDoc.embedFont(StandardFonts.HelveticaBold)
                 
                
                firstPage.drawText(this.data.dateDebut, {
                    x: 277,
                    y: 619,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 415,
                    y: 619,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateAjourdhui, {
                    x: 225,
                    y: 534,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 7 /////////////////////////////////////////
                const url8 = '/pdf/gazel/capa_reduit/capareduit-8.pdf'
                const pageSeven = await fetch(url8).then((res) =>
                    res.arrayBuffer()
                )
                const pageSevenDoc = await PDFDocument.load(pageSeven)
                var getPages = pageSevenDoc.getPages()
                var firstPage = getPages[0]
                var RomanFont = await pageSevenDoc.embedFont(StandardFonts.HelveticaBold)
                
                firstPage.drawText(this.data.raison, {
                    x: 55,
                    y: 600,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 250,
                    y: 575,
                    size: 9,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
               
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-9.pdf'
                const pageEight = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const pageEightDoc = await PDFDocument.load(pageEight)
                var getPages = pageEightDoc.getPages()
                var firstPage = getPages[0]
                 var site = await this.getCodePostale(this.data.adresseSociete)
                
                var multiText = layoutMultilineText(site[2], {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 170 - 100, height: 1000  }
                })
                
                var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 35,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                var multiText = layoutMultilineText(this.data.adresseSociete, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 320 - 100, height: 1000  }
                })
                
                var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 125,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                firstPage.drawText(this.data.siret, {
                    x: 330,
                    y: 645,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.pdl, {
                    x: 415,
                    y: 645,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                if(this.data.cee != 'NON SOUMIS'){
                    firstPage.drawText(this.data.cee+"", {
                        x: 505,
                        y: 645,
                        size: 8,
                        color: rgb(0, 0.0, 0.0),
                    })
                }
                 //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-10.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page9Doc = await PDFDocument.load(page9)
                var getPages = page9Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-11.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page10Doc = await PDFDocument.load(page9)
                var getPages = page10Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-12.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page11Doc = await PDFDocument.load(page9)
                var getPages = page11Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-13.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page12Doc = await PDFDocument.load(page9)
                var getPages = page12Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-14.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page13Doc = await PDFDocument.load(page9)
                var getPages = page13Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-15.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page14Doc = await PDFDocument.load(page9)
                var getPages = page14Doc.getPages()
                var firstPage = getPages[0]
                
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-16.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page15Doc = await PDFDocument.load(page9)
                var getPages = page15Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-17.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page16Doc = await PDFDocument.load(page9)
                var getPages = page16Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-18.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page17Doc = await PDFDocument.load(page9)
                var getPages = page17Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-19.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page18Doc = await PDFDocument.load(page9)
                var getPages = page18Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-20.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page19Doc = await PDFDocument.load(page9)
                var getPages = page19Doc.getPages()
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-21.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page20Doc = await PDFDocument.load(page9)
                var getPages = page20Doc.getPages()
                var firstPage = getPages[0]
                
                firstPage.drawText(this.data.pdl, {
                    x: 30,
                    y: 667,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/capa_reduit/capareduit-22.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page21Doc = await PDFDocument.load(page9)
                var getPages = page21Doc.getPages()
                var firstPage = getPages[0]
                var RomanFont = await page19Doc.embedFont(StandardFonts.HelveticaBold)
                firstPage.drawText(this.data.raison, {
                    x: 300,
                    y: 673,
                    size: 8,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                
                var multiText = layoutMultilineText(this.data.adresseSociete , {
                    alignment: 'left', 
                    fontSize: 8,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 400-100, height: 1000}
                })
                
                var startingPositon=650
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 280,
                        y: startingPositon,
                        size: 8,
                        maxWidth: 400-100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (10)
                }
                firstPage.drawText(this.data.email, {
                    x: 280,
                    y: 620,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.raison, {
                    x: 300,
                    y: 577,
                    size: 8,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText(this.data.pdl, {
                    x: 280,
                    y: 560,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.tva, {
                    x: 280,
                    y: 540,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.siret, {
                    x: 280,
                    y: 520,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 280,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })

                
                if(this.addAdrsFact == true){
                    firstPage.drawText(this.data.raison, {
                        x: 298,
                        y: 400,
                        size: 8,
                        font: RomanFont,
                        color: rgb(0, 0.509, 0.95),
                    })
                    var multiText = layoutMultilineText(this.adresseFacturation , {
                        alignment: 'left', 
                        fontSize: 8,   
                        font: timesRomanFont,
                        lineHeight: 9,
                        bounds: { width: 350-100, height: 1000}
                    })
                    
                    var startingPositon=368
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 195,
                            y: startingPositon,
                            size: 9,
                            maxWidth: 350-100,
                            color: rgb(0, 0, 0),
                        })
                        // move position down
                        startingPositon = startingPositon - (11)
                    }
                }

                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFiveDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSixDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSevenDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageEightDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page9Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page10Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page11Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page12Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page13Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page14Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page15Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page16Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page17Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page18Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page19Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page20Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page21Doc)
                

                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_GazelEnergie_'+this.data.raison+'_.pdf', 'application/pdf')
            },
            async generatePdf(){
                var para = await this.createParagraphe()
                var ref = await this.getTimeStamp()
                /////////////// page 1 ///////////////////////////////////////
                const url1 = '/pdf/gazel/marche/marche-1.pdf'
                const pageZero = await fetch(url1).then((res) =>
                    res.arrayBuffer()
                )
                 // Load a PDFDocument from each of the existing PDFs
                const pageZeroDoc = await PDFDocument.load(pageZero)
                var getPages = pageZeroDoc.getPages()
                var firstPage = getPages[0]
                
                const timesRomanFont = await pageZeroDoc.embedFont(StandardFonts.HelveticaBold)
                //interloculteur
                firstPage.drawText(this.data.raison, {
                    x: 315,
                    y: 280,
                    size: 12,
                    font: timesRomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText("Nombre de site: 1 site(s))", {
                    x: 315,
                    y: 260,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                
                firstPage.drawText("Réf. Contrat : "+ref, {
                    x: 315,
                    y: 240,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 315,
                    y: 190,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText("Email: "+this.data.email, {
                    x: 315,
                    y: 170,
                    size: 10,
                    color: rgb(0, 0.509, 0.95),
                })
                //////////////////// page 2 /////////////////////////////////////////
                const url2 = '/pdf/gazel/marche/marche-2.pdf'
                const pageOne = await fetch(url2).then((res) =>
                    res.arrayBuffer()
                )
                const pageOneDoc = await PDFDocument.load(pageOne)
                var getPages = pageOneDoc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 3 /////////////////////////////////////////
                const url3 = '/pdf/gazel/marche/marche-3.pdf'
                const pageTwo = await fetch(url3).then((res) =>
                    res.arrayBuffer()
                )
                const pageTwoDoc = await PDFDocument.load(pageTwo)
                var getPages = pageTwoDoc.getPages()
                var firstPage = getPages[0]
                var bigParagraphe = para
                var multiText = layoutMultilineText(bigParagraphe, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 320 - 100, height: 1000  }
                })
                
                    var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 330,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }

                
                firstPage.drawText(this.data.dateDebut, {
                    x: 268,
                    y: 180,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 328,
                    y: 180,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                //////////////////// page 4 /////////////////////////////////////////
                const url4 = '/pdf/gazel/marche/marche-4.pdf'
                const pageThree = await fetch(url4).then((res) =>
                    res.arrayBuffer()
                )
                const pageThreeDoc = await PDFDocument.load(pageThree)
                var getPages = pageThreeDoc.getPages()
                var firstPage = getPages[0]
                
                firstPage.drawText(this.data.pdl, {
                    x: 35,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateDebut, {
                    x: 123,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 193,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.prixPointe, {
                    x: 270,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHPH, {
                    x: 335,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHCH, {
                    x: 405,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHPE, {
                    x: 470,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.prixHCE, {
                    x: 535,
                    y: 595,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText('4.58', {
                    x: 85,
                    y: 334,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 5 /////////////////////////////////////////
                const url5 = '/pdf/gazel/marche/marche-5.pdf'
                const pageFour = await fetch(url5).then((res) =>
                    res.arrayBuffer()
                )
                const pageFourDoc = await PDFDocument.load(pageFour)
                var getPages = pageFourDoc.getPages()
                var firstPage = getPages[0]
                
                
                //////////////////// page 6 /////////////////////////////////////////
                const url6 = '/pdf/gazel/marche/marche-6.pdf'
                const pageFive = await fetch(url6).then((res) =>
                    res.arrayBuffer()
                )
                const pageFiveDoc = await PDFDocument.load(pageFive)
                var getPages = pageFiveDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.pdl, {
                    x: 40,
                    y: 630,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateDebut, {
                    x: 125,
                    y: 630,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 195,
                    y: 630,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefPointe, {
                    x: 275,
                    y: 630,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHph, {
                    x: 345,
                    y: 630,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHch, {
                    x: 410,
                    y: 630,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHpe, {
                    x: 475,
                    y: 630,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.coefHce, {
                    x: 540,
                    y: 630,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                var RomanFont = await pageFiveDoc.embedFont(StandardFonts.HelveticaBold)
                
                firstPage.drawText(this.data.raison, {
                    x: 60,
                    y: 502,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 250,
                    y: 477,
                    size: 9,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                //////////////////// page 7 /////////////////////////////////////////
                const url7 = '/pdf/gazel/marche/marche-7.pdf'
                const pageSix = await fetch(url7).then((res) =>
                    res.arrayBuffer()
                )
                const pageSixDoc = await PDFDocument.load(pageSix)
                var getPages = pageSixDoc.getPages()
                var firstPage = getPages[0]
                firstPage.drawText(this.data.dateDebut, {
                    x: 276,
                    y: 619,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateFin, {
                    x: 412,
                    y: 619,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.dateAjourdhui, {
                    x: 222,
                    y: 533,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })



                
                //////////////////// page 7 /////////////////////////////////////////
                const url8 = '/pdf/gazel/marche/marche-8.pdf'
                const pageSeven = await fetch(url8).then((res) =>
                    res.arrayBuffer()
                )
                const pageSevenDoc = await PDFDocument.load(pageSeven)
                var getPages = pageSevenDoc.getPages()
                var firstPage = getPages[0]
                

                 var RomanFont = await pageSixDoc.embedFont(StandardFonts.HelveticaBold)
                firstPage.drawText(this.data.raison, {
                    x: 55,
                    y: 600,
                    size: 8,
                    font: RomanFont,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.nom+" "+this.data.prenom, {
                    x: 250,
                    y: 575,
                    size: 10,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
               
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-9.pdf'
                const pageEight = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const pageEightDoc = await PDFDocument.load(pageEight)
                var getPages = pageEightDoc.getPages()
                var firstPage = getPages[0]
                 
               var site = await this.getCodePostale(this.data.adresseSociete)
                
                var multiText = layoutMultilineText(site[2], {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 170 - 100, height: 1000  }
                })
                
                var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 35,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                var multiText = layoutMultilineText(this.data.adresseSociete, {
                    alignment: 'left', 
                    fontSize: 9,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 320 - 100, height: 1000  }
                })
                
                var startingPositon=645
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 115,
                        y: startingPositon,
                        size: 9,
                        maxWidth: 320 - 100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (11)
                }
                firstPage.drawText(this.data.siret, {
                    x: 325,
                    y: 645,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.pdl, {
                    x: 413,
                    y: 645,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                if(this.data.cee != 'NON SOUMIS'){
                    firstPage.drawText(this.data.cee+"", {
                        x: 505,
                        y: 645,
                        size: 8,
                        color: rgb(0, 0.0, 0.0),
                    })
                }
                 //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-10.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page9Doc = await PDFDocument.load(page9)
                var getPages = page9Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-11.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page10Doc = await PDFDocument.load(page9)
                var getPages = page10Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-12.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page11Doc = await PDFDocument.load(page9)
                var getPages = page11Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-13.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page12Doc = await PDFDocument.load(page9)
                var getPages = page12Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-14.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page13Doc = await PDFDocument.load(page9)
                var getPages = page13Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-15.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page14Doc = await PDFDocument.load(page9)
                var getPages = page14Doc.getPages()
                var firstPage = getPages[0]
                
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-16.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page15Doc = await PDFDocument.load(page9)
                var getPages = page15Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-17.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page16Doc = await PDFDocument.load(page9)
                var getPages = page16Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-18.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page17Doc = await PDFDocument.load(page9)
                var getPages = page17Doc.getPages()
                var firstPage = getPages[0]
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-19.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page18Doc = await PDFDocument.load(page9)
                var getPages = page18Doc.getPages()
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-20.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page19Doc = await PDFDocument.load(page9)
                var getPages = page19Doc.getPages()
                
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-21.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page20Doc = await PDFDocument.load(page9)
                var getPages = page20Doc.getPages()
                
                var firstPage = getPages[0]
                
                firstPage.drawText(this.data.pdl, {
                    x: 30,
                    y: 667,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                //////////////////// page 8 /////////////////////////////////////////
                var url9 = '/pdf/gazel/marche/marche-22.pdf'
                var page9 = await fetch(url9).then((res) =>
                    res.arrayBuffer()
                )
                const page21Doc = await PDFDocument.load(page9)
                var getPages = page21Doc.getPages()
                
                var firstPage = getPages[0]
                var RomanFont = await page19Doc.embedFont(StandardFonts.HelveticaBold)
                firstPage.drawText(this.data.raison, {
                    x: 300,
                    y: 673,
                    size: 8,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                
                var multiText = layoutMultilineText(this.data.adresseSociete , {
                    alignment: 'left', 
                    fontSize: 8,   
                    font: timesRomanFont,
                    lineHeight: 9,
                    bounds: { width: 400-100, height: 1000}
                })
                
                var startingPositon=650
                for(let i = 0; i < multiText.lines.length; i++) {
                    
                    firstPage.drawText(`${multiText.lines[i].text}`, {
                        x: 280,
                        y: startingPositon,
                        size: 8,
                        maxWidth: 400-100,
                        color: rgb(0, 0, 0),
                    })
                    // move position down
                    startingPositon = startingPositon - (10)
                }
                firstPage.drawText(this.data.email, {
                    x: 280,
                    y: 620,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.raison, {
                    x: 300,
                    y: 577,
                    size: 8,
                    font: RomanFont,
                    color: rgb(0, 0.509, 0.95),
                })
                firstPage.drawText(this.data.pdl, {
                    x: 280,
                    y: 560,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.tva, {
                    x: 280,
                    y: 540,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                
                firstPage.drawText(this.data.siret, {
                    x: 280,
                    y: 520,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })
                firstPage.drawText(this.data.codeNaf, {
                    x: 280,
                    y: 500,
                    size: 8,
                    color: rgb(0, 0.0, 0.0),
                })

                
                if(this.addAdrsFact == true){
                    firstPage.drawText(this.data.raison, {
                        x: 298,
                        y: 400,
                        size: 8,
                        font: RomanFont,
                        color: rgb(0, 0.509, 0.95),
                    })
                    var multiText = layoutMultilineText(this.adresseFacturation , {
                        alignment: 'left', 
                        fontSize: 8,   
                        font: timesRomanFont,
                        lineHeight: 9,
                        bounds: { width: 350-100, height: 1000}
                    })
                    
                    var startingPositon=368
                    for(let i = 0; i < multiText.lines.length; i++) {
                        
                        firstPage.drawText(`${multiText.lines[i].text}`, {
                            x: 195,
                            y: startingPositon,
                            size: 9,
                            maxWidth: 350-100,
                            color: rgb(0, 0, 0),
                        })
                        // move position down
                        startingPositon = startingPositon - (11)
                    }
                }
                // Create a new PDFDocument
                let mergedPdf = await PDFDocument.create()
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageZeroDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageOneDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageTwoDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageThreeDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFourDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageFiveDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSixDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageSevenDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, pageEightDoc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page9Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page10Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page11Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page12Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page13Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page14Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page15Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page16Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page17Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page18Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page19Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page20Doc)
                mergedPdf = await this.addPagesToPdf(mergedPdf, page21Doc)
                

                //fin contact
                const pdfBytes = await mergedPdf.save()
                this.generatedPdf = pdfBytes
                download(pdfBytes, this.data.pdl+'_GazelEnergie_'+this.data.raison+'_.pdf', 'application/pdf')
            },
            
            async addPagesToPdf(mergedPdf, donorPdf) {
                const copiedPages = await mergedPdf.copyPages(
                    donorPdf,
                    donorPdf.getPageIndices()
                )
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page)
                })
                return mergedPdf
            },
            async getTimeStamp(){
                var time = new Date().getTime().toString()
                var t1 = time.substring(0, 6)
                var t2 = time.substring(6, time.length)
                var ref = "GES_"+t1+"_"+t2+"_"+this.data.raison.substring(0,5).replace(' ', '_')
                return ref 
            },
            async createParagraphe(){
                this.data.dateAjourdhui = this.dateFinValidite(3)+""
                if(this.donnerEnedis.donneTechniqueInfo[0].result == 'valide'){
                    this.data.adresseCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie+' '+
                            this.donnerEnedis.donneTechniqueInfo[0].data.codePostal+' '+this.donnerEnedis.donneTechniqueInfo[0].data.commune

                }
                
                this.data.nbrMonth = this.donner.nbrMonth+""
                this.data.typeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.typeCompteur
                this.data.codeNaf = this.societe.codeNaf               
                this.data.codePostaleCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.codePostal
                this.data.voieCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie
                this.data.communeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.commune
                this.data.codePostaleSociete = this.societe.codePostal
                this.data.voieSociete = this.societe.Voie
                this.data.communeSociete = this.societe.commune
                
                this.data.nbrYears = this.donner.nbrYears+""
                this.data.dateDebut = this.donner.dateDebut+""
                this.data.dateFin = this.donner.dateFin+""
                this.data.pdl = this.numCompteur+""
                this.data.siret = this.societe.siret+''
                this.data.adresseSociete = this.societe.AdressePostal+""
                this.data.nom = this.societe.nom+""
                this.data.prenom = this.societe.prenom+""
                this.data.email = this.societe.AdresseEmail+""
                this.data.numTele = this.societe.numTele+""
                this.data.raison = this.societe.Raison+""
                this.data.consommationAnuuelle = this.donner.consomationGlobale+""



                this.data.coefHph= this.donner.coefHph+""
                this.data.coefHch= this.donner.coefHch+""
                this.data.coefHpe= this.donner.coefHpe+""
                this.data.coefHce= this.donner.coefHce+""
                this.data.coefPointe= this.donner.coefPointe+""
                if(isNaN(this.data.coefPointe) == true){
                    this.data.coefPointe = ""
                }
                this.data.cee = this.donner.cee

                this.data.prixPointe = this.donner.unitairepointe+""
                if(isNaN(this.data.prixPointe) == true){
                    this.data.prixPointe = ""
                }
                this.data.prixHPH = this.donner.unitairehph+""
                this.data.prixHCH = this.donner.unitairehch+""
                this.data.prixHPE = this.donner.unitairehpe+""
                this.data.prixHCE = this.donner.unitairehce+""
                this.data.rcs = this.societe.RCS
                this.data.tva = this.societe.TVA
                this.data.capitale = this.societe.capitalSocial
                
                var para = this.data.raison + ", SARL au capital de "+this.data.capitale+" euros, dont le siège social est situé à "
                +this.data.adresseSociete+", Immatriculée au RCS de "+this.data.rcs+" sous le numéro SIREN "+this.data.siret.substring(0,9)+
                ", représentée par "+this.data.nom +" "+this.data.prenom +
                ", dûment habilité aux fins des présentes, Ci-après désignée par "+this.data.raison+", ou le « Client », d’autre part."
                return para
            },
        }
    }
</script>
<style scoped>
::v-deep .v-input--selection-controls__input + .v-label{
   margin-bottom:0px !important;
}
</style>