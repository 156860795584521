<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500"
            >
            <v-card>
                <v-card-title class="text-h5">
                Extraction du contrat
                </v-card-title>
                <v-card-text>
                    <v-alert v-if="error==true && loading==false" type="error">
                        {{messageDialoge}}
                    </v-alert>
                    <v-alert v-if="error==false && loading==false" type="success">
                        {{messageDialoge}}
                    </v-alert>
                    <v-flex  class="text-center">
                        <v-progress-circular v-if="loading==true"
                            :size="150"
                            :width="7"
                            color="primary"
                            indeterminate
                            >
                            Patientez  ...
                        </v-progress-circular>
                    </v-flex>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="loading==false"
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                    FERMER
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="addData"
            max-width="850"
            persistent
            >
            <v-card>
                <v-toolbar
                    :color="$colors[0]"
                    dark>
                    <v-card-title class="Poppins-Bold">
                        Informations complémentaires
                    </v-card-title>
                </v-toolbar>
                <v-card-actions class="pa-8">
                <v-spacer></v-spacer>
                <v-row>
                    <v-col cols="12" md="6" sm="6" lg="6" xl="6">
                        <p class="font-weight-bold">Energie verte ? </p>
                        <v-radio-group
                            v-model="energieVert"
                            class="mt-2"
                            row
                            >
                                <v-radio
                                    class="mb-0 mx-2"
                                    label="Oui"
                                    :value="true"
                                ></v-radio>
                                <v-radio
                                    label="Non"
                                    :value="false"
                                ></v-radio>
                            </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" lg="6" xl="6">
                        <span class="font-weight-bold">Utilisation de Gaz :</span>
                        <v-radio-group
                            class="mt-2"
                            v-model="Utilisation"
                            row
                            >
                                <v-radio
                                    class="mb-0 mx-2"
                                    label="chauffage"
                                    value="chauffage"
                                ></v-radio>
                                <v-radio
                                    class="mb-0 mr-2"
                                    label="process"
                                    value="process"
                                ></v-radio>
                                <v-radio
                                    label="mixte"
                                    value="mixte"
                                ></v-radio>
                            </v-radio-group>
                    </v-col>
                    <v-col  cols="12" md="6" sm="6" lg="6" xl="6">
                        <span class="font-weight-bold">Débit du Compteur :</span>
                        <v-radio-group
                            class="mt-2"
                            v-model="Debit"
                            column
                            >
                                <div class="d-flex flex-row"> 
                                    <v-radio
                                        class="mb-0 mr-4"
                                        label="6 m3/h"
                                        value="6"
                                    ></v-radio>
                                    <v-radio
                                        label="10 m3/h"
                                        value="10"
                                    ></v-radio>
                                </div>
                                <div class="d-flex flex-row">    
                                    <v-radio
                                        class="mb-0 mr-2"
                                        label="16 m3/h"
                                        value="16"
                                    ></v-radio>
                                    <v-radio
                                        label="25 m3/h"
                                        value="25"
                                    ></v-radio>
                                </div>
                            </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" lg="6" xl="6">
                        <span class="font-weight-bold">Ajouter une adresse de facturation</span>
                        <v-radio-group
                            class="mt-2"
                            v-model="addAdrsFact"
                            row
                            >
                                <v-radio
                                    label="oui"
                                    class="mb-0 mx-2"
                                    :value="true"
                                ></v-radio>
                                <v-radio
                                    label="non"
                                    :value="false"
                                ></v-radio>
                            </v-radio-group>
                    </v-col>
                     <v-col class="d-flex flex-column align-end" v-if="addAdrsFact==true" cols="12" md="12" sm="12" lg="12" xl="12">
                        <v-text-field
                            class="align-self-end"
                            style="width:50%"
                            outlined
                            v-model="adresseFacturation"
                            label="Adresse de facturation"
                        ></v-text-field>
                    </v-col>
                     <v-col cols="12" md="12" sm="12" lg="12" xl="12" v-if="donner.typeCompteur!='c4'">
                        <span class="font-weight-bold">Type de paiement :</span>
                         <v-radio-group
                            class="mt-2"
                            v-model="typePayement"
                            row
                            >
                            <v-radio
                                label="Je choisis d’être mensualisé et prélevé automatiquement"
                                value="month"
                            ></v-radio>
                            <v-radio
                                label="Autre mode de paiement et de Facturation"
                                value="autre"  >
                            </v-radio>
                            </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                        <span class="font-weight-bold">Statut d’occupation:</span>
                         <v-radio-group class="mt-2" v-model="statutOccupation" row>
                            <v-radio label="Locataire" value="Locataire"/>
                            <v-radio label="Propriétaire occupant" value="Proprietaire occupant"/>
                            <v-radio label="Propriétaire bailleur" value="Proprietaire bailleur"/>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                        <span class="font-weight-bold">Type de local:</span>
                         <v-radio-group class="mt-2" v-model="typeDeLocal" row>
                            <v-radio label="Immeuble" value="Immeuble"/>
                            <v-radio label="Indépendant" value="Independant"/>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" lg="12" xl="12">
                        <span class="font-weight-bold">Mode de chauffage:</span>
                         <v-radio-group class="mt-2" v-model="modeDeChauffage" row>
                            <v-radio label="Électricité" value="Electricite"/>
                            <v-radio label="Gaz de Ville" value="Gaz de Ville"/>
                            <v-radio label="Bois" value="Bois"/>
                            <v-radio label="Gaz Propane" value="Gaz Propane"/>
                            <v-radio label="Fioul" value="Fioul"/>
                            <v-radio label="PAC" value="PAC"/>
                            <v-radio label="Autre" value="Autre"/>
                        </v-radio-group>
                    </v-col>
                    
                     <v-col v-if="typePayement=='month' && donner.typeCompteur!='c4'" cols="12" md="12" sm="12" lg="12" xl="12">
                         <span class="font-weight-bold">Type d'équipement :</span>
                         <v-radio-group
                            v-model="equipement"
                            >
                            <v-radio
                                label="Mon local n’est pas équipé d’un compteur communicant LinkyTM ou Gazpar"
                                value="non"
                            ></v-radio>
                            <v-radio
                                label="Mon local est équipé d’un compteur communicant LinkyTM ou Gazpar"
                                value="oui"  >
                            </v-radio>
                         </v-radio-group>
                    </v-col>
                    
                    <v-col cols="12" md="6" sm="6" lg="6" xl="6"
                            v-if="typePayement=='autre'">
                            <span class="font-weight-bold">Mode de paiement :</span>
                         <v-radio-group
                            class="mt-2"
                            v-model="modePayement"
                            column
                            >
                            <v-radio
                                label="Prélèvement automatique"
                                value="Prel"
                            ></v-radio>
                            <v-radio
                                label="TIP/Chèque"
                                value="TIP"  >
                            </v-radio>
                         </v-radio-group>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" lg="6" xl="6"
                            v-if="((typePayement=='month' && equipement=='non') || typePayement=='autre')">
                            <span class="font-weight-bold">Type de facture :</span>
                         <v-radio-group
                            v-model="typeFacture"
                            column
                            >
                            <v-radio
                                label="Papier"
                                value="papier"
                            ></v-radio>
                            <v-radio
                                label="Électronique"
                                value="elec"  >
                            </v-radio>
                         </v-radio-group>
                    </v-col>
                    
                    <v-col align="end" cols="12" md="12" sm="12" lg="12" xl="12" class="pa-0">
                        <v-btn v-if="loading==false"
                            color="error"
                            text
                            @click="addData=false;$emit('readonlyExpand',false)"
                        >
                            <span class="Poppins-Bold">Annuler</span>
                        </v-btn>
                        <v-btn v-if="loading==false"
                            :color="$colors[0]"
                            class="white--text"
                            @click="createContrat"
                        >
                            <span class="Poppins-Bold">Confirmer</span>
                        </v-btn>
                    </v-col>
                </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <div>
            <v-btn
                @click="replierForm"
                icon
                depressed class="ma-auto text-capitalize">
                <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
            </v-btn>
        </div>

    </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'

    import dialogeModal from "../../components/ui/dialogeModal"
    import axios from 'axios'
    export default {
        name: 'engieProGaz',
        components:{dialogeModal:dialogeModal},
        props: {
            donner: null,
            numCompteur: null,
            societe: null,
        },
        data: () => ({
            addData : false,
            addAdrsFact: false,
            statutOccupation: 'Locataire',
            typeDeLocal: 'Immeuble',
            modeDeChauffage: 'Electricite',
            typePayement: 'autre',
            equipement: 'oui',
            typeFacture: 'elec',
            modePayement: 'Prel',
            adresseFacturation: '',
            energieVert: true,
            percent: '25',
            Debit: '6',
            Utilisation:'chauffage',
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "",
             generatedPdf: null,
             type: '',
             positionPage1: 1,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
             positionPage1: 10,
        }),
        created() {
        },
        methods: {
            
            async replierForm(){
                this.addData = true
            },
            async createContrat(){
                this.addData = false
                this.dialog= true
                this.error= false
                this.loading = true
                this.messageDialoge= ""
                
                var autreDonner = {}
                autreDonner.addAdrsFact = this.addAdrsFact
                autreDonner.typePayement = this.typePayement
                autreDonner.equipement = this.equipement
                autreDonner.typeFacture = this.typeFacture
                autreDonner.modePayement = this.modePayement
                autreDonner.adresseFacturation = this.adresseFacturation
                autreDonner.energieVert = this.energieVert
                autreDonner.Debit = this.Debit
                autreDonner.Utilisation = this.Utilisation
                autreDonner.statutOccupation = this.statutOccupation
                autreDonner.typeDeLocal = this.typeDeLocal
                autreDonner.modeDeChauffage = this.modeDeChauffage
                var dataToSend = {
                    donner: this.donner,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                    autreDonner: autreDonner,
                }
                await axios({
                    url: 'https://editioncontrat.volto.fr/engieContratGaz',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur+'_EngiePro_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
            },
        },
    }
</script>