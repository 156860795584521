<template>
  <div>
    <loadingComponent @closeDialog="dialog = $event" v-if="loading" :loadingProps="loading" :dialogProps="dialog" :errorProps="error"/>
    <div class="ma-2">
    <v-card>
        <v-tabs
          :background-color="$colors[0]"
          v-model="tabs"
          centered
        >
          <v-tabs-slider :color="$colors[2]"></v-tabs-slider>
          <v-tab
            class="white--text font-weight-bold"
            v-for="n in 3"
            :key="n"
          >
            <span class="Poppins-Bold">Graphique {{ n == 1 ? "Elec" : (n==2 ? "Gaz" : "Spot") }}</span>
          </v-tab>
        </v-tabs>

    <v-tabs-items v-model="tabs" v-if="ready==true">
      <v-tab-item v-for="(chart,index) in charts" :key="index">
        <!--<v-card>
          <v-card-text :id="chart.idCapture" class="d-flex flex-lg-row flex-column">-->
            <v-row align="center" justify="center">
              <v-col cols="11" sm="11" md="11" lg="11">
                <researchDate class="pt-4" @updateCourbe="updateCourbe(chart.chartOptions.series,chart.urlAPI,$event.dateDebut,$event.dateFin,chart.items,chart.chartOptions.categories,chart.data,chart.updateChartsOptions)" />
              </v-col>
            </v-row>
            <v-row :id="chart.idCapture"  align="center" justify="center" class="pt-2">
              <v-col cols="11" sm="11" md="11" lg="11">
                <div>
                  <chart :optionsChart="chart.chartOptions" :seriesCharts="chart.chartOptions.series.value" :updateChartsOptions="chart.updateChartsOptions" styleProps="height:450px;"/> 
                </div>
              </v-col>
              <v-col class="pt-0" cols="11" sm="11" md="11" lg="11">
                  <div class="d-flex justify-end mb-4">
                    <v-btn :id="'screenshot'+index" @click="captureDownloadImage(chart.idCapture,'screenshot'+index)" text dense><span class="Poppins-Bold">Capture d'écran</span><v-icon :color="$colors[0]" class="ml-2">mdi-camera</v-icon></v-btn>
                  </div>
                  <chartTable :itemsTable="chart.items?.value" />
              </v-col>
            </v-row> 
          <!--</v-card-text>
        </v-card>-->
      </v-tab-item>
    </v-tabs-items>
  </v-card>
    </div>
  </div>
</template>
<script>
import loadingComponent from "../../components/ui/loading-component.vue"
import researchDate from "./components/research-date.vue";
import chartTable from "./components/chart-table.vue";
import chart from "./components/chart.vue";
import html2canvas from 'html2canvas';
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import dayjs from 'dayjs'
import axios from "axios";
export default {
  components: {
    loadingComponent,
    chart:chart,
    chartTable:chartTable,
    researchDate:researchDate,
  },
  data() {
    return {

      tabs: 0,
      ready: false,
      charts:[
        {
          idCapture:"capture-elec",
          items:{value:[]},
          data: {value:null},
          updateChartsOptions:null,
          chartOptions: {
            colors:['#0D47A1', '#1E6CA4', '#FFCB35'],
            title:"Évolution l'éléctricité",
            series: {value:[]},
            categories: {value:[]},
          },
          urlAPI:"getAllDataCourbeElec/",
        },
        {
          idCapture:"capture-gaz",
          items: {value:[]},
          data: {value:null},
          updateChartsOptions:null,
          chartOptions: {
            colors:["#0D47A1", "#1E6CA4", "#FFCB35"],
            title:"Évolution du gaz",
            series: {value:[]},
            categories: {value:[]},
          },
          urlAPI:"getAllDataCourbeGaz/",
        },
        {
          idCapture:"capture-spot",
          items:{value:[]},
          data: {value:null},
          updateChartsOptions:null,
          chartOptions: {
            colors: ["#70DBF6", "#A9F0D1"],
            title:"Évolution du spot",
            series: {value:[]},
            categories: {value:[]},
          },
          urlAPI:"getAllDataCourbeSpot/",
        }
      ],
      dialog: false,
      error: false,
      loading: false,
      messageDialoge: "L'e-mail a été envoyé avec succès",

    };
  },

  methods: {
    async captureDownloadImage(idDiv,idScreenShot){
      document.getElementById(idScreenShot).style.display = "none";
      var container = document.getElementById(idDiv);
      await html2canvas(container, { allowTaint: true }).then(function (canvas) {
       
          var link = document.createElement("a");
          document.body.appendChild(link);
          idDiv == "capture-elec" ? link.download = "évolution_prix_elec.jpg" : (idDiv == "capture-gaz" ? link.download = "évolution_prix_gaz.jpg" : link.download = "évolution_prix_spot.jpg");
          link.href = canvas.toDataURL();
          link.target = '_blank';
          link.click();
      });
      document.getElementById(idScreenShot).style.display = "block";
    },
    async getDataCharts(seriesCharts,urlAPI,dataCharts,categoriesCharts,items,firstDateSearch,lastDateSearch){
      seriesCharts.value = [];
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + urlAPI,
        method: "POST",
        data: { year: 2022, dateDebut:firstDateSearch, dateFin:lastDateSearch },
      })
        .then((res) => {
          dataCharts = res.data.data;
          Object.keys(dataCharts).forEach((key) => {
            var d = {
              name: key,
              data: dataCharts[key]["data"],
            };
            items.value.push({categorie:key,today:dataCharts[key]["dataTable"][key]['jour'],week:dataCharts[key]["dataTable"][key]['semaine'],month:dataCharts[key]["dataTable"][key]['month'],year:dataCharts[key]["dataTable"][key]['years']})
            if (categoriesCharts.value.length < dataCharts[key]["label"].length) {
              categoriesCharts.value = dataCharts[key]["label"];
            }
            seriesCharts.value.push(d);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateCourbe(series,urlAPI,dateDebut,dateFin,itemsSeries,categories,dataSeries,updateChartsOptions){
      this.dialog = true;
      this.loading = true;
      series.value = [];
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + urlAPI,
        method: "POST",
        data: { year: 2022, dateDebut:dateDebut, dateFin:dateFin},
      })
        .then((res) => {
          
          itemsSeries.value=[]
          categories.value = []
          dataSeries.value = res.data.data;
          Object.keys(dataSeries.value).forEach((key) => {
            var d = {
              name: key,
              data: dataSeries.value[key]["data"],
            };
            itemsSeries.value.push({categorie:key,today:dataSeries.value[key]["dataTable"][key]['jour'],week:dataSeries.value[key]["dataTable"][key]['semaine'],month:dataSeries.value[key]["dataTable"][key]['month'],year:dataSeries.value[key]["dataTable"][key]['years']})
            if (categories.value.length < dataSeries.value[key]["label"].length) {
              categories.value = dataSeries.value[key]["label"];
            }
            series.value.push(d);
                updateChartsOptions = {
                series:[{data:series.value}],
                xaxis: {
                type:'datetime',
                categories: categories.value,
                tickPlacement: 'on',
                labels:{
                  formatter: function (value) {
                    return dayjs(value).format('DD-MM-YYYY');
                  }
                }
              },
                  }
                  switch (urlAPI) {
                    case "getAllDataCourbeElec/":
                      this.charts[0].updateChartsOptions = updateChartsOptions
                      break;

                    case "getAllDataCourbeGaz/":
                      this.charts[1].updateChartsOptions= updateChartsOptions
                      break;

                    case "getAllDataCourbeSpot/":
                      this.charts[2].updateChartsOptions = updateChartsOptions
                      break;
                  
                    default:
                      break;
                  }
                  this.dialog = false;
                  this.loading = false;
            
          });
        })
        .catch((err) => {
          console.log(err);
        });
          
    },
    async createGraph(){
          for(let i = 0;i<this.charts.length;i++){
          await this.getDataCharts(this.charts[i].chartOptions.series,this.charts[i].urlAPI,this.charts[i].data,this.charts[i].chartOptions.categories,this.charts[i].items,dayjs().subtract(1, 'year').format("YYYY-MM-DD"),dayjs().format("YYYY-MM-DD"),);
        }
    },

  },
  async created() {
    this.dialog = true;
    this.loading = true;
    this.error = false;
    await this.createGraph();    
    this.ready = true
    this.dialog = false;
    this.loading = false;
  },
};
</script>